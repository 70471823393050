import React, { useEffect, useState } from 'react'
import "./gifts.scss"
import Environment from 'utils/Environment'
import { Backdrop, CircularProgress } from '@material-ui/core'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'
import { toast } from 'react-toastify'
import user4 from "assets/img/userflow/add1.png";
import ReactPaginate from 'react-paginate';
import { Pagination } from 'react-bootstrap';
import DemoNavbar from "components/Navbars/DemoNavbar.js";


const Gift = (props) => {

    const [open, setOpen] = useState(false);
    const [showw, setShoww] = useState(false);
    const handleClose = () => setShoww(false);
    const [email, setEmail] = useState('')
    const [emailArr, setEmailArr] = useState([])
    const [emailAvail, setEmailAvail] = useState(false)
    const [itemData, setItemData] = useState([])
    const [items, setItems] = useState({})
    const [sGListing, setSGListing] = useState([])
    const token = localStorage.getItem('mytoken')
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0)
    const [walletAddress, setWalletAddress] = useState('')
    const [isValid, setIsValid] = useState(false);
    const [note, setNote] = useState()
    const [limit] = useState(10);

    const Acls = JSON.parse(localStorage.getItem('acls'));
    const userRole = localStorage.getItem('myrole');

    // Search =============
    const [searchTerm, setSearchTerm] = useState('');
    const [giftSortItem, setGiftSortItem] = useState('');

    const searchsubmit = (e) => {
        setPage(0);
        sendGiftsListing();
    }
    // Search =============

    // timerformate ================================

    const changeDateFormate = (createdAt) => {
        console.log("🚀 ~ changeDateFormate ~ createdAt:", createdAt);
        const originalDate = new Date(createdAt);

        // Format the date and time
        const formattedDate = originalDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true, // Use 12-hour clock, you can set it to false for 24-hour clock
        });

        // Return the formatted date and time
        return formattedDate;
    }

    // timerformate ================================

    const getItems = async () => {
        setOpen(true);
        axios.get(Environment.backendUrl + `/giftHistory/getProducts`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                setItemData(response?.data?.productData)
                setOpen(false)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }

    const verifyEmail = async (value) => {
        setEmailAvail(false)
        if (value) {
            setOpen(true)
            axios.post(Environment.backendUrl + `/giftHistory/searchUserWithEmail`, { email: value }, { headers: { "Authorization": `Bearer ${token}` } })
                .then((response) => {
                    setOpen(false)
                    setEmail(null)
                    setEmailArr(response?.data?.UserData)

                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        }
    }

    const sendGifts = async () => {
        if (items?.ProductType?.productName && email?.email && note) {
            let data;
            if (items?.ProductCategory?.categoryName === "Metaverse Assets ") {
                data = {
                    product_id: items?.id,
                    email: email?.email,
                    user_id: email?.id,
                    gift_name: items?.ProductType?.productName,
                    status: 'complete',
                    walletAddress,
                    note
                }
            } else {
                data = {
                    product_id: items?.id,
                    email: email?.email,
                    user_id: email?.id,
                    gift_name: items?.ProductType?.productName,
                    status: 'inProgress',
                    note
                }
            }
            setOpen(true)
            axios.post(Environment.backendUrl + `/giftHistory/sendGift`, data, { headers: { "Authorization": `Bearer ${token}` } })
                .then((response) => {
                    sendGiftsListing()
                    setOpen(false)
                    setEmail(null)
                    setItems(null)
                    setWalletAddress(null)
                    handleClose()
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            toast.error('Fill all fields first', {
                position: "top-center",
                autoClose: 2000,
            });
        }
    }

    const sendGiftsListing = async () => {
        setOpen(true);
        let data = {
            limit: limit,
            page: page,
            searchQuery: searchTerm || "",
            filterBy: giftSortItem === "All" ? "all" : giftSortItem
        }
        axios.post(Environment.backendUrl + `/giftHistory/allGifts`, data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' } })
            .then((response) => {
                setSGListing(response?.data?.gifthistoryData)
                setPageCount(response.data.total / limit)
                setOpen(false)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    function isValidWalletAddress(address) {
        const regex = /^(0x)?[0-9a-fA-F]{40}$/;
        return regex.test(address);
    }

    const validateWallet = (event) => {
        const inputAddress = event.target.value;
        setWalletAddress(inputAddress);
        setIsValid(isValidWalletAddress(inputAddress));
    }

    useEffect(() => {
        sendGiftsListing()
    }, [page, giftSortItem]);

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} giftSortItem={giftSortItem} setGiftSortItem={setGiftSortItem} />

            <div className="content">
                <section className="gifts-section">
                    <div className="container-fluid">
                        <div class="table-responsive">
                            <div className="innertable_gifts">
                                <table>
                                    <thead>
                                        <th>Users Name</th>
                                        <th>Items</th>
                                        <th>Send by </th>
                                        <th>Admin note</th>
                                        <th>Date / Time</th>
                                    </thead>
                                    <tbody>
                                        {sGListing?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="mainimgdiv">
                                                            <div className="inerimgd">
                                                                <img src={item?.User?.profile_image ? item?.User?.profile_image : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMaSURBVHgBxVhLbhpBEK0ZY9nyT4MtW174AydIcgJgwYJVzAmSnIBwAsMJ4pwgyQlCVixYhJwgzgn4LpAQ4is+4pd645kRBqanZ8DykxpQT1X3o7u66vUo5AKFQkHz+Xwf5vP5WzRFUQLcrRmPW9xX5L6iqqqZ8Xj8JxgMFmXHVmSMmEB4Z2fnnn+GyR0y0+n0KxPKOxkqDgQCTOCbBwLrCCVFK6TaPahUKgkm8XcLJIA7jFUqlT7bGSg2JO55v1P0AuAYSl1fX6dX+skDCQ5EarVa1O12aTKZ6H17e3u0v79Pp6entLu7S27JKEskEkziQTRIs9mker0uMqGzszO9icDzJG9vbx9WiBiBiZjQ7JwbjYbeZCBBpsUB/M4MYCtYmcQXEYl2uy1NAoBtr9cTmWjGiSSLCPIEf905DewWTlvICBtzPxFhZgmRdb/ft4LSDRDU8BXBSJSkGLFREBnLBKgdzs/Pye/3C204VvwqkwiTA2azGXmFjC/Xpo/YmvcShuQVMr6cV96oRgUVAsnKK2R8UclV/gg4GR4cHBCXf3ILZFj4OgGLgXXTSAIXFxfkFk7ZdQGa9OYfHR25GVi3PTk5kbYHkZassUwNAXBk3ZAGBx+kHYJF1sP8p8i0o9FIbwBi6Pj4mDRNc6y+y4DE9PE5/8dkpIkAmOjy8pK2BSZSwtY80iuDV/OnyivynV4ZUPwq6wEEa55cAqkb8YGihobfXkoB+/yCJtGzFBedtEzNwYSdTkdYjc0kdnh4qB95J5iK0FJo5XL5N9kodlMUuZUCIOWQTzI3NzfxZ0TWSUXoiVqtRoPBgDYBCF1dXS0f6/VSER28TJayHg6HxPeQjUkA+EPVatXKOQDmWrxwPUvxUNUwMB030SHryPAtQf/GHIsKfoWIQSbFJNLbJGECY3IsgkRq+dnaohcKhVK4d5CLOiQBvC1IRiKR1LqHwkt4NpsNcIDhmiFU+BLI85Z8isViRTsDqdcSuVwuzPUo4YFQHvEQjUbzToZSRExghZD4WIdC5waMZr2o4Vakp9r1yPfiH/F4XHpr/wNFO4nZQ7R6SAAAAABJRU5ErkJggg=="} className="tableimgginer" />
                                                            </div>
                                                            <div className='onlycolmflex'>
                                                                <p className="tableimgtext">
                                                                    {item?.User?.full_name}
                                                                </p>
                                                                <p className='tableimgtext_mute'>
                                                                    {item?.User?.email}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{item?.gift_name}</td>
                                                    <td>
                                                        <div className="mainimgdiv">
                                                            <div className="inerimgd">
                                                                <img src={item?.Sender?.profile_image ? item?.Sender?.profile_image : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMaSURBVHgBxVhLbhpBEK0ZY9nyT4MtW174AydIcgJgwYJVzAmSnIBwAsMJ4pwgyQlCVixYhJwgzgn4LpAQ4is+4pd645kRBqanZ8DykxpQT1X3o7u66vUo5AKFQkHz+Xwf5vP5WzRFUQLcrRmPW9xX5L6iqqqZ8Xj8JxgMFmXHVmSMmEB4Z2fnnn+GyR0y0+n0KxPKOxkqDgQCTOCbBwLrCCVFK6TaPahUKgkm8XcLJIA7jFUqlT7bGSg2JO55v1P0AuAYSl1fX6dX+skDCQ5EarVa1O12aTKZ6H17e3u0v79Pp6entLu7S27JKEskEkziQTRIs9mker0uMqGzszO9icDzJG9vbx9WiBiBiZjQ7JwbjYbeZCBBpsUB/M4MYCtYmcQXEYl2uy1NAoBtr9cTmWjGiSSLCPIEf905DewWTlvICBtzPxFhZgmRdb/ft4LSDRDU8BXBSJSkGLFREBnLBKgdzs/Pye/3C204VvwqkwiTA2azGXmFjC/Xpo/YmvcShuQVMr6cV96oRgUVAsnKK2R8UclV/gg4GR4cHBCXf3ILZFj4OgGLgXXTSAIXFxfkFk7ZdQGa9OYfHR25GVi3PTk5kbYHkZassUwNAXBk3ZAGBx+kHYJF1sP8p8i0o9FIbwBi6Pj4mDRNc6y+y4DE9PE5/8dkpIkAmOjy8pK2BSZSwtY80iuDV/OnyivynV4ZUPwq6wEEa55cAqkb8YGihobfXkoB+/yCJtGzFBedtEzNwYSdTkdYjc0kdnh4qB95J5iK0FJo5XL5N9kodlMUuZUCIOWQTzI3NzfxZ0TWSUXoiVqtRoPBgDYBCF1dXS0f6/VSER28TJayHg6HxPeQjUkA+EPVatXKOQDmWrxwPUvxUNUwMB030SHryPAtQf/GHIsKfoWIQSbFJNLbJGECY3IsgkRq+dnaohcKhVK4d5CLOiQBvC1IRiKR1LqHwkt4NpsNcIDhmiFU+BLI85Z8isViRTsDqdcSuVwuzPUo4YFQHvEQjUbzToZSRExghZD4WIdC5waMZr2o4Vakp9r1yPfiH/F4XHpr/wNFO4nZQ7R6SAAAAABJRU5ErkJggg=="} className="tableimgginer" />
                                                            </div>
                                                            <div className='onlycolmflex'>
                                                                <p className="tableimgtext">
                                                                    {item?.Sender?.full_name}
                                                                </p>
                                                                <p className='tableimgtext_mute'>
                                                                    {item?.Sender?.Sender}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className='eleipiess'>
                                                            {item?.note}
                                                        </p>
                                                    </td>
                                                    <td>{changeDateFormate(item?.updatedAt)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='Paginationlattable'>
                                {pageCount >= 1 ?
                                    <div className="Paginationlattable gift-pagination w-100 justify-content-center">
                                        <ReactPaginate
                                            previousLabel="Previous"
                                            nextLabel="Next"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            forcePage={page}
                                        ></ReactPaginate>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            {/* <div className="main-div-nav-head">
                <Modal className='sendgift-modal' show={showw} onHide={handleClose} centered>
                    <Modal.Header>
                        <Modal.Title>Send Gift</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="parent-field">
                            <h6>Select Item</h6>
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {items?.ProductType?.productName ? <p className='text-dark'>{items?.ProductType?.productName} </p> : 'Select Item'}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {itemData?.map((item) => {
                                        console.log("🚀 ~ {itemData?.map ~ item:", item)
                                        return (
                                            <a onClick={() => setItems(item)} class="dropdown-item" href="#">{item?.ProductType?.productName}</a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {items?.ProductCategory?.categoryName === "Metaverse Assets " &&
                            <div className="option-field parent-field">
                                <h6>Wallet Address</h6>
                                <input value={walletAddress} onChange={validateWallet} type="text" placeholder='Enter wallet address' />
                                {(isValid === false && walletAddress) && <p className='text-danger'>Wallet Address Invalid</p>}
                            </div>
                        }
                        <div className="option-field parent-field">
                            <h6>Search with email address</h6>

                            <div class="dropdown w-100">
                                <input id="dropdownMenuButtonINput" value={email?.email && email?.email} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onChange={(e) => { verifyEmail(e.target.value); setEmailAvail(false) }} type="text" placeholder='Enter email address' />

                                <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButtonINput">
                                    {emailArr?.map((item) => {
                                        return (
                                            <a onClick={() => { setEmail(item); setEmailAvail(true) }} class="dropdown-item" href="#">{item?.email}</a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='areatext'>
                            <p>Admin Note</p>
                            <textarea onChange={(e) => setNote(e.target.value)} type='text' placeholder='Admin Note' />
                        </div>

                        <div className="twice-btn">
                            <button onClick={handleClose} className='red-w'>Cancel</button>
                            {(items?.ProductCategory?.categoryName === "Metaverse Assets ") ?
                                ((items?.ProductType?.productName && emailAvail && walletAddress && isValid) ?
                                    <button onClick={sendGifts} className='red-w'>Send</button>
                                    :
                                    <button className='red-w'>Send</button>
                                )
                                :
                                ((items?.ProductType?.productName && emailAvail) ?
                                    <button onClick={sendGifts} className='btn-send'>Send</button>
                                    :
                                    <button className='red-w'>Send</button>
                                )
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div> */}
        </>
    )
}

export default Gift
