
import React, { useEffect, useState } from "react";
import './games.scss';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from "react-router-dom";
// reactstrap components
import { Link } from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

const Addplayto = (props) => {

    const id = props.match.params.id;
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const [selectedImg1, setSelectedImg1] = useState();
    const [selectedImg2, setSelectedImg2] = useState();
    const [selectedImg3, setSelectedImg3] = useState();
    const token = localStorage.getItem('mytoken')
    const [myFiles, setMyFiles] = useState();
    const [myFiles1, setMyFiles1] = useState();
    const [myFiles2, setMyfiles2] = useState();
    const [selectedImg_new, setSelectedImg_new] = useState();
    const [inputs, setInputs] = useState([
        {
            name: '',
            description: '',
            reward: '',
            threshold: ''

        }
    ]);


    // search 
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }


    const getdata = () => {
        setOpen(true)
        axios.get(Environment.backendUrl + "/game/find/" + id, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setOpen(false)
                setInputs(response.data.game)
                setSelectedImg1(response.data.game.horizontalImage)
                setSelectedImg2(response.data.game.verticalImage)
                setSelectedImg3(response.data.game.roundImage)
                setSelectedImg_new(response.data.game.horizontalImage)
                // setOpen(true)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const { name, description, threshold, reward } = inputs;

    const handleFileSelect = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg1(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles(file)
    }
    const handleFileSelect1 = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg2(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles1(file)
    }
    const handleFileSelect2 = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg3(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyfiles2(file)
    }
    const handleFileSelect3 = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg_new(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles(file)
    }


    const addNewGame = async (event) => {
        setOpen(true)
        // event.preventDefault();
        const data = new FormData();
        if (myFiles) {
            data.append("horizontalImage", myFiles)
        }
        if (myFiles1) {
            data.append("verticalImage", myFiles1)
        }
        if (myFiles2) {
            data.append("roundImage", myFiles2)
        }
        if (myFiles) {
            data.append("gameImage", myFiles)
        }

        data.append("name", inputs.name)
        data.append("description", inputs.description)
        data.append("threshold", inputs.threshold)
        data.append("reward", inputs.reward)
        data.append("id", id)
        axios.post(Environment.backendUrl + "/game/add", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                setOpen(false)
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
                setInputs({
                    name: '',
                    description: '',
                    reward: '',
                    threshold: ''
                })
                setSelectedImg1('');
                setSelectedImg2('');
                setSelectedImg3('');
                setOpen(false);
                history.push('/admin/playtoearn')
                // console.log(response)
                // setOpen(true)
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const editDaily = async (event) => {
        setOpen(true)
        event.preventDefault()
        const data = new FormData();
        if (myFiles) {
            data.append("horizontalImage", myFiles)
        }
        if (myFiles1) {
            data.append("verticalImage", myFiles1)
        }
        if (myFiles2) {
            data.append("roundImage", myFiles2)
        }

        data.append("name", inputs.name)
        data.append("description", inputs.description)
        data.append("threshold", inputs.threshold)
        data.append("reward", inputs.reward)
        data.append("id", id)
        axios.post(Environment.backendUrl + "/game/edit/playtoearn", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                setOpen(false)
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
                setInputs({
                    name: '',
                    description: '',
                    reward: '',
                    threshold: ''
                })
                setSelectedImg1('');
                setSelectedImg2('');
                setSelectedImg3('');
                history.push('/admin/playtoearn')
                // console.log(response)
                // setOpen(true)
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }
    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    useEffect(() => {
        if (id) {
            getdata()
        }
    }, [token])

    const renderPhotos = (source) => {
        return <img src={source} alt="" width="386" height="287" />
    }

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />


            <div className="content">
                <section className="daily card">
                    <div className="inner-submit-lower-div">
                        <div class="row">
                            <div class="col-md-8">
                                <div className="row">
                                    <div className="row">
                                        <div className="col-12">
                                            <div class="form-group">
                                                <label for="example">Game Title</label>
                                                <input type="text" name="name" value={id ? inputs.name : name} onChange={handleChange1} className='form-control' placeholder="Enter title of the game" />
                                            </div>
                                        </div>
                                        <div className="col-12 ">
                                            <div class="form-group">
                                                <label className="padd-top" for="example">Game Description</label>
                                                <textarea name="description" value={id ? inputs.description : description} onChange={handleChange1} className='form-control' placeholder="Enter description of the game" rows="5"></textarea>

                                            </div>
                                        </div>
                                        {/* <div className="col-12 ">
                                                <div class="form-group">
                                                    <label className="padd-top ptb20" for="example">Game Thumbnail</label>
                                                    <div className="main-image-div">
                                                        <label for="example">Horizontal Image</label>
                                                        <div className="choose-filessss yoyoyo">
                                                            <small> {selectedImg1 ? selectedImg1 : null}</small>
                                                            <form>
                                                                <input type="file" name="thumbnail" onChange={handleFileSelect} accept="image/*" className='form-control' />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 ">
                                                <div class="form-group">
                                                    <div className="main-image-div">
                                                        <label for="example">Vertical Image</label>
                                                        <div className="choose-filessss yoyoyo">
                                                            <small> {selectedImg2 ? selectedImg2 : null}</small>
                                                            <form>
                                                                <input type="file" name="thumbnail" accept="image/*" onChange={handleFileSelect1} className='form-control' />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 ">
                                                <div class="form-group">
                                                    <div className="main-image-div">
                                                        <label for="example">Round Image</label>
                                                        <div className="choose-filessss yoyoyo">
                                                            <small> {selectedImg3 ? selectedImg3 : null}</small>
                                                            <form>
                                                                <input type="file" name="thumbnail" accept="image/*" onChange={handleFileSelect2} className='form-control' />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                    </div>
                                    <div className="col-12">
                                        <div class="form-group rewardsss">
                                            <label for="example">Earn to Play Reward</label>
                                            <input type="text" name="reward" value={id ? inputs.reward : reward} onChange={handleChange1} className='form-control' placeholder="Game Reward" />

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div class="form-group rewardsss">
                                            <label for="example">Threshold Score</label>
                                            <input type="text" name="threshold" value={id ? inputs.threshold : threshold} onChange={handleChange1} className='form-control' placeholder="Threshold Score" />

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div class="form-group">
                                            <div className="videopage">
                                                {id ?
                                                    <button className="red-b" onClick={editDaily}>Save</button>
                                                    : <button className="red-b" onClick={() => addNewGame()}>Publish</button>
                                                }
                                                <Link to="/admin/playtoearn">
                                                    <button className="red-w">Cancel</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">

                                <div class="form-group rightside-for-group">
                                    <label for="exampleInputsymbol"> Horizontal Image</label>
                                    <div className="dashed-border-new">
                                        <div className="main-image-div">

                                            <div className="choose-filessss">
                                                {selectedImg1 ?
                                                    <label htmlFor="myFile1">
                                                        {renderPhotos(selectedImg1)}
                                                        <input type="file" className="custom-file-inputt d-none" id="myFile1" name="filename" onChange={(e) => handleFileSelect(e)} />
                                                    </label>
                                                    :
                                                    <label htmlFor="myFile">
                                                        <svg xmlns="http://www.w3.org/2000/svg" onChange={handleFileSelect} width="61" height="45" viewBox="0 0 61 45" fill="none">
                                                            <path d="M47.6633 44.6918H37.804H35.1492H34.5758V31.4672H38.9009C39.9978 31.4672 40.6459 30.2208 39.9978 29.3234L31.5844 17.6818C31.0484 16.9339 29.9391 16.9339 29.4031 17.6818L20.9898 29.3234C20.3416 30.2208 20.9773 31.4672 22.0866 31.4672H26.4117V44.6918H25.8384H23.1835H11.7538C5.21005 44.3303 0 38.2104 0 31.5794C0 27.005 2.48038 23.0165 6.15734 20.8602C5.8208 19.9503 5.6463 18.9781 5.6463 17.956C5.6463 13.2819 9.42297 9.50525 14.0971 9.50525C15.1067 9.50525 16.0789 9.67975 16.9888 10.0163C19.6935 4.28273 25.5268 0.306641 32.3073 0.306641C41.0821 0.319105 48.3114 7.03733 49.134 15.6003C55.8772 16.7594 61 23.004 61 30.0713C61 37.6246 55.1169 44.1683 47.6633 44.6918Z" fill="#EDEDEE" />
                                                        </svg>
                                                        <p className='draganddrop'>Drag & drop or <span className='common' >Browse</span></p>
                                                        <input type="file" className="custom-file-inputt d-none" id="myFile" name="filename" onChange={(e) => handleFileSelect(e)} />
                                                    </label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group rightside-for-group">
                                    <label for="exampleInputsymbol"> Vertical Image</label>
                                    <div className="dashed-border-new">
                                        <div className="main-image-div">
                                            <div className="choose-filessss">
                                                {selectedImg2 ?
                                                    <label htmlFor="myFile22">
                                                        {renderPhotos(selectedImg2)}
                                                        <input type="file" className="custom-file-inputt d-none" id="myFile22" name="filename" onChange={(e) => handleFileSelect1(e)} />
                                                    </label>
                                                    :
                                                    <label htmlFor="myFile2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" onChange={handleFileSelect1} width="61" height="45" viewBox="0 0 61 45" fill="none">
                                                            <path d="M47.6633 44.6918H37.804H35.1492H34.5758V31.4672H38.9009C39.9978 31.4672 40.6459 30.2208 39.9978 29.3234L31.5844 17.6818C31.0484 16.9339 29.9391 16.9339 29.4031 17.6818L20.9898 29.3234C20.3416 30.2208 20.9773 31.4672 22.0866 31.4672H26.4117V44.6918H25.8384H23.1835H11.7538C5.21005 44.3303 0 38.2104 0 31.5794C0 27.005 2.48038 23.0165 6.15734 20.8602C5.8208 19.9503 5.6463 18.9781 5.6463 17.956C5.6463 13.2819 9.42297 9.50525 14.0971 9.50525C15.1067 9.50525 16.0789 9.67975 16.9888 10.0163C19.6935 4.28273 25.5268 0.306641 32.3073 0.306641C41.0821 0.319105 48.3114 7.03733 49.134 15.6003C55.8772 16.7594 61 23.004 61 30.0713C61 37.6246 55.1169 44.1683 47.6633 44.6918Z" fill="#EDEDEE" />
                                                        </svg>
                                                        <p className='draganddrop'>Drag & drop or <span className='common' >Browse</span></p>
                                                        <input type="file" className="custom-file-inputt d-none" id="myFile2" name="filename" onChange={(e) => handleFileSelect1(e)} />
                                                    </label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group rightside-for-group">
                                    <label for="exampleInputsymbol"> Round Image</label>
                                    <div className="dashed-border-new">
                                        <div className="main-image-div">

                                            <svg xmlns="http://www.w3.org/2000/svg" onChange={handleFileSelect2} width="61" height="45" viewBox="0 0 61 45" fill="none">
                                                <path d="M47.6633 44.6918H37.804H35.1492H34.5758V31.4672H38.9009C39.9978 31.4672 40.6459 30.2208 39.9978 29.3234L31.5844 17.6818C31.0484 16.9339 29.9391 16.9339 29.4031 17.6818L20.9898 29.3234C20.3416 30.2208 20.9773 31.4672 22.0866 31.4672H26.4117V44.6918H25.8384H23.1835H11.7538C5.21005 44.3303 0 38.2104 0 31.5794C0 27.005 2.48038 23.0165 6.15734 20.8602C5.8208 19.9503 5.6463 18.9781 5.6463 17.956C5.6463 13.2819 9.42297 9.50525 14.0971 9.50525C15.1067 9.50525 16.0789 9.67975 16.9888 10.0163C19.6935 4.28273 25.5268 0.306641 32.3073 0.306641C41.0821 0.319105 48.3114 7.03733 49.134 15.6003C55.8772 16.7594 61 23.004 61 30.0713C61 37.6246 55.1169 44.1683 47.6633 44.6918Z" fill="#EDEDEE" />
                                            </svg>
                                            <div className="choose-filessss">
                                                {selectedImg3 ?
                                                    <label htmlFor="myFile33">
                                                        {renderPhotos(selectedImg3)}
                                                        <input type="file" className="custom-file-inputt d-none" id="myFile33" name="filename" onChange={(e) => handleFileSelect2(e)} />
                                                    </label>
                                                    :
                                                    <label htmlFor="myFile3">
                                                        <p className='draganddrop'>Drag & drop or <span className='common' >Browse</span></p>
                                                        <input type="file" className="custom-file-inputt d-none" id="myFile3" name="filename" onChange={(e) => handleFileSelect2(e)} />
                                                    </label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group rightside-for-group">
                                    <label for="exampleInputsymbol"> Game Image</label>
                                    <div className="dashed-border-new">
                                        <div className="main-image-div">

                                            <svg xmlns="http://www.w3.org/2000/svg" onChange={handleFileSelect3} width="61" height="45" viewBox="0 0 61 45" fill="none">
                                                <path d="M47.6633 44.6918H37.804H35.1492H34.5758V31.4672H38.9009C39.9978 31.4672 40.6459 30.2208 39.9978 29.3234L31.5844 17.6818C31.0484 16.9339 29.9391 16.9339 29.4031 17.6818L20.9898 29.3234C20.3416 30.2208 20.9773 31.4672 22.0866 31.4672H26.4117V44.6918H25.8384H23.1835H11.7538C5.21005 44.3303 0 38.2104 0 31.5794C0 27.005 2.48038 23.0165 6.15734 20.8602C5.8208 19.9503 5.6463 18.9781 5.6463 17.956C5.6463 13.2819 9.42297 9.50525 14.0971 9.50525C15.1067 9.50525 16.0789 9.67975 16.9888 10.0163C19.6935 4.28273 25.5268 0.306641 32.3073 0.306641C41.0821 0.319105 48.3114 7.03733 49.134 15.6003C55.8772 16.7594 61 23.004 61 30.0713C61 37.6246 55.1169 44.1683 47.6633 44.6918Z" fill="#EDEDEE" />
                                            </svg>
                                            <div className="choose-filessss">
                                                {selectedImg_new ?
                                                    <label htmlFor="myFile44">
                                                        {renderPhotos(selectedImg_new)}
                                                        <input type="file" className="custom-file-inputt d-none" id="myFile44" name="filename" onChange={(e) => handleFileSelect3(e)} />
                                                    </label>
                                                    :
                                                    <label htmlFor="myFile4">
                                                        <p className='draganddrop'>Drag & drop or <span className='common' >Browse</span></p>
                                                        <input type="file" className="custom-file-inputt d-none" id="myFile4" name="filename" onChange={(e) => handleFileSelect3(e)} />
                                                    </label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Addplayto;
