
import React, { useEffect, useState } from "react";
import './rewardlea.scss';
// reactstrap components
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Modal } from "react-bootstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

function Streakrewards(props) {

    // const id = props.match.params.id;

    const [getId, setId] = useState(null);
    console.log("🚀 ~ Streakrewards ~ getId:", getId)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        console.log("🚀 ~ handleShow ~ id:", id)
        setShow(true);
        setId(id);
    };


    const [myFiles, setMyFiles] = useState([]);
    const token = localStorage.getItem('mytoken')
    const [open, setOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [editTitle, setEditTitle] = useState(null);
    const [editReward, setEditReward] = useState(null);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const getReward = async () => {
        setOpen(true)
        await axios.get(Environment.backendUrl + `/reward/getRewardsByType?type=DailyLoginStreak&page=${page}&pageSize=${limit}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                console.log("🚀 ~ .then ~ response:", response)
                setMyFiles(response?.data?.response?.data)
                setOpen(false)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const getRewardById = async () => {
        setOpen(true)
        await axios.get(Environment.backendUrl + `/reward/getRewardsById?id=${getId}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                console.log("🚀 ~ .then ~ response:", response)
                setEditTitle(response?.data?.response?.description);
                setEditReward(response?.data?.response?.boltReward);
                setOpen(false);
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const editAdsRewardHandle = async () => {
        setOpen(true)
        const data = {
            description: editTitle,
            boltReward: editReward,
            id: getId
        }
        await axios.post(Environment.backendUrl + `/reward/editBoltsRewardById`, data, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setMyFiles(response?.data?.response?.data)
                handleClose();
                getReward();
                setOpen(false)
                toast?.success(response?.data?.msg);

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    // const News = myFiles.map(elem => {
    //     return (
    //         <tr>
    //             <td className='main-image'>
    //                 <Link className=''> <img src={elem?.image} className="pr-2 imgages-no" alt="" /></Link>
    //             </td>
    //             <td className=''>{elem.name}</td>
    //             <td className=''>{elem?.createdAt.split('T')[0]}</td>
    //             <td className=''>{elem.description}</td>
    //         </tr>
    //     )
    // })

    useEffect(() => {
        getReward()
    }, [token])

    useEffect(() => {
        console.log(getId, "getId");
        if (getId) {
            getRewardById(getId);
        }
    }, [getId])

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <section className="main-tasks user-details">
                    <div className="container-fluid">

                        <div className='maintablea_task'>
                            <table class="mt-0 ">
                                <thead>
                                    <tr>
                                        <th className="onlyforwiirtg" >Title</th>
                                        <th className="onlyforwiirtg">Reward</th>
                                        <th className="onlyforwiirtg">Edit</th>
                                        {/* <th>Description</th> */}
                                        {/* <th>Remove</th> */}
                                    </tr>
                                </thead>
                                <tbody className="main-t-body-text" >
                                    {/* {News.length > 0 ? News : "No data found"} */}
                                    {/* <tr>
                                        <td className=' forbolddddd'>
                                            Sign Up and get 20 Bolts
                                        </td>
                                        <td className=''>20 Bolts</td>
                                        <td className=''><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                            <g clip-path="url(#clip0_277_141271)">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_277_141271">
                                                    <rect width="22" height="22" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg></td>

                                    </tr> */}
                                    {
                                        myFiles?.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className=''>
                                                    <p className="main-image forbolddddd">
                                                            {data?.description}
                                                        </p>

                                                    </td>
                                                    <td className=''>

                                                        <p className="forbolddddd">
                                                            {data?.boltReward} Bolts
                                                        </p>

                                                    </td>
                                                    <td className=''>
                                                        <svg
                                                            onClick={() => {
                                                                handleShow(); // Add parentheses to call the function
                                                                setId(data?.id);
                                                            }}

                                                            xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                            <g clip-path="url(#clip0_277_141271)">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_277_141271">
                                                                    <rect width="22" height="22" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {/* <ToastContainer style={{ fontSize: 20 }} /> */}
                        </div>
                    </div>
                </section>

            </div>
            <Modal className='ambmodalmain userlastmodal  addrewadsssdd' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="">Streak Rewards</Modal.Title>
                </Modal.Header>
                <Modal.Body className='userlastmodrewards'>
                    <div class="form-group">
                        <label className="rewardslable" for="example">Title</label>
                        <input type="text" name="rewards" className="rewardinput pr-3" placeholder="Title" value={editTitle} onChange={(e) => setEditTitle(e?.target?.value)} />
                    </div>
                    <div class="form-group">
                        <label className="rewardslable" for="example">Reward</label>
                        <input type="number" min="0" name="rewards" className="rewardinput pr-3" placeholder="Enter Rewards" value={editReward} onChange={(e) => setEditReward(e?.target?.value)} />
                    </div>

                    {/* <div class="form-group">
                        <label className="rewardslable" for="example">Day 1 Reward</label>
                        <input type="text" name="rewards" className="rewardinput" placeholder="Enter Rewards" />

                    </div>
                    <div class="form-group">
                        <label className="rewardslable" for="example">Day 2 Reward</label>
                        <input type="text" name="rewards" className="rewardinput" placeholder="Enter Rewards" />

                    </div>
                    <div class="form-group">
                        <label className="rewardslable" for="example">Day 3 Reward</label>
                        <input type="text" name="rewards" className="rewardinput" placeholder="Enter Rewards" />

                    </div>
                    <div class="form-group">
                        <label className="rewardslable" for="example">Day 4 Reward</label>
                        <input type="text" name="rewards" className="rewardinput" placeholder="Enter Rewards" />

                    </div>
                    <div class="form-group">
                        <label className="rewardslable" for="example">Day 5 Reward</label>
                        <input type="text" name="rewards" className="rewardinput" placeholder="Enter Rewards" />

                    </div>
                    <div class="form-group">
                        <label className="rewardslable" for="example">Day 6 Reward</label>
                        <input type="text" name="rewards" className="rewardinput" placeholder="Enter Rewards" />

                    </div> */}
                    <div className="videopagerewards text-center">
                        <button className="red-b" onClick={editAdsRewardHandle}>Save</button>
                        <button className="red-w" onClick={handleClose}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Streakrewards;
