
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import './badges.scss';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { Dropdown } from "react-bootstrap";

// reactstrap components
function PushBadges(props) {






    const id = props.match.params.id;
    console.log("asdkfasdfasd", id)
    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('mytoken')
    const history = useHistory();
    const [state, setState] = useState(0);
    const [selectedImg, setSelectedImg] = useState();
    const [selectedImg2, setSelectedImg2] = useState();
    const [myFiles, setMyFiles] = useState({});
    const [myFiles2, setMyFiles2] = useState({});
    const [myFiles1, setMyFiles1] = useState();
    const [myFiles12, setMyFiles12] = useState();
    const [inputs, setInputs] = useState({
        name: '',
        description: '',
        type: ''
    })
    const { name, description, type } = inputs;

    // search 
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 

    console.log("fielee", myFiles1)
    const handleChange1 = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const handleCategory = (event) => {
        console.log("events", event.target.value)
        setInputs(inputs => ({ ...inputs, type: event.target.value }));

    }
    // console.log("newchn",id, inputs, selectedImg)

    const renderPhotos = (source) => {
        return (
            <>
                <div id="imgg1" className="mainimgset">
                    <img src={source} alt="" className="innerimgset" />
                    <input type="file" id="imgg1" name="image" accept="image/*" className={'form-control d-none'} onChange={handleFileSelect} />
                </div>
            </>
        )
    }
    const renderPhotos2 = (source) => {
        return (
            <div id="imgg2" className="mainimgset">
                <img src={source} alt="" className="innerimgset" />
                <input type="file" id="imgg2" name="image" accept="image/*" className={'form-control d-none'} onChange={handleFileSelect2} />
            </div>
        )
    }
    const handleFileSelect = (evt) => {
        console.log("sfsdfsdsdfsdfsd", evt)
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles(file)
        if (id) {
            setMyFiles1(file)
        }
    }
    const handleFileSelect2 = (evt) => {
        console.log("sfsdfsdsdfsdfsd", evt)
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg2(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles2(file)
        if (id) {
            setMyFiles12(file)
        }
    }
    const FIndBadge = () => {
        setOpen(true)
        axios.get(Environment.backendUrl + "/badges/find/" + id, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setInputs(response.data.badges)
                setSelectedImg(response.data.badges.image)
                setSelectedImg2(response.data.badges.sharedImage)
                setOpen(false)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const AddBadges = (e) => {
        e.preventDefault()
        const data = new FormData();
        data.append("image", myFiles)
        data.append("sharedImage", myFiles2)
        data.append("name", inputs.name)
        data.append("description", inputs.description)
        data.append("type", inputs.type)
        setOpen(true)
        if (myFiles && name && description !== '') {
            axios.post(Environment.backendUrl + '/badges/add', data, { headers: { "Authorization": `Bearer ${token}` } })
                .then((response) => {
                    setOpen(false)
                    setTimeout(() => {
                        toast.success(response.data.msg, {
                            position: "top-center",
                            autoClose: 1000,
                        });
                    }, 1000);

                    setInputs({
                        name: '',
                        description: '',
                        Type: '',
                    })
                    setSelectedImg('')
                    setMyFiles('')
                    history.push('/admin/badges')
                    // setOpen(true)
                }).catch((err) => {
                    setOpen(false)
                    toast.error("Badge Not Created", {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            setOpen(false)
            toast.error("Field Cannot be empty", {
                position: "top-center",
                autoClose: 2000,
            });
        }

    }

    const editBadge = async (event) => {
        setOpen(true)
        event.preventDefault()
        const data = new FormData();
        if (myFiles1) {
            data.append("image", myFiles1)
        }
        if (myFiles12) {
            data.append("sharedImage", myFiles12)
        }
        data.append("name", inputs.name)
        data.append("description", inputs.description)
        data.append("type", inputs.type)
        data.append("id", id)
        axios.post(Environment.backendUrl + "/badges/edit", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                setOpen(false)
                setTimeout(() => {
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 1000,
                    });
                }, 1000);

                setInputs({
                    name: '',
                    description: '',
                    type: '',
                })
                setSelectedImg('')
                setMyFiles('')
                history.push('/admin/badges')
                // console.log(response)
                // setOpen(true)
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const cancel = () => {
        history.push('/admin/badges')
    }

    useEffect(() => {
        if (id) {
            FIndBadge()
        }
    }, [id])

    // const images = importAll(require.context('assets/img/userflow', false, /\.(png|jpe?g|svg)$/));
    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <section className="addbanner1234 send-notifications card">
                    <form>
                        <div className="inner-submit-lower-div">
                            <div class="row">
                                <div class="col-md-7  col-sm-12 order-md-0 order-1">
                                    <div className="bbadges_flex">

                                        <div class="form-group rightside-for-group">
                                            <label for="exampleInputsymbol" style={{ display: "block" }}>Badge Image</label>
                                            <label id="immmmm">
                                                <div className="dashed-border-new">
                                                {selectedImg ? renderPhotos(selectedImg) : null}
                                                {selectedImg ? 
                                                  null
                                                    :
                                               
                                                    <div className="main-image-div text-center">
                                                    {/* {selectedImg ? renderPhotos(selectedImg) : null} */}

                                                    <img
                                                        src="\dashboard-assets\upload.svg"
                                                        alt="img"
                                                        className="img-fluid dragimg"
                                                    />
                                                    <div className="choose-filessss">
                                                        <p className='draganddrop'>Drag & drop or <span className='common'>Browse</span></p>
                                                        <input type="file" id="immmmm" name="image" accept="image/*" className={'form-control d-none'} onChange={handleFileSelect} />
                                                        {/* <form action="" className="style-actionn">
                                                        <input type="file" className="custom-file-inputt" accept="video/*" id="myFile" name="filename" />
                                                    </form> */}
                                                    </div>
                                                </div>
                                                }
                                                </div>
                                            </label>

                                        </div>

                                        <div class="form-group rightside-for-group">
                                            <label for="exampleInputsymbol"> Badge Banner Image</label>
                                            <div className="dashed-border-new">
                                            {selectedImg2 ? renderPhotos2(selectedImg2) : null}

                                            {
                                                selectedImg2 ?
                                                null
                                                :
                                                <div className="main-image-div text-center">
                                             
                                                <label id="img">
                                                    <img
                                                        src="\dashboard-assets\upload.svg"
                                                        alt="img"
                                                        className="img-fluid dragimg"
                                                    />
                                                    <div className="choose-filessss">

                                                        <p className='draganddrop'>Drag & drop or <span className='common'>Browse</span></p>
                                                        <input type="file" id="img" name="image" accept="image/*" className={'form-control d-none'} onChange={handleFileSelect2} />
                                                        {/* <form action="" className="style-actionn">
                                                        <input type="file" className="custom-file-inputt" accept="video/*" id="myFile" name="filename" />
                                                    </form> */}
                                                    </div>
                                                </label>
                                            </div>
                                            }
                                              
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 main-send-message">
                                            {/* <h3>Add Badges</h3> */}
                                            <div class="form-group">
                                                {console.log("injsxxx", inputs)}
                                                <label className="padd-top" for="example">Name</label>
                                                {/* <textarea class="form-control" name="name" value={inputs?.name} onChange={handleChange1} id="exampleFormControlTextarea1" placeholder="Title" rows="2"></textarea> */}
                                                {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}

                                                <input class="form-control" name="name" value={inputs?.name} onChange={handleChange1} type="text" placeholder='Name' />
                                            </div>

                                            <div class="form-group">
                                                <label className="padd-top" for="example">Type</label>
                                                <div class="dropdown "  className="styleeee" type="button" data-toggle="dropdown" aria-expanded="false">
                                                    <button class="btn newwwwwwwwwwwwwbtnn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                    Type
                                                        <span className="">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                                                <path d="M2.4345 5.31539C2.4055 5.28714 2.2815 5.18047 2.1795 5.0811C1.538 4.49854 0.488 2.97881 0.1675 2.18339C0.116 2.06259 0.007 1.75718 0 1.59401C0 1.43765 0.036 1.2886 0.109 1.14637C0.211 0.96907 0.3715 0.826839 0.561 0.748904C0.6925 0.698734 1.086 0.620799 1.093 0.620799C1.5235 0.542864 2.223 0.5 2.996 0.5C3.7325 0.5 4.4035 0.542864 4.8405 0.606673C4.8475 0.61398 5.3365 0.691914 5.504 0.777155C5.81 0.933512 6 1.23892 6 1.56576V1.59401C5.9925 1.80687 5.8025 2.25451 5.7955 2.25451C5.4745 3.00706 4.476 4.49172 3.8125 5.08841C3.8125 5.08841 3.642 5.25645 3.5355 5.32952C3.3825 5.4435 3.193 5.5 3.0035 5.5C2.792 5.5 2.595 5.43619 2.4345 5.31539Z" fill="#CDCDCD" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                    <div
                                                        native
                                                        onChange={handleCategory}
                                                        value={inputs?.type}
                                                        inputProps={{
                                                            state
                                                        }}
                                                        class="dropdown-menu w-100 mydropppp"
                                                    >

                                                        <a className="dropdown-item namediv" >Type</a>
                                                        <a className="dropdown-item namediv" >Legion App</a>
                                                        <a className="dropdown-item namediv" >Launch Pad</a>
                                                        <a className="dropdown-item namediv" >Arcadia</a>
                                                        <a className="dropdown-item namediv" >Empower</a>
                                                        <a className="dropdown-item namediv" >Rewards</a>
                                                        {/* {categoryData} */}
                                                    </div>

                                                </div>
                                                {/* <Dropdown className="amer_dropdonfst styleeee w-100">
                                                    <Dropdown.Toggle id="dropdown-basic" native
                                                        onChange={handleCategory}
                                                        value={inputs?.type}
                                                        inputProps={{
                                                            state
                                                        }}>
                                                        Sort by
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="mydropppp">
                                                        <Dropdown.Item href="#/action-1" className="namediv main-boot">Name</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2" className="namediv">Join Date</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3" className="namediv">Verification Status</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown> */}



                                            </div>
                                            <div class="form-group">
                                                <label className="padd-top" for="example">Description</label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1" name="description" value={inputs?.description} onChange={handleChange1} placeholder="Requirements" rows="5"></textarea>
                                                {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group">
                                            <div className="videopage">
                                                {id ? <button className="red-b" onClick={editBadge}>Save Changes</button> : <button className="red-b" onClick={AddBadges}>Publish</button>}
                                                <Link to="/admin/badges">
                                                    <button className="red-w mt-3 mt-sm-0" onClick={cancel}>Cancel</button>
                                                    {/* <button className="red-b" >Unpublish</button> */}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <ToastContainer style={{ fontSize: 20 }} /> */}
                                </div>
                                <div className="col-md-5 order-md-1 order-0">

                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </>
    );
}

export default PushBadges;
