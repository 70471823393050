import { Backdrop, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Environment from 'utils/Environment';
import './addnewcategory.scss';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

const Addnewcategory = (props) => {

  // search 
  const [searchTerm, setSearchTerm] = useState('');
  const [limit] = useState(10);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  // Pagination
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage)
  };

  const searchsubmit = (e) => {
    setPage(0)
    setPage(0)
    // if (page == 0) {
    //   getAllUser()
    // }
  }

  // search 

  const history = useHistory();
  const [totalProducts, setTotalProducts] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const token = localStorage.getItem('mytoken')
  const [open, setOpen] = useState(false);

  const params = useParams();

  const id = props.match.params.id;
  console.log('raffel cate', params.id)


  const createCategory = async () => {
    if (totalProducts && categoryName) {
      try {
        setOpen(true)
        const res = await axios.post(`${Environment.backendUrl}/productsCategory/add`, { totalProducts, categoryName }, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' } })
        toast.success('Product Category Added', {
          position: "top-center",
          autoClose: 2000,
        })
        history?.push('/admin/newcategory');
        setOpen(false)
      } catch (error) {
        setOpen(false)
        toast.error('Category Not added !', {
          position: "top-center",
          autoClose: 2000,
        })
      }
    } else {
      toast.error('Fill Fields')
    }
  }
  const editCategory = async () => {
    if (totalProducts && categoryName) {
      try {
        setOpen(true)
        const res = await axios.post(`${Environment.backendUrl}/productsCategory/edit`, { id, totalProducts, categoryName }, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' } })
        toast.success('Product Category Editted', {
          position: "top-center",
          autoClose: 2000,
        })
        history?.push('/admin/newcategory');
        setOpen(false)
      } catch (error) {
        setOpen(false)
        toast.error('Category Not editted !', {
          position: "top-center",
          autoClose: 2000,
        })
      }
    } else {
      toast.error('Fill Fields')
    }
  }
  const getSingleCategory = async () => {
    setOpen(true)
    axios.get(Environment.backendUrl + `/productsCategory/find/${id}`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        console.log('single sub', response?.data?.productCategory)
        setCategoryName(response?.data?.productCategory?.categoryName)
        setTotalProducts(response?.data?.productCategory?.totalProducts)
        console.log('dsafsadfasfasdf', response)
        setOpen(false)
        // window.location.assign('/admin/newsbanner')
      }).catch((err) => {
        setOpen(false)
        toast.error(err.response.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      })
  }
  useEffect(() => {
    if (id) {
      getSingleCategory()
    }
  }, [])
  // useEffect(() => {
  //   if (!(id === '4577432')) {
  //     getSingleCategory()
  //   }
  // }, [])

  return (
    <>
      <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
      <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

      <div className="content">
        <section className='addnewcategory'>
          <div className="row">
            <div className="col-lg-8">
              <p>Category</p>
              <input value={categoryName} onChange={(e) => setCategoryName(e.target.value)} type="text" placeholder='Name' />
              <p>Total Products</p>
              <input value={totalProducts} onChange={(e) => setTotalProducts(e.target.value)} type="number" placeholder='amount' />
              <div className="btnslasts">
                {/* <button onClick={id === '4577432' ? createCategory : editCategory} className="bluebtn">Save</button> */}
                {
                  id ?
                    <button onClick={editCategory} className="bluebtn">Save</button>
                    :
                    <button onClick={createCategory} className="bluebtn">Publish</button>
                }
                <Link to="/admin/newcategory">
                  <button className="whitebtn">Cancel</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Addnewcategory
