
import React, { useEffect, useState } from "react";
import './notification.scss';
import Environment from "utils/Environment";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import DemoNavbar from "components/Navbars/DemoNavbar.js";

// reactstrap components
function Notificationss(props) {

    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('mytoken')
    const Acls = JSON.parse(localStorage.getItem('acls'))
    const [myFiles, setMyFiles] = useState([]);

    // search 
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 


    const changeDateFormate = (createdAt) => {
        console.log("🚀 ~ changeDateFormate ~ createdAt:", createdAt);
        const originalDate = new Date(createdAt);

        // Format the date and time
        const formattedDate = originalDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true, // Use 12-hour clock, you can set it to false for 24-hour clock
        });

        // Return the formatted date and time
        return formattedDate;
    }

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const getNotification = () => {
        setOpen(true)
        axios.get(Environment.backendUrl + "/notification/all/admin", { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setMyFiles(response.data.notifications)
                setOpen(false)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const noti = myFiles.map((elem) => {
        return (
            <tr>
                <td className='main-image'>
                    <img src={elem?.image} className="pr-2 imgages-no" alt="" />
                </td>
                <td className=''>{elem?.title}</td>
                <td className=''>{elem?.description}</td>
                <td className=''>{elem?.createdAt.split('T')[0]}</td>
                <td className=''>{elem?.to}</td>
                {Acls?.notification?.delete ? <td><button className="buttons-remove" type="button" onClick={() => notiDel(elem.id)}>
                    
                    
                    
                    <i className="far fa-trash-alt"></i>
                    
                    
                    
                    </button></td> : ''}
            </tr>
        )
    })

    const notiDel = (de) => {
        setOpen(true)
        const id = de
        axios.get(Environment.backendUrl + "/notification/delete/" + id, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setOpen(false)
                toast.success('Notification Deleted ', {
                    position: "top-center",
                    autoClose: 2000,
                });
                getNotification()

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }

    useEffect(() => {
        getNotification()
    }, [token])

    const images = importAll(require.context('assets/img/userflow', false, /\.(png|jpe?g|svg)$/));

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

                {/* <section className="videos notifications card">
                    <div className="container-fluid">
                        <div class="table-responsive">
                            <table class="table ">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th > Creation Date <img src={`${images['arrow-down.png']['default']}`} className="pl-1" alt="" /></th>
                                        <th > Platform <img src={`${images['arrow-down.png']['default']}`} className="pl-1" alt="" /></th>
                                        <th>Remove </th>
                                    </tr>
                                </thead>
                                <tbody className="main-t-body-text" >
                                    {noti}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section> */}
                <section className="content">
                    <div className="main-tasks user-details">
                 
                        <div className='maintablea_task table-responsive'>
                                <table className="mt-0">
                                    <thead>
                                    <tr>
                                        <th>Message</th>
                                        <th > Creation Date      <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                <g opacity="0.7">
                                                    <path d="M2.4345 4.81539C2.4055 4.78714 2.2815 4.68047 2.1795 4.5811C1.538 3.99854 0.488 2.47881 0.1675 1.68339C0.116 1.56259 0.007 1.25718 0 1.09401C0 0.937652 0.036 0.788602 0.109 0.646371C0.211 0.46907 0.3715 0.326839 0.561 0.248904C0.6925 0.198734 1.086 0.120799 1.093 0.120799C1.5235 0.0428641 2.223 0 2.996 0C3.7325 0 4.4035 0.0428641 4.8405 0.106673C4.8475 0.11398 5.3365 0.191914 5.504 0.277155C5.81 0.433512 6 0.738919 6 1.06576V1.09401C5.9925 1.30687 5.8025 1.75451 5.7955 1.75451C5.4745 2.50706 4.476 3.99172 3.8125 4.58841C3.8125 4.58841 3.642 4.75645 3.5355 4.82952C3.3825 4.9435 3.193 5 3.0035 5C2.792 5 2.595 4.93619 2.4345 4.81539Z" fill="#030229" />
                                                </g>
                                            </svg></th>
                                        <th > Platform
                                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                <g opacity="0.7">
                                                    <path d="M2.4345 4.81539C2.4055 4.78714 2.2815 4.68047 2.1795 4.5811C1.538 3.99854 0.488 2.47881 0.1675 1.68339C0.116 1.56259 0.007 1.25718 0 1.09401C0 0.937652 0.036 0.788602 0.109 0.646371C0.211 0.46907 0.3715 0.326839 0.561 0.248904C0.6925 0.198734 1.086 0.120799 1.093 0.120799C1.5235 0.0428641 2.223 0 2.996 0C3.7325 0 4.4035 0.0428641 4.8405 0.106673C4.8475 0.11398 5.3365 0.191914 5.504 0.277155C5.81 0.433512 6 0.738919 6 1.06576V1.09401C5.9925 1.30687 5.8025 1.75451 5.7955 1.75451C5.4745 2.50706 4.476 3.99172 3.8125 4.58841C3.8125 4.58841 3.642 4.75645 3.5355 4.82952C3.3825 4.9435 3.193 5 3.0035 5C2.792 5 2.595 4.93619 2.4345 4.81539Z" fill="#030229" />
                                                </g>
                                            </svg>



                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            myFiles?.map((data, index) => {
                                                return (
                                                    <tr>
                                                        <td className="onlyboldtext eleipiess">{data?.title}</td>
                                                        <td className="onlyboldtext">{changeDateFormate(data?.updatedAt)}</td>
                                                        <td className="onlyboldtext">{data?.to}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                 
                    </div>
                </section>
          
        </>
    );
}

export default Notificationss;
