import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import AdminLayout from "layouts/Admin.js";
import Login from "components/login/Login";
import Reffrel from "components/refrel/Reffrel.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FactorAuth from 'components/2FA/FactorAuth';
import useEagerConnect from './hooks/useEagerConnect'
import "rsuite/dist/rsuite.css";
function App() {

    useEagerConnect();

    return (
        <>
            <ToastContainer style={{ fontSize: 20, width: '400px' }} />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/auth" render={(props) => <FactorAuth {...props} />} />
                    <Route exact path="/adminlogin" render={(props) => <Login {...props} />} />
                    <Route exact path="/factorLogin" render={(props) => <FactorAuth {...props} />} />
                    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                    <Route path="/reffrel" render={(props) => <Reffrel {...props} />} />
                    <Redirect to="/adminlogin" />
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;