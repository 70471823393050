/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { Link, useLocation } from "react-router-dom";
import user4 from "assets/img/userflow/add1.png";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from "utils/Environment";
// import { inputAction } from '../../redux/action/action';
// import { useDispatch } from 'react-redux'
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Container,
  // InputGroup,
  // InputGroupText,
  // InputGroupAddon,
  // Input,
} from "reactstrap";

import routes from "routes.js";
import { Accordion, Button, ButtonGroup, Card, Dropdown, FormLabel, InputGroup } from "react-bootstrap";
import SendGiftModal from "components/gifts/SendGiftModal";
import DateRangePicker from 'rsuite/DateRangePicker';



const Header = (props) => {
  // console.log("🚀 ~ file: DemoNavbar.js:50 ~ props:", props)

  const [showcalendar4, setShowCalendar4] = useState(false);
  const [showw, setShoww] = useState(false);
  const handleClose = () => setShoww(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedImg, setSelectedImg] = useState();
  const [myFiles, setMyFiles] = useState({});
  const token = localStorage.getItem('mytoken')
  const Acls = JSON.parse(localStorage.getItem('acls'))
  const role = localStorage.getItem('myrole')
  const [open, setOpen] = useState(false);
  const [brandName, setbrandName] = React.useState();
  // const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const [activeKey, setActiveKey] = useState(null);

  // console.log("brandnaemmee 🚀 ~ routes.map ~ prop.name: okokok", brandName)


  function capitalize(str) {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const [show, setShow] = useState(false);

  const handleToggle = (nextShow) => {
    setShow(nextShow);
  };

  const tastTimeSelecter = (eventKey) => {
    let period;
    if (eventKey === 'day') {
      period = 'day';
    } else if (eventKey == 'week') {
      period = 'week';
    } else if (eventKey == 'month') {
      period = 'month';
    } else if (eventKey == '90days') {
      period = '90days';
    } else if (eventKey == 'year') {
      period = 'year';
    } else if (eventKey == 'all') {
      period = 'all';
    }
    props?.setSelectedPeriod(period);
    props?.setCalledAPI(false);
  };

  const sortByHandel = (eventKey) => {
    props?.setSortBy(eventKey);
    props?.setCalledAPI(false);
  };

  const sortByCoursesHandel = (eventKey) => {
    console.log('courseSortBy, okokokok');

    props?.setCourseSortBy(eventKey);
    props?.setCalledAPI(false);
  };





  // const getBrand = () => {
  //   routes.map((prop, key) => {
  //     if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
  //       // brandname = prop.name;

  //       console.log("🚀 ~ routes.map ~ prop.name: okokok", prop.name, prop.path)
  //       setbrandName(prop.name)
  //     }
  //     return null;
  //   });
  // };

  const getBrand = () => {
    routes.map((prop) => {
      const routePattern = new RegExp(`^${prop.layout}${prop.path.replace(/:\w+/g, '[^/]+')}$`);

      // console.log('Generated routePattern:', routePattern);
      // console.log('Current pathname:', window.location.pathname);

      if (routePattern.test(window.location.pathname)) {
        // console.log("🚀 ~ routes.map ~ prop:", prop);
        // console.log("🚀 ~ routes.map ~ prop.name:", prop.name);
        // console.log("🚀 ~ routes.map ~ prop.path:", prop.path);
        setbrandName(prop.name);
      }
      return null;
    });
  };



  const [submitted, setSubmitted] = useState(false);
  const [inputs, setInputs] = useState({
    category: '',
    search: ''
  })

  const renderPhotos = (source) => {
    return <img src={source} alt="" width="200" height="200" />
  }
  const { category } = inputs;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  const handleFileSelect = (evt) => {
    if (evt.target.files) {
      const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));
      setSelectedImg(filesarray[0]);
      // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
    }
    var files = evt.target.files;
    var file = files[0];
    setMyFiles(file)
  }

  const addCategory = (e) => {
    setOpen(true)
    const data = new FormData();
    data.append("image", myFiles)
    data.append("name", category)
    if (category !== '' && myFiles !== '') {
      axios.post(Environment.backendUrl + "/videoCategory/add", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          setOpen(false)
          toast.success("Category Added", {
            position: "top-center",
            autoClose: 3000,
          });
          setInputs({
            category: '',
          })
          setSelectedImg('')
          window.$('#exampleModal34').modal('hide')
          window.location.reload();

        }).catch((err) => {
          setOpen(false)
          toast.error(err.response.data.msg, {
            position: "top-center",
            autoClose: 2000,
          });
        })
    } else {
      close()
      setOpen(false)
      toast.error("Category cannot be empty", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  }

  const close = () => {
    window.$('#exampleModal34').modal('hide')
  }
  const opeeennn = () => {
    window.$('#exampleModal34').modal('show')
  }
  useEffect(() => {
    getBrand()
  }, [])

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);


  const [showDropdown, setShowDropdown] = useState(false);
  const { filerByAccessList, setFilerByAccessList } = props;
  const [isEveryoneChecked, setIsEveryoneChecked] = useState(false);
  console.log(filerByAccessList, "filerByAccessList in header");


  const handleCloseD = () => {
    setShowDropdown(false);
    setSelectedView('');
    setFilerByAccessList('');
  };

  const toggleDropdownD = () => {
    setShowDropdown(!showDropdown);
  };

  // console.log("brancd name", brandName)


  const handleSelect = async (eventKey) => {
    if (eventKey) {
      props?.setSortItem(eventKey);
      props?.setSortOrder('asc');
      await props?.handleApplyClick();  // Ensure the apply function is called here
    }
    // console.log(`Selected: ${eventKey}`);
  };


  const [selectedView, setSelectedView] = useState('');

  const handleCheckboxClick = (view) => {
    if (selectedView === view) {
      setSelectedView(''); // Uncheck if the same checkbox is clicked again
      props?.setFilerByView('');
    } else {
      setSelectedView(view);
      props?.setFilerByView(view);
    }
    props?.setCalledAPI(false);
  };

  const clearlAllHandle = () => {
    setSelectedView('');
    setFilerByAccessList('');
    props?.setFilterByDate('');
    setIsEveryoneChecked(false);
  }

  const clearlAllHandleD = () => {
    props?.setCourseFilters('');
  }


  // Effect to handle "Everyone" checkbox state based on filerByAccessList
  useEffect(() => {
    if (filerByAccessList?.length > 0) {
      setIsEveryoneChecked(false);
    }
  }, [filerByAccessList]);

  // Handle checkbox click for individual access lists
  const handleCheckboxClick1 = (value) => {
    if (filerByAccessList?.includes(value)) {
      // Remove the value from the list if it's already selected
      setFilerByAccessList(filerByAccessList?.filter((item) => item !== value));
    } else {
      // Add the value to the list if it's not selected
      setFilerByAccessList([...filerByAccessList, value]);
    }
    // Update the "Everyone" checkbox state
    if (filerByAccessList?.length === 0 && !isEveryoneChecked) {
      setIsEveryoneChecked(true);
    } else {
      setIsEveryoneChecked(false);
    }
  };

  // Handle "Everyone" checkbox click
  const handleEveryoneClick = () => {
    // If "Everyone" is checked, clear all filters
    if (!isEveryoneChecked) {
      setFilerByAccessList([]);
      setIsEveryoneChecked(true);
    } else {
      // If "Everyone" is unchecked, uncheck "Everyone" and update the filters
      setIsEveryoneChecked(false);
    }
  };

  const disableFutureDates = (date) => {
    return date.getTime() > new Date().getTime(); // Disable dates after today
  };

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     // Trigger your function here
  //     props?.searchsubmit();
  //     console.log('Enter key pressed. Input value:', event);
  //     // You can call any function here or perform any action
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.target.value.trim() !== '') {
        // If input has value, trigger searchsubmit
        props?.searchsubmit();
        console.log('Enter key pressed. Input value:', event.target.value);
      } else {
        // Clear the input if it's empty
        event.target.value = '';
        console.log('Input cleared');
      }
    }
  };

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <div className="main-div-nav-head">
      <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
      <Navbar
        color={
          props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : color
        }
        expand="md"
        className={
          props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid className="main-header-top-change">
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={sidebarToggle}
                className="navbar-toggler"
                onClick={() => openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand >{brandName}</NavbarBrand>
          </div>
          <NavbarToggler onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="justify-content-end text-center">
            {/* <form>
              <InputGroup className="no-border">
                <Input name="search" value={search} onChange={handleChange} className={'form-control'} placeholder="Search..." />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <button onClick={() => dispatch(inputAction(inputs.search))} type="button" className="nav-buttonss" > <i className="nc-icon nc-zoom-split" /></button>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form> */}

            {brandName === 'Videos / Videos' && Acls?.videos?.create && <Link to={`/admin/addvideos`}> <button type="button" className="blue-add-category ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>  Add Video</button></Link>}

            {/* {console.log(brandName, " otherBanner addd")} */}
            {brandName === 'Add Video' &&
              <>
              </>
            }
            {(brandName === 'Banner / Game Banner' && Acls?.gameBanner?.create) ? <Link to={`/admin/addBanners/game`}> <button type="button" className="blue-add-category ml-2">


              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Banner</button></Link> : null}
            {brandName === 'Banner / News Banner' ? <Link to={`/admin/addBanners`}> <button type="button" className="blue-add-category ml-2">


              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Banner</button></Link> : null}
            {brandName === 'Other Banner' ?
              <>
                {/* <Link to={`/admin/addBanners`}> <button type="button" className="blue-add-category ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <g clip-path="url(#clip0_564_35514)">
                      <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_564_35514">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                  Add Banner</button>
                </Link> */}
              </>
              : null}
            {/* {console.log('otherBanner', brandName)} */}
            {brandName === 'Play To Earn' && Acls?.playToEarn?.create && <Link to={`/admin/addplaytoearn`}> <button type="button" className="blue-add-category ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>


              Add Play to Earn</button></Link>}
            {brandName === 'Games / Add Play to Earn' && Acls?.playToEarn?.create && <Link to={`/admin/addplaytoearn`}> </Link>}
            {brandName === 'Games / Add Daily Challanges' && Acls?.playToEarn?.create && <Link to={`/admin/addplaytoearn`}> </Link>}
            {brandName === 'Upcoming' && Acls?.upComming?.create && <Link to={`/admin/addupcoming`}> <button type="button" className="blue-add-category ml-2">           <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <g clip-path="url(#clip0_564_35514)">
                <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_564_35514">
                  <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>Add Upcoming</button></Link>}
            {brandName === 'Daily Challange' && Acls?.dailyChallenge?.create && <Link to={`/admin/adddailychallange`}> <button type="button" className="blue-add-category ml-2">           <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <g clip-path="url(#clip0_564_35514)">
                <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_564_35514">
                  <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>Add Challanges</button></Link>}

            {brandName === 'Task' && Acls?.task?.create &&
              <div className="newinputs">
                <Dropdown className="amer_dropdonfst"
                  onSelect={tastTimeSelecter}
                // onSelect={(eventKey) => {
                //   if (eventKey) {
                //     props?.setGiftSortItem(eventKey);
                //   }
                //   console.log(`Selected keyyyyy: tasksss ${eventKey}`);
                // }}
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    {/* {props?.selectedPeriod ? props?.selectedPeriod : "This week"} */}
                    {props?.selectedPeriod ? props?.selectedPeriod === "90days" ? "90 days" : capitalize(props?.selectedPeriod) : "Sort by"}
                    {/* {console.log(props?.selectedPeriod, "Selected keyyyyy okokokok")} */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" eventKey="day">24 hours</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="week">7 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="month">30 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="90days">90 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="year">year</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="all">All</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Link to={`/admin/addtask`}>

                  <button type="button" className="blue-add-category ml-2">           <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <g clip-path="url(#clip0_564_35514)">
                      <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_564_35514">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>  Add Task</button>
                </Link>


              </div>


            }

            {brandName === 'Rewards / LeaderBoard' && Acls?.leaderBoard?.create &&
              <>
                <div className="custom-tab-bar d-flex justify-content-center p-0 border-0">
                  {/* <div className="newinputs">
                    <div className="inputoutermain">
                      {
                        props?.searchTerm == null && props?.searchTerm == undefined && props?.searchTerm !== '' ?
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                            <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                            <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                          </svg>
                          :
                          <svg style={{ cursor: "pointer" }} onClick={props?.searchsubmit} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                            <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                            <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                          </svg>
                      }
                      <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" value={props?.searchTerm} onChange={(e) => props?.setSearchTerm(e?.target?.value)} />
                    </div>
                  </div> */}

                  {/* <Dropdown className="amer_dropdonfst"
                  onSelect={(eventKey) => {
                    if (eventKey) {
                      props?.setGiftSortItem(eventKey);
                    }
                    // console.log(`Selected keyyyyy: ${eventKey}`);
                  }}
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    {props?.giftSortItem ? props?.giftSortItem === "90days" ? "90 days" : capitalize(props?.giftSortItem) : "Sort by"}
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" eventKey="day">24 hour</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="week">7 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="month">30 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="90days">90 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="year">Year</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="All">All</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                </div>
              </>
            }

            {brandName === 'Streak Leaderboard' && Acls?.leaderBoard?.create &&
              <>
                {/* <div className="custom-tab-bar d-flex justify-content-end p-0 border-0">
                  <Link to={`/admin/Streakrewards`}>
                    <button type="button" className="tablebtnstyle"> Raffle</button></Link>
                </div> */}
                {/* <div className="newinputs">
                  <div className="inputoutermain">
                    {
                      props?.searchTerm == null && props?.searchTerm == undefined && props?.searchTerm !== '' ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                          <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                          <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                        </svg>
                        :
                        <svg style={{ cursor: "pointer" }} onClick={props?.searchsubmit} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                          <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                          <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                        </svg>
                    }
                    <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" value={props?.searchTerm} onChange={(e) => props?.setSearchTerm(e?.target?.value)} />
                  </div>
                </div> */}
              </>
            }

            {brandName === 'Ads Reward' && Acls?.leaderBoard?.create &&
              <></>
            }





            {/* {brandName === 'Rewards / LeaderBoard' && Acls?.leaderBoard?.create && <Link to={`/admin/dailyleaderboard`}>
              <button type="button" className="blue-add-category ml-2"> Past Week</button></Link>} */}

            {brandName === 'On boarding' &&
              <>
                <Link to={`/admin/addonboarding/.id}`}>
                  <button type="button" className="blue-add-category ml-2"> <img src={user4} className="img-fluid pr-2" alt="img" />  Add On Boarding</button>
                </Link>
              </>
            }

            {brandName === 'add boarding' && <></>}

            {brandName === 'Leaderboard/Details' && Acls?.leaderboarddetailsnew?.create && role === 'super-admin' &&


              <></>}


            {brandName === 'Reward / This Week Reward' && Acls?.weeksReward?.create && <Link to={`/admin/thisweekrewardform`}> <button type="button" className="blue-add-category ml-2">


              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Reward</button></Link>}





            {brandName === 'FAQs' && Acls?.faq?.create && <Link to={`/admin/AddFaq`}> <button type="button" className="blue-add-category ml-2">


              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add FAQ</button></Link>}
            {brandName === 'Coins' && Acls?.coins?.create &&
              <>
                <div className="newinputs">
                  <div className="inputoutermain">
                    {/* {console.log(props?.searchTerm, "props?.searchTerm Mystery Box Reward")} */}
                    {
                      props?.searchTerm ?
                        <svg style={{ cursor: "pointer" }} onClick={props?.searchsubmit} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                          <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                          <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                          <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                          <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                        </svg>
                    }
                    {/* <input type="text" name="full_name" className="ambassadorinput" placeholder="Search"
                      value={props?.searchTerm}
                      onChange={(e) => props?.setSearchTerm(e?.target?.value)}
                      onKeyDown={handleKeyDown} // Handle keydown event
                    /> */}
                    <input type="text" name="full_name" className="ambassadorinput" style={{ paddingRight: '40px' }} placeholder="Search"
                      value={props?.searchTerm}
                      onChange={(e) => props?.setSearchTerm(e?.target?.value)}
                      onKeyDown={handleKeyDown} // Handle keydown event
                    />
                  </div>
                  <Link to={`/admin/AddCoins`}>
                    <button type="button" className="blue-add-category ml-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <g clip-path="url(#clip0_564_35514)">
                          <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_564_35514">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                          </clipPath>
                        </defs>
                      </svg>
                      Add Coin
                    </button>
                  </Link>
                </div>
              </>
            }

            {brandName === 'AddCoin' && <Link to={`/admin/AddCoins`}> <button type="button" className="blue-add-category ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Coin</button></Link>}
            {brandName === 'Meme Coins' && <Link to={`/admin/AddMemeCoins`}> <button type="button" className="blue-add-category ml-2">


              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add MemeCoin</button></Link>}
            {brandName === 'Push Notifications' && Acls?.notification?.create && <Link to={`/admin/sendnotification`}> <button type="button" className="blue-add-category ml-2">  Send Notification</button></Link>}
            {brandName === 'Push Notification / Send Notification' && <></>}
            {/* {console.log(brandName, "push notiii")} */}
            {/* {(window.location.href.indexOf(prop.layout + prop.path) == a} */}
            {brandName === 'Videos' && Acls?.course?.create &&
              <>
                <div className="newinputs">
                  <div className="inputoutermain">
                    {
                      props?.searchTerm ?
                        <svg onClick={props?.searchsubmit} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                          <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                          <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                          <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                          <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                        </svg>
                    }
                    <input type="text" name="full_name" className="ambassadorinput" style={{ paddingRight: '40px' }} placeholder="Search"
                      value={props?.searchTerm}
                      onChange={(e) => props?.setSearchTerm(e?.target?.value)}
                      onKeyDown={handleKeyDown} // Handle keydown event
                    />
                  </div>
                  <Dropdown className="amer_dropdonfst" onSelect={sortByHandel}>
                    <Dropdown.Toggle id="dropdown-basic">
                      Sort by
                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                        <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="mydropppp">
                      <Dropdown.Item eventKey='newest' className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Newest
                          {
                            props?.sortBy == 'newest' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="oldest" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Oldest
                          {
                            props?.sortBy == 'oldest' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="shortest" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Shortest
                          {
                            props?.sortBy == 'shortest' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="longest" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Longest
                          {
                            props?.sortBy == 'longest' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="mostViews" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Most Views
                          {
                            props?.sortBy == 'mostViews' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="fewestViews" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Fewest Views
                          {
                            props?.sortBy == 'fewestViews' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="highestRewards" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Highest Rewards
                          {
                            props?.sortBy == 'highestRewards' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="lowestRewards" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Lowest Rewards
                          {
                            props?.sortBy == 'lowestRewards' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>


                  <Dropdown className="amer_dropdonfst" show={showDropdown} onToggle={toggleDropdownD}>
                    <Dropdown.Toggle className="filyerbyn" id="dropdown-basic" onClick={toggleDropdownD}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                        <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" strokeWidth="2" strokeLinecap="round" />
                        <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" strokeWidth="2" strokeLinecap="round" />
                        <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" strokeWidth="2" strokeLinecap="round" />
                        <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" strokeWidth="2" strokeLinecap="round" />
                      </svg>
                      Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="mydropppp  dropdownmennww" >
                      <Accordion className="mainaccordghhyyts" activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                        <div className="maininerdiv">
                          <p className="filterheading">
                            Filters
                          </p>
                          <p className="clearall" style={{ cursor: 'pointer' }} onClick={() => clearlAllHandle()}>
                            Clear All
                          </p>
                        </div>

                        <Card className="acrdgfstcover mb-0 p-0">
                          <Accordion.Toggle className="accodnhedg " variant="link" eventKey="0">
                            <div className="maininhgdiv">
                              <p className="filtepp">
                                Filter By Date
                              </p>
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                  <path d="M9.22726 0H4.77747H0.770515C0.084833 0 -0.258008 0.910697 0.227684 1.44455L3.92751 5.51129C4.52034 6.1629 5.48458 6.1629 6.07741 5.51129L7.48449 3.96467L9.77724 1.44455C10.2558 0.910697 9.91295 0 9.22726 0Z" fill="#007FFF" />
                                </svg>
                              </span>
                            </div>
                          </Accordion.Toggle>

                          <Accordion.Collapse eventKey="0">
                            <Card.Body className="pt-0">
                              <div className="w-100">
                                <div className="custom-tab-bar new-cls">
                                  <ButtonGroup size="sm" className="ButtonGroup">
                                    {
                                      console.log(props?.filterState, props?.filterByDate, 'props?.filterState filterByDate')
                                    }
                                    <Button className={props?.filterByDate == 'day' ? "filersCls" : ""} onClick={() => { props?.setFilterByDate('day'); props?.setCalledAPI(false) }}>1D</Button>
                                    <Button className={props?.filterByDate == 'week' ? "filersCls" : ""} onClick={() => { props?.setFilterByDate('week'); props?.setCalledAPI(false) }}>7D</Button>
                                    <Button className={props?.filterByDate == 'month' ? "filersCls" : ""} onClick={() => { props?.setFilterByDate('month'); props?.setCalledAPI(false) }}>1M</Button>
                                    <Button className={props?.filterByDate == 'year' ? "filersCls" : ""} onClick={() => { props?.setFilterByDate('year'); props?.setCalledAPI(false) }}>1Y</Button>
                                    <Button className={props?.filterByDate == 'all' ? "filersCls" : ""} onClick={() => { props?.setFilterByDate('all'); props?.setCalledAPI(false) }}>ALL</Button>
                                  </ButtonGroup>
                                  <div className='custom-daterangepicker'>
                                    <DateRangePicker placement='bottomEnd' disabledDate={disableFutureDates} />
                                  </div>
                                </div>
                              </div>


                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>

                        {/* <Card className="acrdgfstcover mb-0 p-0">
                          <Accordion.Toggle className="accodnhedg " variant="link" eventKey="1">
                            <div className="maininhgdiv">
                              <p className="filtepp">
                                Views
                              </p>
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                  <path d="M9.22726 0H4.77747H0.770515C0.084833 0 -0.258008 0.910697 0.227684 1.44455L3.92751 5.51129C4.52034 6.1629 5.48458 6.1629 6.07741 5.51129L7.48449 3.96467L9.77724 1.44455C10.2558 0.910697 9.91295 0 9.22726 0Z" fill="#007FFF" />
                                </svg>
                              </span>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="pt-0">
                              <div className="checkboxxxesssnewww">
                                <label className="checkBox m-0 warrrrw texxccser" onClick={() => handleCheckboxClick('')}>
                                  <input
                                    type="checkbox"
                                    id="ch1"
                                    className="boxxxxx"
                                    checked={selectedView === ''}
                                    readOnly
                                  />
                                  <p className="inputtext">Users</p>
                                </label>
                              </div>
                              <div className="checkboxxxesssnewww">
                                <label className="checkBox m-0 warrrrw texxccser" onClick={() => handleCheckboxClick('mostViewed')}>
                                  <input
                                    type="checkbox"
                                    id="ch12"
                                    className="boxxxxx"
                                    checked={selectedView === 'mostViewed'}
                                    readOnly
                                  />
                                  <p className="inputtext">Most Viewed</p>
                                </label>
                              </div>
                              {console.log(props?.filerByView, "props?.filerByView")}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card> */}

                        <Card className="acrdgfstcover mb-0 p-0">
                          <Accordion.Toggle className="accodnhedg " variant="link" eventKey="2">
                            <div className="maininhgdiv">
                              <p className="filtepp">
                                Availability
                              </p>
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                  <path d="M9.22726 0H4.77747H0.770515C0.084833 0 -0.258008 0.910697 0.227684 1.44455L3.92751 5.51129C4.52034 6.1629 5.48458 6.1629 6.07741 5.51129L7.48449 3.96467L9.77724 1.44455C10.2558 0.910697 9.91295 0 9.22726 0Z" fill="#007FFF" />
                                </svg>
                              </span>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="2">
                            <Card.Body className="pt-0">
                              <div className="checkboxxxesssnewww">
                                <label class="checkBox m-0 warrrrw texxccser">
                                  {/* <input type="checkbox" id="ch1" className="boxxxxx" /> */}
                                  <input
                                    type="checkbox"
                                    id="everyone"
                                    className="boxxxxx"
                                    checked={isEveryoneChecked}
                                    onChange={() => handleEveryoneClick('')}
                                  />
                                  <p className="inputtext">Everyone</p>
                                </label>
                              </div>
                              <Accordion>
                                <Card className="acrdgfstcover mb-0 p-0">
                                  <Accordion.Toggle className="accodnhedg p-0" variant="link" eventKey="accesslist">
                                    <div className="maininhgdiv ">
                                      <div className="checkboxxxesssnewww">
                                        <label class="checkBox m-0 warrrrw texxccser ml-0 pl-0">
                                          <p className="inputtext pl-0">Access List</p>
                                        </label>
                                      </div>
                                      <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                          <path d="M9.22726 0H4.77747H0.770515C0.084833 0 -0.258008 0.910697 0.227684 1.44455L3.92751 5.51129C4.52034 6.1629 5.48458 6.1629 6.07741 5.51129L7.48449 3.96467L9.77724 1.44455C10.2558 0.910697 9.91295 0 9.22726 0Z" fill="#007FFF" />
                                        </svg>
                                      </span>
                                    </div>

                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="accesslist">
                                    <Card.Body className="pt-0 px-4 mt-0">
                                      <div className="checkboxxxesssnewww">
                                        <label className="checkBox m-0 warrrrw texxccser">
                                          <input
                                            type="checkbox"
                                            id="legend"
                                            className="boxxxxx"
                                            value="legend"
                                            checked={filerByAccessList?.includes('legend')}
                                            onChange={() => handleCheckboxClick1('legend')}
                                          />
                                          <p className="inputtext">Legend Stakers</p>
                                        </label>
                                      </div>
                                      <div className="checkboxxxesssnewww">
                                        <label className="checkBox m-0 warrrrw texxccser">
                                          <input
                                            type="checkbox"
                                            id="gold"
                                            className="boxxxxx"
                                            value="gold"
                                            checked={filerByAccessList?.includes('gold')}
                                            onChange={() => handleCheckboxClick1('gold')}
                                          />
                                          <p className="inputtext">Gold Stakers</p>
                                        </label>
                                      </div>
                                      <div className="checkboxxxesssnewww">
                                        <label className="checkBox m-0 warrrrw texxccser">
                                          <input
                                            type="checkbox"
                                            id="silver"
                                            className="boxxxxx"
                                            value="silver"
                                            checked={filerByAccessList?.includes('silver')}
                                            onChange={() => handleCheckboxClick1('silver')}
                                          />
                                          <p className="inputtext">Silver Stakers</p>
                                        </label>
                                      </div>
                                      <div className="checkboxxxesssnewww">
                                        <label className="checkBox m-0 warrrrw texxccser">
                                          <input
                                            type="checkbox"
                                            id="freeUser"
                                            className="boxxxxx"
                                            value="freeUser"
                                            checked={filerByAccessList?.includes('freeUser')}
                                            onChange={() => handleCheckboxClick1('freeUser')}
                                          />
                                          <p className="inputtext">Free Users</p>
                                        </label>
                                      </div>
                                      <div className="checkboxxxesssnewww">
                                        <label className="checkBox m-0 warrrrw texxccser">
                                          <input
                                            type="checkbox"
                                            id="diamond"
                                            className="boxxxxx"
                                            value="diamond"
                                            checked={filerByAccessList?.includes('diamond')}
                                            onChange={() => handleCheckboxClick1('diamond')}
                                          />
                                          <p className="inputtext">Diamond Stakers</p>
                                        </label>
                                      </div>
                                      <div className="checkboxxxesssnewww">
                                        <label className="checkBox m-0 warrrrw texxccser">
                                          <input
                                            type="checkbox"
                                            id="platinum"
                                            className="boxxxxx"
                                            value="platinum"
                                            checked={filerByAccessList?.includes('platinum')}
                                            onChange={() => handleCheckboxClick1('platinum')}
                                          />
                                          <p className="inputtext">Platinum Stakers</p>
                                        </label>
                                      </div>
                                      <div className="checkboxxxesssnewww">
                                        <label className="checkBox m-0 warrrrw texxccser">
                                          <input
                                            type="checkbox"
                                            id="premium"
                                            className="boxxxxx"
                                            value="premium"
                                            checked={filerByAccessList?.includes('premium')}
                                            onChange={() => handleCheckboxClick1('premium')}
                                          />
                                          <p className="inputtext">Premium Users</p>
                                        </label>
                                      </div>

                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>

                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                      <div className="twinnnssdfbtns">
                        <button className="sdsdsdsfbvbnmnmhjm" onClick={() => handleCloseD()}>
                          Cancel
                        </button>
                        <button className="sdsdsdsfbvbsdsdsnmnmhjm" onClick={() => props?.filtersHandle()}>
                          Apply
                        </button>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                </div>
                <Link to={"/admin/addvideos"}> <button type="button" className="blue-add-category ml-2" data-toggle="modal" >

                  <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <g clip-path="url(#clip0_564_35514)">
                      <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_564_35514">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>


                  Add Videos</button></Link>
              </>
            }


            {brandName === 'Courses' && Acls?.course?.create &&
              <>
                {/* Add Course */}
                <div className="newinputs">
                  <Dropdown className="amer_dropdonfst" onSelect={sortByCoursesHandel}>
                    <Dropdown.Toggle id="dropdown-basic">
                      Sort by
                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                        <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="mydropppp">
                      <Dropdown.Item eventKey="views_high_to_low" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Views: High to Low
                          {
                            props?.courseSortBy == 'views_high_to_low' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="views_low_to_high" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Views: Low to High
                          {
                            props?.courseSortBy == 'views_low_to_high' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="videos_most_to_fewest" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Most Videos
                          {
                            props?.courseSortBy == 'videos_most_to_fewest' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                      {/* <Dropdown.Item eventKey="videos_fewest_to_most" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Longest
                          {
                            props?.courseSortBy == 'videos_fewest_to_most' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item> */}
                      <Dropdown.Item eventKey="videos_fewest_to_most" className="namedivsdsds namediv ">
                        <p className="d-flex justify-content-between">
                          Fewest videos
                          {
                            props?.courseSortBy == 'videos_fewest_to_most' &&
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M7.03121 13.08C6.74994 13.08 6.48274 12.9675 6.28585 12.7706L2.30588 8.79064C1.89804 8.3828 1.89804 7.70775 2.30588 7.29991C2.71372 6.89207 3.38877 6.89207 3.79661 7.29991L7.03121 10.5345L14.2598 3.30588C14.6677 2.89804 15.3427 2.89804 15.7506 3.30588C16.1584 3.71372 16.1584 4.38877 15.7506 4.79661L7.77658 12.7706C7.57969 12.9675 7.31248 13.08 7.03121 13.08Z" fill="#3654D6" />
                              </svg>
                            </span>
                          }
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>


                  <Dropdown className="amer_dropdonfst">
                    <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                        <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" strokeWidth="2" strokeLinecap="round" />
                        <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" strokeWidth="2" strokeLinecap="round" />
                        <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" strokeWidth="2" strokeLinecap="round" />
                        <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" strokeWidth="2" strokeLinecap="round" />
                      </svg>
                      Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="mydropppp  dropdownmennww">
                      <Accordion className="mainaccordghhyyts">
                        <div className="maininerdiv">
                          <p className="filterheading">
                            Filters
                          </p>

                          <p className="clearall" style={{ cursor: 'pointer' }} onClick={() => clearlAllHandleD()}>
                            Clear All
                          </p>
                        </div>


                        {/* <Card className="acrdgfstcover mb-0 p-0">
                          <Accordion.Toggle className="accodnhedg " variant="link" eventKey="1">
                            <div className="maininhgdiv">
                              <p className="filtepp">
                                Views
                              </p>
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                  <path d="M9.22726 0H4.77747H0.770515C0.084833 0 -0.258008 0.910697 0.227684 1.44455L3.92751 5.51129C4.52034 6.1629 5.48458 6.1629 6.07741 5.51129L7.48449 3.96467L9.77724 1.44455C10.2558 0.910697 9.91295 0 9.22726 0Z" fill="#007FFF" />
                                </svg>
                              </span>
                            </div>
                          </Accordion.Toggle>

                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <div className="checkboxxxesssnewww">
                                <label class="checkBox m-0 warrrrw texxccser">
                                  <input type="checkbox" id="ch1" className="boxxxxx" />
                                  <p className="inputtext">All</p>
                                </label>
                              </div>
                              <div className="checkboxxxesssnewww">
                                <label class="checkBox m-0 warrrrw texxccser">
                                  <input type="checkbox" id="ch12" className="boxxxxx" />
                                  <p className="inputtext">Most Viewed</p>
                                </label>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card> */}

                        <Card className="acrdgfstcover mb-0 p-0">
                          <Accordion.Toggle className="accodnhedg " variant="link" eventKey="2">
                            <div className="maininhgdiv">
                              <p className="filtepp">
                                Number of videos
                              </p>
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                  <path d="M9.22726 0H4.77747H0.770515C0.084833 0 -0.258008 0.910697 0.227684 1.44455L3.92751 5.51129C4.52034 6.1629 5.48458 6.1629 6.07741 5.51129L7.48449 3.96467L9.77724 1.44455C10.2558 0.910697 9.91295 0 9.22726 0Z" fill="#007FFF" />
                                </svg>
                              </span>
                            </div>
                          </Accordion.Toggle>

                          <Accordion.Collapse eventKey="2">
                            <Card.Body>
                              <div className="inputoutermains">
                                <input type="number" name="full_name" className="ambassadorinputss" placeholder="Numbers" value={props?.courseFilters} onChange={(e) => props?.setCourseFilters(e?.target?.value)} />
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                      <div className="twinnnssdfbtns">
                        <button className="sdsdsdsfbvbnmnmhjm" onClick={() => handleCloseD()}>
                          Cancel
                        </button>
                        <button className="sdsdsdsfbvbsdsdsnmnmhjm" onClick={() => props?.getCourse()}>
                          Apply
                        </button>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Link to="/admin/addcours">
                    <button type="button" className="blue-add-category ml-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <g clip-path="url(#clip0_564_35514)">
                          <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_564_35514">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                          </clipPath>
                        </defs>
                      </svg> Add Course</button>  </Link>
                </div>


              </>
            }


            {(brandName === 'Badges' && Acls?.badges?.create) && <Link to={`/admin/sendbadges`}> <button type="button" className="blue-add-category ml-2">


              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Badges</button></Link>}



            {(brandName === 'Badges' && Acls?.badges?.create) && <Link to={`/admin/sendbadges`}> </Link>}






            {brandName === 'Social Links' && <Link to={`/admin/addsociallinks`}> <button type="button" className="blue-add-category ml-2">


              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Links</button></Link>}
            {brandName === 'Bolt Management' && <>
              <Link to={`/admin/addboltcategory`}>
                <button type="button" className="blue-add-category ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <g clip-path="url(#clip0_564_35514)">
                      <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_564_35514">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>

                  Add Categories
                </button>
              </Link>
            </>
            }
            {brandName === 'Subscription' && <Link to={`/admin/addsubscription/4577432`}> <button type="button" className="blue-add-category ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Subcription</button></Link>}
            {(brandName === 'Raffles' && Acls?.raffles?.create) && <Link to={`/admin/addraffles`}> <button type="button" className="blue-add-category ml-2">


              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Raffles</button></Link>}
            {(brandName === 'Add Raffles' && Acls?.raffles?.create) && <></>}

            {/* {console.log(props, "edittttt")} */}

            {brandName === 'Edit Raffles' && role === 'super-admin' &&
              <>
                {/* lkmjnhbgvf */}
              </>
            }

            {(brandName === 'Raffles categories' && Acls?.rafflesCategories?.create) && <Link to={`/admin/addcategoryraffle`}>
              <button type="button" className="blue-add-category ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <g clip-path="url(#clip0_564_35514)">
                    <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_564_35514">
                      <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                Add Categories</button></Link>
            }
            {
              console.log(brandName, "brandName okokokok")
            }
            {(brandName === 'Add Categories' && Acls?.rafflesCategories?.create) &&
              <></>
            }

            {(brandName === 'Edit Categories' && Acls?.rafflesCategories?.create) &&
              <></>
            }

            {brandName === 'Categories' && <Link to={`/admin/addproductraffle`}>
              {/* <button type="button" className="blue-add-category ml-2">           <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>  Add Products</button> */}
            </Link>}

            {(brandName === 'Products' && Acls?.products?.create) && <Link to={`/admin/addproducts/:id`}> <button type="button" className="blue-add-category ml-2">


              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Products</button></Link>}

            {(brandName === 'Categories' && Acls?.productCategories?.create) &&
              <Link to={`/admin/addnewcategory`}>
                <button type="button" className="blue-add-category ml-2">           <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <g clip-path="url(#clip0_564_35514)">
                    <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_564_35514">
                      <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>  Add Categories</button>
              </Link>
            }

            {brandName === 'Add Category' &&
              <>
                {/* <Link to={`/admin/addproducts`}>
                 <button type="button" className="blue-add-category ml-2">           <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>  Add Products</button>
               </Link> */}
              </>
            }

            {brandName === 'Edit Category' &&
              <>
                {/* <Link to={`/admin/addproducts`}>
                 <button type="button" className="blue-add-category ml-2">           <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>  Add Products</button>
               </Link> */}
              </>
            }

            {brandName === 'Admin' && role === 'super-admin' && <Link to={`/admin/AddAdminUser`}> <button type="button" className="blue-add-category ml-2" data-toggle="modal" >  Add User</button></Link>}

            {brandName === 'Add Role' && role === 'super-admin' ?
              <>
                {/* <Link to={`/admin/addrolemanagement`} className="add_role_btn"> 
      <button type="button" className="blue-add-category ml-2">
        Add Role
      </button>
    </Link> */}
              </>
              : null
            }
            {brandName === 'Role Management' && role === 'super-admin' && <Link to={`/admin/addrolemanagement`} className="add_role_btn"> <button type="button" className="blue-add-category ml-2" data-toggle="modal" >
              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Role </button></Link>}

            {/* {console.log(brandName == 'Add role management' && role == 'super-admin', role, brandName, "asdfasdfdsa")} */}


            {brandName === 'Ambassadors Applications' && role === 'super-admin' &&
              <div className="newinputs">

                <div className="inputoutermain">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                    <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                    <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                  </svg>
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" />

                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">Name</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">Join Date</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Verification Status</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>

            }
            {brandName === 'Support' && role === 'super-admin' &&
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                    <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                    <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                  </svg>
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" />

                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">Name</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">Join Date</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Verification Status</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            }
            {brandName == "Ticket Detail" && role === 'super-admin' && <>Ticket Detail</>}

            {brandName === "Support/Ticket Details" && role === 'super-admin' &&
              <>
                {/* <div className="newinputs">
                <div className="inputoutermain">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                    <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                    <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                  </svg>
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" />

                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by  
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
  <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69"/>
</svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">Name</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">Join Date</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Verification Status</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              <Dropdown className="amer_dropdonfst">
                    <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                        <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                        <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                        <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                        <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      </svg>
                      Filters
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mydropppp">
                      <Dropdown.Item className="namediv" href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item className="namediv" href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item className="namediv" href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

              </div> */}
              </>

            }
            {/* {console.log(brandName, props.name, "brandName")} */}
            {brandName == 'Ambassadors Claim Rewards' && role === 'super-admin' &&
              <div className="newinputs">

                <div className="inputoutermain">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                    <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                    <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                  </svg>
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" />

                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">Name</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">Join Date</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Verification Status</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            }
            {brandName === 'Ambassadors Claim' && role === 'super-admin' &&
              <div className="newinputs">

                <div className="inputoutermain">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                    <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                    <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                  </svg>
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" />

                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">Name</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">Join Date</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Verification Status</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            }
            {brandName === 'Leaderboards' && role === 'super-admin' &&
              <div className="newinputs">

                <div className="inputoutermain">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                    <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                    <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                  </svg>
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" />

                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">Name</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">Join Date</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Verification Status</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>

            }

            {brandName === 'Launchpad' && <Link to={`/admin/raffles`}> <button type="button" className="blue-add-category ml-2">


              <svg xmlns="http://www.w3.org/2000/svg" className=" mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_564_35514)">
                  <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_564_35514">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              Add Project
            </button></Link>}

            {brandName === 'Users' && role === 'super-admin' &&
              <div className="newinputs">
                <div className="inputoutermain">
                  {/* {console.log(props?.searchTerm, "props?.searchTerm")} */}
                  {
                    props?.searchTerm == null && props?.searchTerm == undefined && props?.searchTerm !== '' ?
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                        <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                        <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                      </svg>
                      :
                      <svg style={{ cursor: "pointer" }} onClick={props?.searchsubmit} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                        <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                        <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                      </svg>
                  }
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" value={props?.searchTerm} onChange={(e) => props?.setSearchTerm(e?.target?.value)} />
                </div>
                <Dropdown
                  className="amer_dropdonfst"
                  autoClose="outside"
                  onSelect={handleSelect}
                // onSelect={(eventKey) => {
                //   if (eventKey) {
                //     props?.setSortItem(eventKey);
                //     props?.setSortOrder("asc");
                //     props?.handleApplyClick();  // Ensure the apply function is called here
                //   }
                //   // else if (eventKey === 'asc' || eventKey === 'desc') {
                //   //   props?.setSortOrder(eventKey);
                //   // }
                //   console.log(`Selected: ${eventKey}`);
                // }}
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>

                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item eventKey="name" className="namediv">Name</Dropdown.Item>
                    <Dropdown.Item eventKey="createdAt" className="namediv">Join Date</Dropdown.Item>
                    <Dropdown.Item eventKey="email_verified" className="namediv">Verification Status</Dropdown.Item>
                    {/* <Dropdown.Item eventKey="eventKey" className="namediv" onClick={()=> props?.handleApplyClick()}>Referrals</Dropdown.Item> */}

                    {/* <Dropdown.Item eventKey="asc" className="namediv">Ascending</Dropdown.Item>
                    <Dropdown.Item eventKey="desc" className="namediv">Descending</Dropdown.Item>
                    <Dropdown.Item as="button" className="namediv" onClick={props?.handleApplyClick}>Apply</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="amer_dropdonfst"
                  onSelect={(eventKey) => {
                    props?.setFilterState(eventKey);
                    // console.log(`Selected: ${eventKey}`);
                  }}
                >
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    Filters
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item eventKey="day" className="namediv">24 Hours</Dropdown.Item>
                    <Dropdown.Item eventKey="week" className="namediv">7 Days</Dropdown.Item>
                    <Dropdown.Item eventKey="month" className="namediv">30 Days</Dropdown.Item>
                    <Dropdown.Item eventKey="90days" className="namediv">90 Days</Dropdown.Item>
                    <Dropdown.Item eventKey="year" className="namediv">Year</Dropdown.Item>
                    {/* 
                    <Dropdown.Item eventKey="asc">Ascending</Dropdown.Item>
                    <Dropdown.Item eventKey="desc">Descending</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={props?.handleApplyClick}>Apply</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }

            {brandName === 'Early Users' && role === 'super-admin' &&
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg onClick={props?.searchsubmit} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                    <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                    <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                  </svg>
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" value={props?.searchTerm} onChange={(e) => props?.setSearchTerm(e?.target?.value)} />
                </div>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>

                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">Name</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">Join Date</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Verification Status</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }
            {brandName === 'User / Detail' && role === 'super-admin' &&
              <div className="">

                <div className="custom-tab-bar  border-0">
                  <div className="main-outer-p">
                    <div className="main-p">
                      <p>Verify user</p>
                    </div>
                    {/* {console.log(props?.AccountPrivacy, props?.AccountPrivacy?.boltsVerified, 'verify')} */}
                    <div className="main-switch-nn">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="customSwitches" checked={props?.AccountPrivacy?.boltsVerified} onChange={() => props?.verifyUser()} />
                        <label class="custom-control-label" for="customSwitches"></label>
                      </div>
                    </div>
                  </div>

                  {/* <a className='clanderdate_nothover  pl-3' onClick={(handleButtonClick) => setShowCalendar4(!showcalendar4)}>
                    <img src="\users-assets\clander.svg" alt="" />
                  </a> */}
                  {/* {showcalendar && ( */}
                  {showcalendar4 && (
                    <div className="cal set-custom-calendar-div">
                      <Calendar
                        numberOfMonths={2}
                        disableMonthPicker
                        disableYearPicker
                      />
                    </div>
                  )}


                </div>
              </div>
            }

            {brandName === 'Gifts' &&
              <div className="newinputs">

                <div className="inputoutermain">
                  <svg onClick={props?.searchsubmit} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                    <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                    <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                  </svg>
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" value={props?.searchTerm} onChange={(e) => props?.setSearchTerm(e?.target?.value)} />

                </div>

                <Dropdown className="amer_dropdonfst"
                  onSelect={(eventKey) => {
                    if (eventKey) {
                      props?.setGiftSortItem(eventKey);
                    }
                    // console.log(`Selected keyyyyy: ${eventKey}`);
                  }}
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    {props?.giftSortItem ? props?.giftSortItem === "90days" ? "90 days" : capitalize(props?.giftSortItem) : "Sort by"}
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" eventKey="day">24 hour</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="week">7 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="month">30 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="90days">90 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="year">Year</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="All">All</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <SendGiftModal />
              </div>


            }

            {/* orders navbar */}
            {brandName === 'Orders' &&
              <div className="newinputs">
                <div className="inputoutermain">
                  {/* {console.log(props?.searchTerm, "props?.searchTerm")} */}
                  {
                    props?.searchTerm == null && props?.searchTerm == undefined && props?.searchTerm !== '' ?
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                        <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                        <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                      </svg>
                      :
                      <svg style={{ cursor: "pointer" }} onClick={props?.searchsubmit} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                        <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                        <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                      </svg>
                  }
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" value={props?.searchTerm} onChange={(e) => props?.setSearchTerm(e?.target?.value)} />
                </div>
                <Dropdown
                  className="amer_dropdonfst"
                  autoClose="outside"
                  onSelect={(eventKey) => {
                    if (eventKey === 'email_verified' || eventKey === 'createdAt') {
                      props?.setSortItem(eventKey);
                    } else if (eventKey === 'asc' || eventKey === 'desc') {
                      props?.setSortOrder(eventKey);
                    }
                    // console.log(`Selected: ${eventKey}`);
                  }}
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item eventKey="name" className="namediv">Name</Dropdown.Item>
                    <Dropdown.Item eventKey="createdAt" className="namediv">Join Date</Dropdown.Item>
                    <Dropdown.Item eventKey="email_verified" className="namediv">Verification Status</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                    {/* 
                  <Dropdown.Item eventKey="asc">Ascending</Dropdown.Item>
                  <Dropdown.Item eventKey="desc">Descending</Dropdown.Item>
                  <Dropdown.Item as="button" onClick={props?.handleApplyClick}>Apply</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="amer_dropdonfst"
                  onSelect={(eventKey) => {
                    props?.setFilterState(eventKey);
                    // console.log(`Selected: ${eventKey}`);
                  }}
                >
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" eventKey="day">24 hour</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="week">7 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="month">30 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="90days">90 days</Dropdown.Item>
                    <Dropdown.Item className="namediv" eventKey="year">year</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }
            {brandName === 'staking Leaderboard' &&
              <div className="newinputs">

                <div className="inputoutermain">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                    <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                    <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                  </svg>
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" />

                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">Name</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">Join Date</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Verification Status</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item className="namediv" href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item className="namediv" href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            }
            {brandName === 'Mystery Box Reward' &&
              <div className="newinputs">
                <div className="inputoutermain">
                  {/* {console.log(props?.searchTerm, "props?.searchTerm Mystery Box Reward")} */}
                  {
                    props?.searchTerm == null && props?.searchTerm == undefined && props?.searchTerm !== '' ?
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                        <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                        <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                      </svg>
                      :
                      <svg style={{ cursor: "pointer" }} onClick={props?.searchsubmit} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="ambrinputicon">
                        <path d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z" fill="#A3A3A3" />
                        <path d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z" fill="#A3A3A3" />
                      </svg>
                  }
                  <input type="text" name="full_name" className="ambassadorinput" placeholder="Search" value={props?.searchTerm} onChange={(e) => props?.setSearchTerm(e?.target?.value)} />
                </div>
                <Dropdown
                  className="amer_dropdonfst"
                  autoClose="outside"
                  onSelect={(eventKey) => {
                    if (eventKey === 'type') {
                      props?.setSortItem(eventKey);
                    } else if (eventKey === 'asc' || eventKey === 'desc') {
                      props?.setSortOrder(eventKey);
                    }
                    // console.log(`Selected: ${eventKey}`);
                  }}
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort by
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                      <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item eventKey="type" className="namediv">User</Dropdown.Item>
                    <Dropdown.Item eventKey="asc" className="namediv">Ascending</Dropdown.Item>
                    <Dropdown.Item eventKey="desc" className="namediv">Descending</Dropdown.Item>
                    {/* 
                <Dropdown.Item eventKey="asc">Ascending</Dropdown.Item>
                <Dropdown.Item eventKey="desc">Descending</Dropdown.Item>
                <Dropdown.Item as="button" onClick={props?.handleApplyClick}>Apply</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="amer_dropdonfst"
                  onSelect={(eventKey) => {
                    props?.setFilterState(eventKey);
                    // console.log(`Selected timeee: ${eventKey}`);
                  }}
                >
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <line x1="1" y1="1" x2="17" y2="1" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="3" y1="5" x2="15" y2="5" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="5" y1="9" x2="13" y2="9" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                      <line x1="8" y1="13" x2="10" y2="13" stroke="#4F4E69" stroke-width="2" stroke-linecap="round" />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item eventKey="day">24 hour</Dropdown.Item>
                    <Dropdown.Item eventKey="week">7 days</Dropdown.Item>
                    <Dropdown.Item eventKey="month">30 days</Dropdown.Item>
                    <Dropdown.Item eventKey="90days">90 days</Dropdown.Item>
                    <Dropdown.Item eventKey="year">year</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }
          </Collapse>
        </Container>
      </Navbar>
      {/* Add Category Modal */}
      <div className="main-modal-one">
        <div class="modal fade modal-zzz" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog daily-profile-modal">
            <div class="modal-content daily-profile-modal-inner">
              <div class="modal-body modal-body-main">
                <div className="main-outter">
                  <div className="row main-cardssss">
                    <div className="col-md-12 col-12">
                      <div className="flux-b">
                        <h3>Add Course</h3>
                      </div>
                    </div>
                    <div className="col-12">
                      <div class="form-group">
                        <label for="example">Add Thumbnail</label>
                        <div className="main-image-div">
                          <div className="choose-filessssss">
                            <form>
                              <div className="sbhjsbd">
                                {selectedImg ? renderPhotos(selectedImg) : null}
                                <div className="layerss"></div>
                              </div>

                              <div className="nsjd">
                                <label className="" for="filess"><i class="fas fa-cloud-upload-alt"></i></label>
                                <input type="file" name="image" id="filess" onChange={handleFileSelect} className='form-control d-none' />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div class="form-group">
                        <label for="example">Course Name</label>
                        <input type="text" name="category" value={category} onChange={handleChange} className={'form-control' + (submitted && !category ? ' is-invalid' : '')} placeholder="Enter Category Name" />

                      </div>
                    </div>
                    <div className="col-md-12 col-12">
                      <div className="button-modal-daily">
                        <button type="button" className="button-main-daily" onClick={addCategory} >Add</button>
                        <button type="button" className="button-main-dailys" data-dismiss="modal" aria-label="Close" onChange={close} >Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* send gift modal here......................... */}
      {/* <Modal className='sendgift-modal' show={showw} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Send Gift asdfafafaref</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-field">
            <h6>Select Item</h6>
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Select Item
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#">Select Item</a>
                <a class="dropdown-item" href="#">Select Item</a>
                <a class="dropdown-item" href="#">Select Item</a>
                <a class="dropdown-item" href="#">Select Item</a>
                <a class="dropdown-item" href="#">Select Item</a>
                <a class="dropdown-item" href="#">Select Item</a>
                <a class="dropdown-item" href="#">Select Item</a>
                <a class="dropdown-item" href="#">Select Item</a>
                <a class="dropdown-item" href="#">Select Item</a>
              </div>
            </div>
          </div>
          <div className="option-field">
            <h6>Search with email address</h6>
            <input type="text" placeholder='Enter email address' />
          </div>
          <div className="twice-btn">
            <button onClick={handleClose} className='btn-cancel'>Cancel</button>
            <button className='btn-send'>Send</button>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>

  );
}

export default Header;
