import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Environment from 'utils/Environment';
import user4 from "assets/img/userflow/add1.png";
import { Backdrop, CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router';

const SendGiftModal = () => {

    const history = useHistory();
    const token = localStorage.getItem('mytoken');

    const [open, setOpen] = useState(true);
    const [walletAddress, setWalletAddress] = useState('')
    const [items, setItems] = useState({})
    console.log("🚀 ~ items:", items, items?.ProductType?.productName == "Metaverse Assets", items?.ProductType)
    const [isValid, setIsValid] = useState(false);
    const [email, setEmail] = useState('')
    const [emailArr, setEmailArr] = useState([])
    const [emailAvail, setEmailAvail] = useState(false)
    const [note, setNote] = useState()

    const [showw, setShoww] = useState(false);
    const handleClose = () => setShoww(false);

    const [itemData, setItemData] = useState([])


    const sendGifts = async () => {
        setOpen(true);

        if (items?.ProductType?.productName && email?.email && note) {
            let data;
            console.log('Items object:', items); // Add this line
            if (items?.ProductCategory?.categoryName.trim() === "Metaverse Assets") { // Trim the categoryName
                data = {
                    email: email?.email,
                    user_id: email?.id,
                    gift_name: items?.ProductType?.productName,
                    product_id: items?.id,
                    status: 'complete',
                    walletAddress,
                    note: note
                }
            } else {
                data = {
                    email: email?.email,
                    user_id: email?.id,
                    gift_name: items?.ProductType?.productName,
                    // product_id: items?.ProductType?.id,
                    product_id: items?.id,
                    status: 'inProgress',
                    note: note
                }
            }
            console.log('Payload data:', data); // Add this line
            setOpen(true);
            axios.post(Environment.backendUrl + `/giftHistory/sendGift`, data, { headers: { "Authorization": `Bearer ${token}` } })
                .then((response) => {
                    if (response) {
                        setOpen(false)
                        setEmail(null)
                        setItems(null)
                        setWalletAddress(null)
                        handleClose();
                        toast.success(response.data.msg, {
                            position: "top-center",
                            autoClose: 2000,
                        });
                        window.location.reload();
                    }
                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            setOpen(false);
            toast.error('Fill all fields first', {
                position: "top-center",
                autoClose: 2000,
            });
        }
    }


    const getItems = async () => {
        setOpen(true);
        axios.get(Environment.backendUrl + `/giftHistory/productsDropdownListing`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                console.log("🚀 ~ .then ~ response:", response)
                setItemData(response?.data?.productData)
                setOpen(false)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }

    const verifyEmail = async (value) => {
        setEmailAvail(false)
        if (value) {
            setOpen(true)
            axios.post(Environment.backendUrl + `/giftHistory/searchUserWithEmail`, { email: value }, { headers: { "Authorization": `Bearer ${token}` } })
                .then((response) => {
                    setOpen(false)
                    setEmail(null)
                    setEmailArr(response?.data?.UserData)

                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        }
    }


    function isValidWalletAddress(address) {
        const regex = /^(0x)?[0-9a-fA-F]{40}$/;
        return regex.test(address);
    }

    const validateWallet = (event) => {
        const inputAddress = event.target.value;
        setWalletAddress(inputAddress);
        setIsValid(isValidWalletAddress(inputAddress));
    }

    useEffect(() => {
        getItems();
    }, [])


    return (
        <>
            {/* {
                open ?
                    <Backdrop className="loader" style={{ zIndex: '999999999999999' }} sx={{ color: '#fff' }} open={open} ><CircularProgress color="inherit" /></Backdrop>
                    : null} */}

            <button onClick={() => setShoww(true)} type="button" className="blue-add-category"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_277_136672)">
                    <path d="M14.5714 7.07143H9.71429C9.5565 7.07143 9.42857 6.9435 9.42857 6.78571V1.92857C9.42857 1.13965 8.78892 0.5 8 0.5C7.21108 0.5 6.57143 1.13965 6.57143 1.92857V6.78571C6.57143 6.9435 6.4435 7.07143 6.28571 7.07143H1.42857C0.639648 7.07143 0 7.71108 0 8.5C0 9.28892 0.639648 9.92857 1.42857 9.92857H6.28571C6.4435 9.92857 6.57143 10.0565 6.57143 10.2143V15.0714C6.57143 15.8604 7.21108 16.5 8 16.5C8.78892 16.5 9.42857 15.8604 9.42857 15.0714V10.2143C9.42857 10.0565 9.5565 9.92857 9.71429 9.92857H14.5714C15.3604 9.92857 16 9.28892 16 8.5C16 7.71108 15.3604 7.07143 14.5714 7.07143Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_277_136672">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                </defs>
            </svg>Send Gifts</button>
            <div className="main-div-nav-head">
                <Modal className='sendgift-modal' show={showw} onHide={handleClose} centered>
                    <Backdrop className="loader" style={{ zIndex: '999999999999999', height: '110vh', overflow: "hidden" }} sx={{ color: '#fff' }} open={open} ><CircularProgress color="inherit" /></Backdrop>
                    <Modal.Header>
                        <Modal.Title>Send Gift</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="parent-field">
                            <h6>Select Item</h6>
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {items?.ProductType?.productName ? <p className='text-dark'>{items?.ProductType?.productName} </p> : 'Select Item'}
                                    <span className=''>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                            <path d="M2.4345 5.31539C2.4055 5.28714 2.2815 5.18047 2.1795 5.0811C1.538 4.49854 0.488 2.97881 0.1675 2.18339C0.116 2.06259 0.007 1.75718 0 1.59401C0 1.43765 0.036 1.2886 0.109 1.14637C0.211 0.96907 0.3715 0.826839 0.561 0.748904C0.6925 0.698734 1.086 0.620799 1.093 0.620799C1.5235 0.542864 2.223 0.5 2.996 0.5C3.7325 0.5 4.4035 0.542864 4.8405 0.606673C4.8475 0.61398 5.3365 0.691914 5.504 0.777155C5.81 0.933512 6 1.23892 6 1.56576V1.59401C5.9925 1.80687 5.8025 2.25451 5.7955 2.25451C5.4745 3.00706 4.476 4.49172 3.8125 5.08841C3.8125 5.08841 3.642 5.25645 3.5355 5.32952C3.3825 5.4435 3.193 5.5 3.0035 5.5C2.792 5.5 2.595 5.43619 2.4345 5.31539Z" fill="#030229" />
                                        </svg>
                                    </span>
                                </button>
                                <div class="dropdown-menu  mydropppp " aria-labelledby="dropdownMenuButton">
                                    {itemData?.map((item) => {
                                        console.log("🚀 ~ {itemData?.map ~ item:", item)
                                        return (
                                            <a onClick={() => setItems(item)} class="dropdown-item namediv p-3" href="#">{item?.ProductType?.productName}</a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {items?.ProductType?.productName == "Metaverse Asset" &&
                            <div className="option-field parent-field">
                                <h6>Wallet Address</h6>
                                <input value={walletAddress} onChange={validateWallet} type="text" placeholder='Enter wallet address' />
                                {(isValid === false && walletAddress) && <p className='text-danger'>Wallet Address Invalid</p>}
                            </div>
                        }
                        <div className="option-field parent-field">
                            <h6>Search with email address</h6>

                            <div class="dropdown w-100">
                                <input id="dropdownMenuButtonINput" value={email?.email && email?.email} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onChange={(e) => { verifyEmail(e.target.value); setEmailAvail(false) }} type="text" placeholder='Enter email address' />

                                <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButtonINput">
                                    {emailArr?.map((item) => {
                                        return (
                                            <a onClick={() => { setEmail(item); setEmailAvail(true) }} class="dropdown-item" href="#">{item?.email}</a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='areatext'>
                            <p>Admin Note</p>
                            <textarea onChange={(e) => setNote(e.target.value)} type='text' placeholder='Admin Note' />
                        </div>

                        <div className="twice-btn">
                            <button onClick={handleClose} className='red-b'>Cancel</button>
                            {(items?.ProductCategory?.categoryName === "Metaverse Assets ") ?
                                <button onClick={sendGifts} className='red-b'>Send</button>
                                // ((items?.ProductType?.productName && emailAvail && walletAddress && isValid) ?
                                //     :
                                //     <button className=''>Send</button>
                                // )
                                :
                                <button onClick={sendGifts} className='red-b'>Send</button>
                                // ((items?.ProductType?.productName && emailAvail) ?
                                //     :
                                //     <button className=''>Send</button>
                                // )
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default SendGiftModal