import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import "./raffles.scss"
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Environment from 'utils/Environment';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { Dropdown } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';


const Addraffles = (props) => {

    const [userId, setUserData] = useState(null);
    console.log("🚀 ~ userId: in leaderboard", userId)

    const getUserIdHandel = (id) => {
        setUserData(id);
    }

    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const handleSelect = (ranges) => {
        console.log(ranges);
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
        setSelectionRange(ranges.selection);
    };

    const history = useHistory();
    const [error, setError] = useState(false);
    const [subs, setSubs] = useState([]);
    const [raffelCat, setRaffelCat] = useState({
        image: '',
        name: '',
        description: '',
        totalTickets: '',
        pricePerTicket: '',
        maxCap: '',
        category_id: 'Category',
        category_name: 'Category',
        startDate: '',
        endDate: '',
        winner: {},
        winnerTicketNo: '',
        rafLeadTickets: '',
        ticketBooked: []
    })
    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('mytoken')
    const [dImage, setDImage] = useState()
    // console.log('raffel cate', raffelCat, dImage)

    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);


    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }


    const id = props.match.params.id;
    // console.log("iddd", id)
    // const addRaffel = async (e) => {
    //     const { name, value } = e.target;
    //     // console.log('raffel cate', name)
    //     if (name === 'image') {
    //         setDImage(URL.createObjectURL(e.target.files[0]))
    //         setRaffelCat((pre) => {
    //             return {
    //                 ...pre, [name]: e.target.files[0]
    //             }
    //         })
    //     } else {
    //         setRaffelCat((pre) => {
    //             return {
    //                 ...pre, [name]: value
    //             }
    //         })
    //     }

    // }


    // const addRaffel = (e) => {
    //     const { name, value } = e.target;
    //     setRaffelCat((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    // };

    const addRaffel = async (e) => {
        const { name, value, files } = e.target;

        if (name === 'image') {
            const file = files[0];
            if (file) {
                // Revoke the previous object URL to prevent memory leaks
                URL.revokeObjectURL(dImage);
                const newImageURL = URL.createObjectURL(file);
                setDImage(newImageURL);
                setRaffelCat((prev) => ({
                    ...prev,
                    [name]: file
                }));
            }
        } else {
            setRaffelCat((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };


    // Get the current date and time in the YYYY-MM-DDTHH:MM format
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    // Format it for the datetime-local input
    const currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

    // Helper function to add 30 minutes to a date
    // Helper function to add 30 minutes to a date
    const addThirtyMinutes = (dateString) => {
        // Check if dateString is valid
        if (!dateString) return dateString;

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            throw new RangeError("Invalid time value");
        }
        date.setMinutes(date.getMinutes() + 30);
        return date.toISOString().slice(0, 16); // Slice to remove seconds and milliseconds
    };

    // Get the current values and add 30 minutes
    const updatedStartDate = addThirtyMinutes(raffelCat?.startDate);
    const updatedEndDate = addThirtyMinutes(raffelCat?.endDate);


    const formatDateForInput = (dateString) => {
        if (!dateString) return "";

        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const publish = async () => {
        if (raffelCat.image && raffelCat.name && raffelCat.description && raffelCat.totalTickets && raffelCat.pricePerTicket && raffelCat.category_id !== 'Category' && raffelCat?.startDate && raffelCat.endDate) {
            setOpen(true)
            const data = new FormData();
            data.append("image", raffelCat?.image);
            data.append("name", raffelCat?.name);
            data.append("description", raffelCat?.description);
            data.append("totalTickets", raffelCat?.totalTickets);
            data.append("pricePerTicket", raffelCat?.pricePerTicket);
            data.append("maxCap", raffelCat?.maxCap);
            data.append("rafflesCategory_id", raffelCat?.category_id);
            data.append("startDate", raffelCat?.startDate);
            data.append("endDate", raffelCat?.endDate);

            axios.post(Environment.backendUrl + "/raffles/add", data,
                { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    setOpen(false)
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                    history?.push('/admin/raffles');
                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            toast.error('Fill all Feilds', {
                position: 'top-center'
            })
            setError(true)
            setOpen(false)
        }
    }
    const editRaffles = async () => {
        if (raffelCat.name && raffelCat.description && raffelCat.totalTickets && raffelCat.pricePerTicket && raffelCat.category_id !== 'Category' && raffelCat?.startDate && raffelCat.endDate) {
            setOpen(true)
            const data = new FormData();
            if (raffelCat?.image) {
                data.append("image", raffelCat?.image)
            }

            data.append("name", raffelCat?.name)
            data.append("description", raffelCat?.description)
            data.append("totalTickets", raffelCat?.totalTickets)
            data.append("pricePerTicket", raffelCat?.pricePerTicket)
            data.append("maxCap", raffelCat?.maxCap)
            data.append("rafflesCategory_id", raffelCat?.category_id)
            data.append("startDate", raffelCat?.startDate)
            data.append("endDate", raffelCat?.endDate)
            data.append('id', id)
            axios.post(Environment.backendUrl + "/raffles/edit", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    setOpen(false)
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });

                    history?.push('/admin/raffles');

                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            toast.error('Fill all Feilds', {
                position: 'top-center'
            })
            setError(true)
            setOpen(false)
        }
    }
    //to get the categories of Raffles
    const getSubscriptions = async () => {
        setOpen(true)

        axios.get(Environment.backendUrl + "/rafflesCategory/all", { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' } })
            .then((response) => {
                setOpen(false)
                // console.log('asldfjld', response?.data?.rafflesCategory)
                setSubs(response?.data?.rafflesCategory)
                // window.location.assign('/admin/newsbanner')
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }
    const getSingleSubscription = async () => {
        axios.get(Environment.backendUrl + `/raffles/find/${id}`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                setOpen(false)
                console.log('single sub', response?.data?.rafflesData?.RafflesCategory?.category)
                setDImage(response?.data?.rafflesData?.image)
                let cat = subs?.filter((item) => {
                    return (
                        item?.id === response?.data?.rafflesData?.rafflesCategory_id
                    )
                })
                // console.log('dsafsadfasfasdf', cat)
                setRaffelCat({
                    // image: response?.data?.rafflesCategory?.image,
                    name: response?.data?.rafflesData?.name,
                    description: response?.data?.rafflesData?.description,
                    totalTickets: response?.data?.rafflesData?.totalTickets,
                    pricePerTicket: response?.data?.rafflesData?.pricePerTicket,
                    maxCap: response?.data?.rafflesData?.maxCap,
                    category_id: response?.data?.rafflesData?.rafflesCategory_id,
                    category_name: response?.data?.rafflesData?.RafflesCategory?.category,
                    startDate: response?.data?.rafflesData?.startDate,
                    endDate: response?.data?.rafflesData?.endDate,
                    winner: response?.data?.rafflesData?.WinnerData,
                    winnerTicketNo: response?.data?.rafflesData?.winnerTicketNo,
                    rafLeadTickets: response?.data?.rafflesData?.RafflesTickets,
                    ticketBooked: response?.data?.ticketNumbers,
                })
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
                // window.location.assign('/admin/newsbanner')
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }
    const sweepRaffles = async () => {
        setOpen(true)
        toast.info('Sweeping in progress,it will take a bit long', {
            position: "top-center",
            autoClose: 2000,
        });
        axios.post(Environment.backendUrl + `/raffles/sweepFunction`, { raffles_id: id }, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setOpen(false)
                getSingleSubscription()
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 5000,
                });
                // window.location.assign('/admin/newsbanner')
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }

    useEffect(() => {
        getSubscriptions()
        if (id) {
            getSingleSubscription()
        }
    }, [])
    // useEffect(() => {
    //     getSubscriptions()
    //     if (!(id === '4577432') && subs?.length > 0) {
    //         getSingleSubscription()
    //     }
    // }, [subs?.length > 0])

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <div className="row bg-color-raffle">
                    <div className="col-xl-8 col-12">
                        <div className="addraffles">
                            <div className="upload-img">
                                <label>Image</label>
                                <div className="upload">
                                    <label htmlFor="upload" className='upload-imgggg w-100 h-100'>
                                        {
                                            dImage ?
                                                <img src={dImage} className='imgProfile w-100 h-100 mb-0' alt="" />
                                                : null
                                        }
                                        <img src="\dashboard-assets\upload.svg" alt="img" className='img-fluid' />
                                        <h6>Drag & Drop or <label htmlFor='upload'>Browse</label></h6>
                                    </label>
                                </div>

                                {error && (!(raffelCat?.image === '') ? null : <p className="text-danger mt-1">Image is missing!</p>)}
                                <input onChange={addRaffel} type="file" name='image' className='d-none' id='upload' />
                            </div>
                            <div className="option-field">
                                <label>Name</label>
                                <input value={raffelCat?.name} onChange={addRaffel} name='name' type="text" placeholder='Name' />
                                {error && (!(raffelCat?.name === '') ? null : <p className="text-danger mt-1">Name is missing!</p>)}
                            </div>
                            <div className="option-field">
                                <label>Description</label>
                                <textarea value={raffelCat?.description} onChange={addRaffel} name='description' placeholder='Description' />
                                {error && (!(raffelCat?.description === '') ? null : <p className="text-danger mt-1">Description is missing!</p>)}
                            </div>
                            <div className="option-field">
                                <label>Total No. of tickets</label>
                                <input value={raffelCat?.totalTickets} onChange={addRaffel} name='totalTickets' type="number" min="0" placeholder='Total No. of tickets' />
                                {error && (!(raffelCat?.totalTickets === '') ? null : <p className="text-danger mt-1">Total Tickets is missing!</p>)}
                            </div>

                            <div className="option-field">
                                <label>Max ticket per person</label>
                                <input value={raffelCat?.maxCap} onChange={addRaffel} name='maxCap' type="number" min="0" placeholder='Max ticket per person' />
                                {error && (!(raffelCat?.maxCap === '') ? null : <p className="text-danger mt-1">Max ticket per person is missing!</p>)}
                            </div>
                            <div className="option-field">
                                <label>Price per ticket $</label>
                                <input value={raffelCat?.pricePerTicket} onChange={addRaffel} name='pricePerTicket' min="0" type="number" placeholder='Price per ticket' />
                                {error && (!(raffelCat?.pricePerTicket === '') ? null : <p className="text-danger mt-1">Price Per Ticket is missing!</p>)}
                            </div>
                            <div className="option-field">
                                <label>Category</label>
                                <div class="dropdown">
                                    <a class="btn dropdowntoggle eleipiess onlyboldtext" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placeholder="Category">
                                        {raffelCat?.category_name}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                            <path d="M2.4345 5.31539C2.4055 5.28714 2.2815 5.18047 2.1795 5.0811C1.538 4.49854 0.488 2.97881 0.1675 2.18339C0.116 2.06259 0.007 1.75718 0 1.59401C0 1.43765 0.036 1.2886 0.109 1.14637C0.211 0.96907 0.3715 0.826839 0.561 0.748904C0.6925 0.698734 1.086 0.620799 1.093 0.620799C1.5235 0.542864 2.223 0.5 2.996 0.5C3.7325 0.5 4.4035 0.542864 4.8405 0.606673C4.8475 0.61398 5.3365 0.691914 5.504 0.777155C5.81 0.933512 6 1.23892 6 1.56576V1.59401C5.9925 1.80687 5.8025 2.25451 5.7955 2.25451C5.4745 3.00706 4.476 4.49172 3.8125 5.08841C3.8125 5.08841 3.642 5.25645 3.5355 5.32952C3.3825 5.4435 3.193 5.5 3.0035 5.5C2.792 5.5 2.595 5.43619 2.4345 5.31539Z" fill="#030229" />
                                        </svg>
                                    </a>

                                    <div class="dropdown-menu mydropppp" aria-labelledby="dropdownMenuButton">
                                        {subs?.map((item) => {
                                            return (
                                                <p class="dropdown-item namediv" onClick={() => setRaffelCat({ ...raffelCat, category_id: item?.id, category_name: item?.category })}>{item?.category}</p>
                                            )
                                        })}
                                    </div>
                                </div>
                                {error && (!(raffelCat?.category_id === 'Category') ? null : <p className="text-danger mt-2 mt-1">Category is missing!</p>)}
                            </div>
                            <div className="twice-field">
                                <div className="option-field">
                                    <label>Start Date</label>
                                    {/* <input value={raffelCat?.startDate} onChange={addRaffel} name='startDate' type="date" placeholder='Start Date/time' /> */}
                                    {console.log(raffelCat?.startDate, "timeeee")}
                                    <input
                                        // value={raffelCat?.startDate}
                                        value={formatDateForInput(raffelCat?.startDate)}
                                        onChange={addRaffel}
                                        name='startDate'
                                        type="datetime-local"
                                        placeholder='Start Date/time'
                                        min={currentDateTime}
                                    />

                                    {/* <DateRangePicker
                                        ranges={[selectionRange]}
                                        onChange={handleSelect}
                                    /> */}

                                    {error && (!(raffelCat?.startDate === '') ? null : <p className="text-danger mt-1">Start Date is missing!</p>)}
                                    {/* <img src='\dashboard-assets\calendar.png' className='clanderimggonly' /> */}
                                </div>
                                <div className="option-field">
                                    <label>End Date</label>

                                    {/* <input value={raffelCat?.endDate} onChange={addRaffel} name='endDate' type="date" placeholder='End Date/time' /> */}

                                    <input
                                        // value={raffelCat?.endDate}
                                        value={formatDateForInput(raffelCat?.endDate)}
                                        onChange={addRaffel}
                                        name='endDate'
                                        type="datetime-local"
                                        placeholder='End Date/time'
                                        min={currentDateTime}
                                    />


                                    {error && (!(raffelCat?.endDate === '') ? null : <p className="text-danger mt-1">End Date is missing!</p>)}
                                    {/* <img src='\dashboard-assets\calendar.png' className='clanderimggonly' /> */}
                                </div>
                            </div>
                            <div className="videopage">
                                {
                                    id ?
                                        <button className='red-b' onClick={editRaffles}>Save</button>
                                        :
                                        <button className='red-b' onClick={publish}>Publish</button>
                                }
                                <Link to="/admin/raffles">
                                    <button className='red-w'>Cancel</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {id === '4577432' ||
                        <div className="col-xl-4 col-12">
                            {raffelCat?.winner ? null :
                                <div className="topCard">
                                    <span className='pH'>
                                        <p>Ticket Booked</p>
                                        <h4>{raffelCat?.ticketBooked?.length}/{raffelCat?.totalTickets}</h4>
                                    </span>
                                    <span>
                                        {raffelCat?.ticketBooked?.length === raffelCat?.totalTickets ?
                                            <img src="\dashboard-assets\sweepFunDis.svg" alt="" />
                                            :
                                            <img onClick={sweepRaffles} className='cPointer' src="\dashboard-assets\sweepFun.svg" alt="" />
                                        }
                                    </span>
                                </div>
                            }
                            {
                                id &&
                                <div className="sidebar-raffle">
                                    {raffelCat?.winner &&
                                        <div className="main-card">
                                            <label>Winner</label>
                                            <div className="ticket">
                                                <div className="left">
                                                    <span>01</span>
                                                    <div className="profile">
                                                        <div className="inner-left">
                                                            <div className="profile-img">
                                                                <img src={raffelCat?.winner?.profile_image} alt="img" className='img-fluid' />
                                                            </div>
                                                        </div>
                                                        <div className="inner-right">
                                                            <h6>{raffelCat?.winner?.full_name}</h6>
                                                            <p>Tickets No: {raffelCat?.winnerTicketNo}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <button className='blue-btn' data-toggle="modal" data-target="#viewticket">View Tickets</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {raffelCat?.rafLeadTickets?.length > 0 &&
                                        <div className="main-card">
                                            <label>Leaderboard</label>
                                            {raffelCat?.rafLeadTickets?.map((item, id) => {
                                                return (
                                                    <div className="ticket">
                                                        <div className="left">
                                                            <span>{id < 10 && 0}{id + 1}</span>
                                                            <div className="profile">
                                                                <div className="inner-left">
                                                                    <div className="profile-img">
                                                                        <img src={item?.User?.profile_image} alt="img" className='img-fluid' />
                                                                    </div>
                                                                </div>
                                                                <div className="inner-right">
                                                                    <h6>{item?.User?.full_name}</h6>
                                                                    <p>Tickets: {item?.ticketbought}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="right">
                                                            {raffelCat?.ticketBooked?.length > 0 && <button className='blue-btn' data-toggle="modal" data-target="#viewticket" onClick={() => getUserIdHandel(item?.User?.id)}>View Tickets</button>}
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    }
                                    {console.log('kunnn', raffelCat?.ticketBooked)}
                                </div>
                            }
                        </div>
                    }
                </div>

                {/* modal view tickets ................... */}


                <div class="modal fade view-tickets" id="viewticket" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title modalhndinggggff" id="exampleModalLabel">Tickets Number</h5>
                            </div>
                            <div class="modal-body">
                                <div className="countsss">
                                    {raffelCat?.ticketBooked?.map((item) => {
                                        console.log("🚀 ~ {raffelCat?.ticketBooked?.map ~ item:", item)
                                        return (
                                            userId == item?.user_id &&
                                            <span className="count-value count-active">
                                                {
                                                    item?.ticket_no
                                                }
                                            </span>
                                        )
                                    })}

                                </div>
                                <div data-dismiss="modal" className=' text-center'>
                                    <button className='btncomxxxxxon'>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Addraffles
