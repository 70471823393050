import "./raffles.scss"
import React, { useState } from 'react'
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import Category from "views/userflow/ListitemArray";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { Link } from "react-router-dom";

const AddCategoryRaffle = (props) => {
console.log(props, "brandName in comp");
    const [category, setCategory] = useState()
    const [totalItem, setTotalItem] = useState()
    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('mytoken')
    const history = useHistory()
    const id = props.match.params.id;

    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);


    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }


    console.log("iddd", id)
    const addSubscription = async () => {
        setOpen(true)
        if (!category) {
            toast.error('category is missing!', {
                position: "top-center",
                autoClose: 2000,
            });
            setOpen(false)
        } else if (!totalItem) {
            toast.error('Total item is missing!', {
                position: "top-center",
                autoClose: 2000,
            });
            setOpen(false)
        } else if (category && totalItem) {
            axios.post(Environment.backendUrl + "/rafflesCategory/add", { category, totalItem }, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' } })
                .then((response) => {
                    setOpen(false)
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                    history?.push('/admin/rafflescategory');
                    // history.push('/admin/subscription')
                    // window.location.assign('/admin/newsbanner')
                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            setOpen(false)
            toast.error("Field cannot be empty", {
                position: "top-center",
                autoClose: 2000,
            });
        }

    }
    const editSubscription = async () => {
        // data.append("id", id)
        setOpen(true)
        if (!category) {
            toast.error('category is missing!', {
                position: "top-center",
                autoClose: 2000,
            });
            setOpen(false)

        } else if (!totalItem) {
            toast.error('Total item is missing!', {
                position: "top-center",
                autoClose: 2000,
            });
            setOpen(false)
        } else if (category && totalItem) {
            axios.post(Environment.backendUrl + `/rafflesCategory/edit`, { id, category, totalItem }, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' } })
                .then((response) => {
                    setOpen(false)
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                    history?.push('/admin/rafflescategory');
                    // window.location.assign('/admin/newsbanner')
                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            setOpen(false)
            toast.error("Field cannot be empty", {
                position: "top-center",
                autoClose: 2000,
            });
        }

    }
    const getSingleSubscription = async () => {
        axios.get(Environment.backendUrl + `/rafflesCategory/find/${id}`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                setOpen(false)
                console.log('single sub', response?.data?.rafflesCategory)
                setCategory(response?.data?.rafflesCategory?.category)
                setTotalItem(response?.data?.rafflesCategory?.totalItem)
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
                // window.location.assign('/admin/newsbanner')
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }
    useEffect(() => {
        if (id) {
            getSingleSubscription()
        }
    }, [])
    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />


            <div className="content">
                <div className="raffles">

                    <div className="addraffles">
                        <div className="option-field">
                            <label>Category</label>
                            <input value={category} onChange={(e) => setCategory(e.target.value)} type="text" placeholder='Category' />

                        </div>
                        <div className="option-field mb-0">
                            <label>Total Items</label>
                            <input value={totalItem} onChange={(e) => setTotalItem(e.target.value)} type="number" min='0' placeholder='Total Items' />
                        </div>
                        <div className="bottombtn">
                            {
                                id ?
                                    <button onClick={editSubscription} className='red-b'>Save</button>
                                    :
                                    <button onClick={addSubscription} className='red-b'>Publish</button>
                            }
                            <Link to="/admin/rafflescategory">
                                <button className='red-w'>Cancel</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCategoryRaffle
