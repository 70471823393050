
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import './games.scss';
import DemoNavbar from "components/Navbars/DemoNavbar.js";

// reactstrap components
function UpcomingDetail(props) {

    const token = localStorage.getItem('mytoken')
    const [open, setOpen] = useState(false);
    const [myFiles, setMyFiles] = useState([]);
    const id = props.match.params.id;

    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const getdata = () => {
        setOpen(true)
        axios.get(Environment.backendUrl + "/game/find/" + id, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setOpen(false)
                setMyFiles(response.data.game)
                // setOpen(true)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    useEffect(() => {
        getdata()
    }, [token])


    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <section className="daily daily-detail card">
                    <form>
                        <div className="inner-submit-lower-div">
                            <div class="row">
                                <div class="col-md-8">
                                    <div className="row">
                                        <div className="row">
                                            <div className="col-12">
                                                <div class="form-group">
                                                    <label for="example">Game Title</label>
                                                    <input type="text" class="form-control" value={myFiles.name} id="example" aria-describedby="text" disabled placeholder="" />
                                                    {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                                                </div>
                                            </div>
                                            <div className="col-12 ">
                                                <div class="form-group">
                                                    <label className="padd-top" for="example">Game Description</label>
                                                    <textarea class="form-control" value={myFiles.description} disabled id="exampleFormControlTextarea1" placeholder="" rows="5"></textarea>
                                                    {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div class="form-group choose-category">
                                                    <label class="form-check-label" for="exampleRadios1">
                                                        Choose Category
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" checked={myFiles.category === 'Upcoming Daily Challenge' ? "checked" : ''} type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                                                    <label class="form-check-label" for="exampleRadios1">
                                                        Upcoming Daily Challenge
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" checked={myFiles.category === 'Upcoming Future' ? "checked" : ''} type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                                                    <label class="form-check-label" for="exampleRadios1">
                                                        Future Coming
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-12 ">
                                            <div class="form-group">
                                                <label className="padd-top ptb20" for="example">Game Thumbnail</label>
                                                <div className="main-image-div">
                                                    <label for="example">Horizontal Image</label>
                                                    <div className="choose-filessss yoyoyo">
                                                        <img src={myFiles.horizontalImage} alt="" className="imgdbs12" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 ">
                              



                                            <div class="form-group">
                                                <div className="main-image-div">
                                                    <label for="example">Vertical Image</label>
                                                    <div className="choose-filessss yoyoyo">
                                                        <img src={myFiles.verticalImage} alt="" className="imgdbs13" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 ">
                                            <div class="form-group">
                                                <div className="main-image-div">
                                                    <label for="example">Round Image</label>
                                                    <div className="choose-filessss yoyoyo">
                                                        <img src={myFiles.roundImage} alt="" className="imgdbs14" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div class="form-group">
                                                <div className="videopage">
                                                    <Link to={"/admin/editupcoming/" + myFiles.id}>
                                                        <button className="red-b bdhcb">Edit</button>
                                                    </Link>
                                                    <Link to="/admin/upcoming">
                                                        <button className="red-w">Cancel</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 order-md-1 order-0">
                                    <div class="form-group rightside-for-group">
                                        <label for="exampleInputsymbol">Game</label>
                                        <div className="dashed-border-news">
                                            {/* <img src={myFiles.gameImage} alt="" className="sdh" /> */}
                                            {/* <div className="main-image-div">
                                                <img src={`${images['addvid2.png']['default']}`} alt="" />
                                                <p>Drag & drop or <span><Link>Browser</Link></span></p>
                                                <img src={logo ? logo : require("../../static/images/submit-form/cloud.png")} alt="" />
                                                {selectedImg?renderPhotos(selectedImg):null}
                                            </div> */}

                                            <div className="dashed-border-new">
                                                {/* <img src={myFiles.gameImage} alt="" className="sdh" /> */}
                                                <div className="main-image-div">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="45" viewBox="0 0 61 45" fill="none">
                                                        <path d="M47.6633 44.6918H37.804H35.1492H34.5758V31.4672H38.9009C39.9978 31.4672 40.6459 30.2208 39.9978 29.3234L31.5844 17.6818C31.0484 16.9339 29.9391 16.9339 29.4031 17.6818L20.9898 29.3234C20.3416 30.2208 20.9773 31.4672 22.0866 31.4672H26.4117V44.6918H25.8384H23.1835H11.7538C5.21005 44.3303 0 38.2104 0 31.5794C0 27.005 2.48038 23.0165 6.15734 20.8602C5.8208 19.9503 5.6463 18.9781 5.6463 17.956C5.6463 13.2819 9.42297 9.50525 14.0971 9.50525C15.1067 9.50525 16.0789 9.67975 16.9888 10.0163C19.6935 4.28273 25.5268 0.306641 32.3073 0.306641C41.0821 0.319105 48.3114 7.03733 49.134 15.6003C55.8772 16.7594 61 23.004 61 30.0713C61 37.6246 55.1169 44.1683 47.6633 44.6918Z" fill="#EDEDEE" />
                                                    </svg>
                                                    <div className="choose-filessss">
                                                        {/* {selectedImg_new ? renderPhotos(selectedImg_new) : null} */}
                                                        <p className='draganddrop'>Drag & drop or <span className='common' >Browse</span></p>
                                                        {/* <input type="file" className="custom-file-inputt" id="myFile" name="filename" onChange={(e) => handleFileSelect3(e)} /> */}
                                                        {/* <form action="" className="style-actionn">
                                                    <input type="file" className="custom-file-inputt" accept="video/*" id="myFile" name="filename" />
                                                </form> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </>
    );
}

export default UpcomingDetail;
