
import React, { useEffect, useState } from "react";
import axios from 'axios';
import './user.scss';
// reactstrap components
import { Link } from "react-router-dom";
import Category from "./ListitemArray";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Pagination } from "react-bootstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

const User = (props) => {

  const [user, setUser] = useState([]);
  const [category, setCategory] = useState('Filter')
  const [user1, setUser1] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  console.log("🚀 ~ User ~ page:", page)
  const [min, setMIn] = useState();
  const [max, setMax] = useState();
  const [pageCount, setPageCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState(null);
  const [tabStatus, setTabStatus] = useState('Verified')
  const [limit, setLimit] = useState(10);
  const [calledAPI, setCalledAPI] = useState(false);

  const [sortByState, setSortByState] = useState(null);
  const [filterState, setFilterState] = useState(null);

  const [sortItem, setSortItem] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  const handleSelectChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setCalledAPI(false);
  };

  const handleApplyClick = () => {
    // Handle the apply logic and API call here
    console.log(`Sort by: ${sortItem}, Order: ${sortOrder}`);
    // Perform the API call or any other action needed
    if (sortItem) {
      getAllUser();
      setSortItem(null);
    }
  };

  const handleSelect = (eventKey) => {
    if (eventKey) {
      setSortItem(eventKey);
      setSortOrder("asc");
      handleApplyClick();
    }
    console.log(`Selected: ${eventKey}`);
  };

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

  const token = localStorage.getItem('mytoken')


  const getAllUser = () => {
    setOpen(true);
    var data

    data = { limit: limit, page: page, search: searchTerm, sortOrder: sortOrder, sortBy: sortItem, filterBy: filterState }

    axios.post(`${Environment.backendUrl}/user/getLiveUsers`, data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setUser(response.data.users)
        setPageCount(response.data.total / limit);
        setOpen(false);

      }).catch((err) => {
        setUser([])
        setOpen(false)
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      })
  }


  const changeDateFormate = (createdAt) => {
    const originalDate = new Date(createdAt);

    // Format the date
    const formattedDate = originalDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    // Update the state with the formatted date
    return formattedDate;
  }

  const searchsubmit = (e) => {
    setPage(0)
    setPage(0)
    if (page == 0) {
      getAllUser()
    }
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage)
  };

  useEffect(() => {
    if (!calledAPI) {
      getAllUser();
      // setFilterState(null);
      // setSortByState(null);
      setCalledAPI(true);
    }
    else if (page) {
      getAllUser();
      setCalledAPI(true);
    }
    else if (sortByState) {
      getAllUser();
      // setSortByState(null);
    }
    else if (filterState) {
      getAllUser();
      // setFilterState(null);
    }
  }, [page, sortByState, filterState, calledAPI, limit]);



  return (
    <>
      <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>

      <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} handleApplyClick={handleApplyClick} setSortOrder={setSortOrder} setSortItem={setSortItem} sortByState={sortByState} setSortByState={setSortByState} filterState={filterState} setFilterState={setFilterState} handleSelect={handleSelect} />

      <div className="content pb-0">
        <div className="main-tasks user-details">
          <div className="innertable_user  ">
            <div className='maintablea_task table-responsive'>
              <table className="">
                <thead>
                  <th>Users Name</th>
                  <th>Joining Date</th>
                  <th>Wallet Address </th>
                  <th>Referrals</th>
                  <th>Email</th>
                  <th>Verification </th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {
                    user?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="mainimgdiv">
                              <div className="inerimgd">
                                <img src={data?.profile_image} className="tableimgginer">
                                </img>
                              </div>
                              <p className="tableimgtext eleipiess">
                                {data?.full_name}
                              </p>
                            </div>
                          </td>
                          <td>{changeDateFormate(data?.createdAt)}</td>
                          <td>
                            <span className="eleipiess">
                              {data?.address ? data?.address : ""}
                            </span>
                          </td>
                          <td>{data?.referrer?.length >= 0 ? data?.referrer?.length : 0}</td>
                          <td className="">{data?.email}</td>
                          <td>
                            {
                              data?.email_verified ?
                                <span className="greyish">
                                  Complete
                                </span> :
                                <span className="orange">
                                  Pending
                                </span>
                            }
                          </td>
                          <td>
                            <Link to={`/admin/userdetail/${data?.id}`}>
                              <button className="detailbtn" >Detail</button>
                            </Link>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>


          {pageCount >= 1 ?
            <div className="Paginationlattable">
              <div className="select-item-drop">
                <select onChange={handleSelectChange} value={limit}>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                </select>
              </div>

              <ReactPaginate
                previousLabel="Pre"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
              ></ReactPaginate>
            </div>
            : ''}
        </div>
      </div>
    </>
  );
}

export default User;
