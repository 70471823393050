import React, { useEffect, useState } from 'react'
import { Modal, Nav, Pagination } from 'react-bootstrap'
import './mysteryboxreward.scss'
import { Link } from 'react-router-dom';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Environment from 'utils/Environment';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { error } from 'jquery';
import { toast } from 'react-toastify';
import { Backdrop, CircularProgress } from '@material-ui/core';

const Mysteryboxreward = (props) => {

    const token = localStorage.getItem('mytoken');

    const [open, setOpen] = useState(false);
    const [calledAPI, setCalledAPI] = useState(false);

    const [rewardDate, setRewardData] = useState(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [sortByState, setSortByState] = useState(null);
    const [filterState, setFilterState] = useState(null);
    console.log("🚀 ~ User ~ sortByState: Selected timeee", sortByState, filterState)

    const [sortItem, setSortItem] = useState('');
    const [sortOrder, setSortOrder] = useState('');

    const changeDateFormate = (createdAt) => {
        console.log("🚀 ~ changeDateFormate ~ createdAt:", createdAt);
        const originalDate = new Date(createdAt);

        // Format the date and time
        const formattedDate = originalDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true, // Use 12-hour clock, you can set it to false for 24-hour clock
        });

        // Return the formatted date and time
        return formattedDate;
    }

    const handleApplyClick = () => {
        if (!sortItem) {
            alert('Please select a sort item.');
            return;
        }
        if (!sortOrder) {
            alert('Please select an order.');
            return;
        }
        // Handle the apply logic and API call here
        setSortItem(sortItem);
        setSortOrder(sortOrder);
        console.log(`Sort by: ${sortItem}, Order: ${sortOrder}`);
        // Perform the API call or any other action needed
        if (sortItem && sortOrder) {
            getAllMysteryBoxReward();
        }
    };

    const getAllMysteryBoxReward = () => {
        setOpen(true);
        axios.get(`${Environment.backendUrl}/reward/getMysteryBoxRewards`, {
            params: {
                searchQuery: searchTerm,
                filterBy: filterState,
                limit: limit,
                offset: page,
                sortBy: sortItem,
                sortOrder: sortOrder
            },
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                console.log("🚀 ~ .then ~ response: getAllMysteryBoxReward okokok", response);
                setRewardData(response?.data?.data);
                setPageCount(Math.ceil(response.data.totalCount / limit));
                setOpen(false);
            })
            .catch((error) => {
                setOpen(false);
                toast?.error(error.message);
            });
    }



    // Pagination
    const searchsubmit = (e) => {
        setPage(0);
        if (page == 0) {
            getAllMysteryBoxReward()
        }
    }

    // search 

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        console.log("testing....", e, selectedPage, calledAPI);
        setCalledAPI(false);
        setPage(selectedPage);
    };


    useEffect(() => {
        // if (!calledAPI) {
        getAllMysteryBoxReward();
        console.log("Selected timeee in useEffect");
        // setCalledAPI(true);
        // }
    }, [page, sortOrder, sortByState, filterState]);

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} handleApplyClick={handleApplyClick} setSortOrder={setSortOrder} setSortItem={setSortItem} sortByState={sortByState} setSortByState={setSortByState} filterState={filterState} setFilterState={setFilterState} />

            <div className='content'>
                <div className='maintablea_leader'>
                    <>
                        <div className="innertable table-responsive">
                            <table>
                                <thead>
                                    <th>Date </th>
                                    {/* <th>Type</th> */}
                                    <th>User </th>
                                    <th>Received Via </th>
                                    {/* <th>Reward</th> */}
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {rewardDate && rewardDate.length > 0 ? (
                                        rewardDate.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{changeDateFormate(data?.createdAt)}</td>
                                                    {/* <td>
                                                        <span className="eleipiess">
                                                            {data?.type}
                                                        </span>
                                                    </td> */}
                                                    <td>{data?.User?.full_name}</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            {data?.type}
                                                        </span>
                                                    </td>
                                                    {/* <td>Bolts +2</td> */}
                                                    <td>
                                                        <button className="detailbtn" onClick={handleShow}>Detail</button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No Data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {pageCount >= 1 ?
                            <div className='Paginationlattable'>
                                <div className="text-center">
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={page}
                                    ></ReactPaginate>
                                </div>
                            </div>
                            : ''}
                    </>
                </div>
            </div>
            <Modal className='ambmodalmain' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>

                        details
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M12.0008 13.9008L7.10078 18.8008C6.91745 18.9841 6.68411 19.0758 6.40078 19.0758C6.11745 19.0758 5.88411 18.9841 5.70078 18.8008C5.51745 18.6174 5.42578 18.3841 5.42578 18.1008C5.42578 17.8174 5.51745 17.5841 5.70078 17.4008L10.6008 12.5008L5.70078 7.60078C5.51745 7.41745 5.42578 7.18411 5.42578 6.90078C5.42578 6.61745 5.51745 6.38411 5.70078 6.20078C5.88411 6.01745 6.11745 5.92578 6.40078 5.92578C6.68411 5.92578 6.91745 6.01745 7.10078 6.20078L12.0008 11.1008L16.9008 6.20078C17.0841 6.01745 17.3174 5.92578 17.6008 5.92578C17.8841 5.92578 18.1174 6.01745 18.3008 6.20078C18.4841 6.38411 18.5758 6.61745 18.5758 6.90078C18.5758 7.18411 18.4841 7.41745 18.3008 7.60078L13.4008 12.5008L18.3008 17.4008C18.4841 17.5841 18.5758 17.8174 18.5758 18.1008C18.5758 18.3841 18.4841 18.6174 18.3008 18.8008C18.1174 18.9841 17.8841 19.0758 17.6008 19.0758C17.3174 19.0758 17.0841 18.9841 16.9008 18.8008L12.0008 13.9008Z" fill="white" />
                        </svg></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mod_bodydivxx '>

                        <div className='modfsrflex'>
                            <div className='fsteft'>
                                <h6 className='usernnamee'>Date/Time </h6>
                                <h6 className='namefullletf'> 15/11/2023 </h6>
                            </div>
                            <div className='fsteft'>
                                <h6 className='usernnamee'>Type </h6>
                                <h6 className='namefullletf'> Rare </h6>
                            </div>
                        </div>
                        <div className='modfsrflex'>
                            <div className='fsteft'>
                                <h6 className='usernnamee'>User </h6>
                                <h6 className='namefullletf'> John Doe </h6>
                            </div>
                            <div className='fsteft'>
                                <h6 className='usernnamee'>Received Via  </h6>
                                <h6 className='namefullletf'> Gifts </h6>
                            </div>
                        </div>
                        <div className='modfsrflex'>
                            <div className='fsteft'>
                                <h6 className='usernnamee'>User </h6>
                                <h6 className='namefullletf'> John Doe </h6>
                            </div>

                        </div>
                    </div>



                </Modal.Body>
            </Modal >
        </>
    )
}

export default Mysteryboxreward
