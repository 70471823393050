const Category= [
    // {
    //     item: 'lgx'
    // },
    {
        item: 'refferal',
    },
    // {
    //     item: 'trx',
    // },
    {
        item: 'SignedUp',
    },
    {
        item: 'Cancel',
    },
    
] 
export default Category;