
import React, { useEffect, useState } from "react";
import './rewardlea.scss';
// reactstrap components
import { Link } from "react-router-dom";
import user5 from "assets/img/userflow/copy 1.png";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import user17 from "assets/img/userflow/copy-icon.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Environment from "utils/Environment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Carousel from 'react-bootstrap/Carousel';
import { Dropdown } from "react-bootstrap";

function LeaderBoardDetails(props) {
    console.log("🚀 ~ LeaderBoardDetails ~ props:", props)

    const [page, setPage] = useState(0);

    const id = props.match.params.id

    // search ==========================
    const [searchTerm, setSearchTerm] = useState('');

    const [timeFilter, setTimeFilter] = useState(null);
    const [calledAPI, setCalledAPI] = useState(null);

    const tastTimeSelecter = (eventKey) => {
        let period;
        if (eventKey === 'day') {
            period = 'day';
        } else if (eventKey === 'week') {
            period = 'week';
        } else if (eventKey === 'month') {
            period = 'month';
        } else if (eventKey === '90days') {
            period = '90days';
        } else if (eventKey === 'year') {
            period = 'year';
        } else if (eventKey === 'all') {
            period = '';
        }
        setTimeFilter(period);
        setCalledAPI(false);
    };


    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }
    // search ==========================

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }
    const images = importAll(require.context('assets/img/userflow', false, /\.(png|jpe?g|svg)$/));
    const [userDetail, setUserDetail] = useState([])
    const [user, setUser] = useState({})
    const [open1, setOpen1] = useState(false);
    const [display, setDisplay] = useState({
        proofImage1: null,
        proofImage2: null,
        proofImage3: null,
        proofImage4: null,
        proofImage5: null
    })
    const [open, setOpen] = useState(false)
    const [copied, setCopied] = useState(false);
    const token = localStorage.getItem('mytoken')

    const getUserLeaderboard = async () => {
        setOpen1(true);
        const numericId = parseInt(id);

        if (isNaN(numericId)) {
            setOpen1(false);
            toast.error("Invalid ID format. Please provide a valid number.", {
                position: "top-center",
                autoClose: 2000,
            });
            return;
        }

        console.log(typeof numericId, numericId, "numericId");

        try {
            const response = await axios.get(`${Environment.backendUrl}/task/leaderboard/${numericId}`, {
                params: { filterBy: timeFilter || '' },
                headers: { "Authorization": `Bearer ${token}` }
            });

            setUser(response.data.detail);
            setUserDetail(response.data.detail.user);
        } catch (err) {
            toast.error(err.response?.data.msg, {
                position: "top-center",
                autoClose: 2000,
            });
        } finally {
            setOpen1(false);
        }
    };


    const handleChangeCHeckbox = async (event, elem) => {
        console.log("Checkbox element:::", elem);
        setOpen1(true);

        const payload = {
            taskId: elem?.TaskTrack?.task_id,
            userId: id,
            verified: !elem?.TaskTrack?.verified
        };

        console.log("Payload to be sent:::", payload);

        try {
            const response = await axios.post(`${Environment.backendUrl}/task/verify/submission`, payload, { headers: { "Authorization": `Bearer ${token}` } });
            console.log("Response from server:::", response);
            getUserLeaderboard();
            setOpen1(false);
            toast?.success(response?.data?.msg)
        } catch (err) {
            console.error("Error from server:::", err);
            setOpen1(false);
            toast.error(err.response?.data.msg, {
                position: "top-center",
                autoClose: 2000,
            });
        }
    };



    const News = userDetail.map((elem, index) => {
        console.log("verfified okokokoko", elem)
        return (
            <tr key={index}>
                <td className='forltableonly'>
                    <div className="mainimage">
                        <img src={elem.image} className=" imgages55no" alt="" />
                    </div>
                </td>
                <td className='text-style-c forltableonly'>

                    <p className="eleipiess">
                        {elem.description}
                    </p>

                </td>
                <td className="forltableonly" >
                    <p className={elem.TaskTrack.status == "completed" ? 'complete' : 'uncomplete'}>
                        {elem.TaskTrack.status}
                    </p>
                </td>
                <td className='forltableonly'>
                    <Link><img src={`${images['eye.png']['default']}`} onClick={() => collection(elem)} alt="" /></Link>
                </td>
                <td className='toggler-main forltableonly'>
                    <div class=" custom-switch">

                        <label class="switch" for={index} >
                            <input type="checkbox" defaultChecked={elem.TaskTrack.verified} onChange={(event) => handleChangeCHeckbox(event, elem)} id={index} />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </td>
            </tr>
        )
    })

    const collection = (de) => {
        setOpen(true)
        setDisplay(de.TaskTrack);
    }

    // <img src={display[`proofImage${i}`]} class="d-block w-100" alt="" />

    const Images = () => {
        let arr = [];
        for (let i = 1; i <= 5; i++) {
            if (display[`proofImage${i}`]) {
                arr.push(
                    <Carousel.Item>
                        {/* <img src="\users-assets\user-img-big.png" class="d-block w-100" alt="" />
                        <img class="d-block" src="\dashboard-assets\insta.png" alt="-block w-100" /> */}
                        {/* <h1>test</h1> */}
                        {/* <img src="\users-assets\user-img-big.png" class="d-block w-100" alt="" /> */}
                        {/* <img src={display[`proofImage${i}`]} class="d-block w-100" alt="" />
                         <img src={display[`proofImage${i}`]} class="d-block w-100" alt="" />
                         <img src={display[`proofImage${i}`]} class="d-block w-100" alt="" /> */}
                    </Carousel.Item>
                )
            }
        }
        return arr;
    }


    const close = async () => {
        setOpen(false)
    };

    useEffect(() => {
        getUserLeaderboard();
    }, [id, timeFilter])

    const account = user ? user.Accounts ? user.Accounts.length > 0 ? user.Accounts[0]?.public_address : '' : '' : "";


    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open1}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <section className="leaderdetails">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="upper-detail-page card">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="imgedit">
                                                <img src={user.profile_image} alt="" className="nnneerfdsfdsf" />
                                            </div>
                                        </div>
                                        <div className="col-lg-9">
                                            <div className="row pt-4 pt-lg-0">
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="feildss">
                                                        <label>User Name</label>
                                                        <p>{user.full_name}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="feildss">
                                                        <label>Full Name</label>
                                                        <p>{user.full_name}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="feildss">
                                                        <label>Wallet Address</label>
                                                        <p>
                                                            {user?.address ? `${user?.address?.substring(0, 6)} ... ${user?.address?.substring(user?.address.length - 6)}` : ""}
                                                            <span>
                                                                {
                                                                    copied ? <img src={user17} className="img-fluid pl-3" alt="" />
                                                                        :
                                                                        <CopyToClipboard text={user?.address} onCopy={() => setCopied(true)}>
                                                                            <img src={user5} className="img-fluid pl-3" alt="" />
                                                                        </CopyToClipboard>
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="feildss">
                                                        <label>Email Address</label>
                                                        <p>{user.email}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="feildss">
                                                        <label>Contact No</label>
                                                        <p>{user.contact_no}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-div-drop">
                                <div class="dropdown main-drop-down">
                                    {/* <button class=" dropdown-toggless" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        This Week <img src={`${images['detaildownarrow.png']['default']}`} alt="" />
                                    </button>
                                    <div class="dropdown-menu mydropppp" aria-labelledby="dropdownMenuButton">
                                    </div> */}
                                    <Dropdown className="amer_dropdonfst" onSelect={tastTimeSelecter}>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            {/* {timeFilter ? timeFilter : "This week" ? timeFilter == '90days' : "90 days"} */}
                                            {timeFilter ? (timeFilter === '90days' ? '90 days' : timeFilter) : 'This week'}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                                <path d="M2.4345 4.87169C2.4055 4.84886 2.2815 4.76266 2.1795 4.68237C1.538 4.21161 0.488 2.98355 0.1675 2.34078C0.116 2.24317 0.007 1.99638 0 1.86452C0 1.73817 0.036 1.61772 0.109 1.50279C0.211 1.35951 0.3715 1.24458 0.561 1.1816C0.6925 1.14106 1.086 1.07808 1.093 1.07808C1.5235 1.01511 2.223 0.980469 2.996 0.980469C3.7325 0.980469 4.4035 1.01511 4.8405 1.06667C4.8475 1.07257 5.3365 1.13555 5.504 1.20443C5.81 1.33078 6 1.57757 6 1.84169V1.86452C5.9925 2.03652 5.8025 2.39825 5.7955 2.39825C5.4745 3.00638 4.476 4.2061 3.8125 4.68827C3.8125 4.68827 3.642 4.82407 3.5355 4.88311C3.3825 4.97521 3.193 5.02087 3.0035 5.02087C2.792 5.02087 2.595 4.96931 2.4345 4.87169Z" fill="#4F4E69" />
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="mydropppp">
                                            <Dropdown.Item className="namediv" eventKey="day">24 hours</Dropdown.Item>
                                            <Dropdown.Item className="namediv" eventKey="week">7 days</Dropdown.Item>
                                            <Dropdown.Item className="namediv" eventKey="month">30 days</Dropdown.Item>
                                            <Dropdown.Item className="namediv" eventKey="90days">90 days</Dropdown.Item>
                                            <Dropdown.Item className="namediv" eventKey="year">year</Dropdown.Item>
                                            <Dropdown.Item className="namediv" eventKey="all">All</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="lower-upper-detail card">
                                    <div class="table-responsive">
                                        <table class="table ">
                                            <thead>
                                                <tr>
                                                    <th>Reward Image</th>
                                                    <th> Details</th>
                                                    <th> Status</th>
                                                    <th>Submitted Proof</th>
                                                    <th> Verified</th>
                                                </tr>
                                            </thead>
                                            <tbody className="main-t-body-text" >
                                                {News ? News : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <ToastContainer style={{ fontSize: 20 }} /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-modal-one">
                        <Modal isOpen={open} className="register-modal collection-modal modal-collection-show main-modal-one" centered >
                            <ModalHeader className="flexlkjalsdjlksend">
                                <button type="button" class="close" data-dismiss="modal" onClick={close} aria-label="Close">
                                    {/* <span aria-hidden="true">&times;</span> */}
                                    <img src="/users-assets/lederclosebtnn.svg" className="">
                                    </img>
                                </button>
                            </ModalHeader>
                            <ModalBody >


                                {/* <Carousel>
                                        {Images()}


                                        <img src="\users-assets\user-img-big.png" class="d-block w-100" alt="" />
                                        <img class="d-block" src="\dashboard-assets\insta.png" alt="Third slide" />
                                        <img class="d-block" src="\dashboard-assets\insta.png" alt="Third slide" />
                                        <img class="d-block" src="\dashboard-assets\insta.png" alt="Third slide" />
                                        <img class="d-block" src="\dashboard-assets\insta.png" alt="Third slide" />
                                        <img class="d-block" src="\dashboard-assets\insta.png" alt="Third slide" />
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitches" />
                                            <label class="custom-control-label" for="customSwitches"></label>
                                        </div>

                                    </Carousel> */}

                                <Carousel>
                                    <Carousel.Item>
                                        <img class="forwodth" src="\dashboard-assets\sliderrrrss.svg" alt="img" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img class="forwodth" src="\dashboard-assets\sliderrrrss.svg" alt="img" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img class="forwodth" src="\dashboard-assets\sliderrrrss.svg" alt="img" />
                                    </Carousel.Item>
                                </Carousel>
                                <div className='toggler-main text-center'>
                                    <div class=" custom-switch mt-3 d-flex justify-content-center">
                                        <p className="pr-3 forbolddddd">
                                            Verify
                                        </p>
                                        <label class="switch" >
                                            <input type="checkbox" />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {/* <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitches" />
                                    <label class="custom-control-label" for="customSwitches"></label>
                                </div> */}


                            </ModalBody>
                        </Modal>
                    </div>
                </section>
            </div>



        </>
    );
}

export default LeaderBoardDetails;
