import React, { useEffect, useState } from 'react';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { Link, useHistory } from 'react-router-dom';
import Environment from 'utils/Environment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Backdrop, CircularProgress } from '@material-ui/core';


const AddCourse = (props) => {

    const history = useHistory();
    const id = props.match.params.id;
    const token = localStorage.getItem('mytoken');
    const [open, setOpen] = useState(false);
    const [myFiles, setMyFiles] = useState();
    const [courseName, setCourseName] = useState(null);

    const [selectedImg, setSelectedImg] = useState(null);
    const [myfiles, setMyfiles] = useState(null);
    console.log("🚀 ~ AddCourse ~ myfiles:", myfiles, selectedImg)
    // search 
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 


    const handleFileSelect = (evt) => {
        console.log("🚀 ~ handleFileSelect ~ evt:", evt)
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyfiles(file)
    }

    const getCourse = () => {
        // const id = de
        // setId(id)
        setOpen(true)
        if (id) {
            axios.get(Environment.backendUrl + "/videoCategory/find/" + id, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    console.log("🚀 ~ .then ~ response getCourse :", response)
                    // setInputs(response.data.category)
                    setCourseName(response?.data?.category?.name);
                    setSelectedImg(response.data.category.image);
                    setOpen(false)
                    // opeeennn()

                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else { }

    }

    const AddCourseHandle = (e) => {
        setOpen(true)
        const data = new FormData();
        if (myfiles) {
            data.append("image", myfiles)
        }
        data.append("name", courseName)
        if (myfiles != '') {
            axios.post(Environment.backendUrl + "/videoCategory/add", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    console.log("🚀 ~ .then ~ response: Add Thumbnail", response)
                    setOpen(false)
                    toast.success(response?.data?.msg,
                        {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    // setInputs({
                    //     category: '',
                    // })
                    setSelectedImg('')
                    history?.push('/admin/addcategory');
                    // window.$('#exampleModal345').modal('hide')
                    // window.location.reload();

                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            // close()
            setOpen(false)
            toast.error("Category cannot be empty", {
                position: "top-center",
                autoClose: 2000,
            });
        }
    }
    const EditCategory = (e) => {
        setOpen(true)
        const data = new FormData();
        if (myFiles) {
            data.append("image", myFiles)
        }
        data.append("name", courseName)
        data.append("id", id)
        if (myFiles != '') {
            axios.post(Environment.backendUrl + "/videoCategory/edit", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    console.log("🚀 ~ .then ~ response: Add Thumbnail", response)
                    setOpen(false)
                    toast.success(response?.data?.msg,
                        {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    // setInputs({
                    //     category: '',
                    // })
                    setSelectedImg('')
                    history?.push('/admin/addcategory');
                    // window.$('#exampleModal345').modal('hide')
                    // window.location.reload();

                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            // close()
            setOpen(false)
            toast.error("Category cannot be empty", {
                position: "top-center",
                autoClose: 2000,
            });
        }
    }


    useEffect(() => {
        if (id) {
            getCourse();
        }
    }, [])

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />
            <div className="content">


                <div className="addbanner12345 card njdhbcdhbc">
                    <div className="inner-submit-lower-div">

                        <div className="row ">


                            <div className="col-xl-6 col-sm-12 ">
                                {/* <div className="flux-b">
                                        <h3>Add Course</h3>
                                    </div> */}
                                <div class="form-group">
                                    <label for="example">Add Thumbnail</label>
                                    <div className="main-image-div">
                                        <div className="choose-filessssssdds">
                                            {
                                                selectedImg ?
                                                    <>
                                                        <label className="text-center" htmlFor="imgFile2">
                                                            <img src={selectedImg} alt='myfiles2' className='img-fluid' />
                                                            <input type="file" name="image" accept="image/*" id="imgFile2" className='form-control d-none' onChange={(e) => handleFileSelect(e)} />
                                                        </label>
                                                    </>
                                                    :
                                                    <>
                                                        <label className="text-center" htmlFor="imgFile">
                                                            <div className="nsjddddd">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="45" viewBox="0 0 62 45" fill="none">
                                                                    <path d="M48.1633 44.6938H38.304H35.6492H35.0758V31.4692H39.4009C40.4978 31.4692 41.1459 30.2228 40.4978 29.3254L32.0844 17.6837C31.5484 16.9359 30.4391 16.9359 29.9031 17.6837L21.4898 29.3254C20.8416 30.2228 21.4773 31.4692 22.5866 31.4692H26.9117V44.6938H26.3384H23.6835H12.2538C5.71005 44.3323 0.5 38.2124 0.5 31.5814C0.5 27.007 2.98038 23.0184 6.65734 20.8621C6.3208 19.9522 6.1463 18.98 6.1463 17.958C6.1463 13.2839 9.92297 9.50721 14.5971 9.50721C15.6067 9.50721 16.5789 9.6817 17.4888 10.0182C20.1935 4.28469 26.0268 0.308594 32.8073 0.308594C41.5821 0.321058 48.8114 7.03929 49.634 15.6022C56.3772 16.7614 61.5 23.006 61.5 30.0732C61.5 37.6265 55.6169 44.1703 48.1633 44.6938Z" fill="#EDEDEE" />
                                                                </svg>
                                                                <h6 className="drtuop">
                                                                    Drag & Drop or <span className="commonfffr">Browse</span>
                                                                </h6>
                                                                <input type="file" name="image" id="imgFile" accept='image/*' className='form-control d-none' onChange={(e) => handleFileSelect(e)} />
                                                            </div>
                                                        </label>
                                                    </>
                                            }

                                        </div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label for="example">Course Name</label>
                                    <input type="text" name="category" className='form-control bbbfffddfdfdf' placeholder="Ethereum" value={courseName} onChange={(e) => setCourseName(e?.target?.value)} />
                                </div>

                                <div class="form-group">
                                    <div className="videopage videopage-btnsss">
                                        {/* <button type="button" className="button-main-dailyred">Unpublish</button> */}
                                        <Link to="/admin/addcategory">
                                            <button type="button" className="red-w">Cancel</button>
                                        </Link>
                                        {
                                            id ?
                                                <button type="button" className="red-b" onClick={EditCategory}>Save</button>
                                                :
                                                <button type="button" className="red-b" onClick={() => AddCourseHandle()}>Publish</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCourse
