
import React, { useEffect, useState } from "react";
import './video.scss';
import axios from 'axios';
import VideoPlayer from 'simple-react-video-thumbnail'
// reactstrap components
import { Link } from "react-router-dom";
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { toast } from "react-toastify";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import ReactPaginate from "react-paginate";
import { Dropdown, Modal } from "react-bootstrap";

function Videos(props) {

    function capitalize(str) {
        if (typeof str !== 'string') return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }


    const token = localStorage.getItem('mytoken')
    const Acls = JSON.parse(localStorage.getItem('acls'))
    const [allVideo, setAllVideo] = useState([]);
    const [open, setOpen] = useState(false);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    const handleShow1 = (id) => {
        setShow1(true);
        setDelId(id);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);



    const handleShow = (id, status) => {
        setShow(true);
        let obj = {
            videoId: id,
            videoStatus: status
        }
        setId(obj);
    }

    const [confirmationText, setConfirmation] = useState('');

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const [calledAPI, setCalledAPI] = useState(false);

    // search 
    const [searchTerm, setSearchTerm] = useState('');
    const [limit, setLimit] = useState(25);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [filterState, setFilterState] = useState('');
    const [filterByDate, setFilterByDate] = useState('');
    console.log(filterByDate, 'filterByDate');

    const [filerByView, setFilerByView] = useState('');
    const [filerByAccessList, setFilerByAccessList] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [_id, setId] = useState({});
    const [delID, setDelId] = useState(null);
    console.log(_id, "status okokoko");

    const handleSelectChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
        setCalledAPI(false);
    };

    // Pagination

    const searchsubmit = (e) => {
        setPage(0)
        if (page == 0) {
            All();
        }
    }

    // search 

    const All = () => {
        // setAllVideo()
        setOpen(true);
        axios.get(Environment.backendUrl + `/video/all?limit=${limit}&page=${page + 1}&searchQuery=${searchTerm}&filterBy=${filterByDate ? filterByDate : ""}&viewFilter=${filerByView ? filerByView : ""}&accessList=${filerByAccessList ? filerByAccessList : ""}`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                console.log("🚀 ~ .then ~ response: video okokok", response.data?.stakingtier);
                setAllVideo(response.data);
                // if (response?.data?.stakingtier) {
                //     setAllVideo(response.data?.stakingtier);
                // }
                setPageCount(response.data.totalCount / limit);
                setOpen(false);
            })
    }

    const getSortByVideos = () => {
        // setAllVideo()
        setOpen(true);
        axios.get(Environment.backendUrl + `/video/getVideosByFilter?filterBy=${sortBy}&limit=${limit}&page=${page + 1}`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                console.log("🚀 ~ .then ~ response: video okokok", response.data?.stakingtier);
                setAllVideo(response.data);
                // if (response?.data?.stakingtier) {
                //     setAllVideo(response.data?.stakingtier);
                // }
                setPageCount(response.data.totalCount / limit);
                setOpen(false);
            })
    }

    const getPublish = async () => {
        const access = allVideo.published;
        console.log("status", access);
        setOpen(true);
        await axios.post(`${Environment.backendUrl}/video/publish/unpublish`,
            { id: _id?.videoId, access: _id?.videoStatus == false ? true : false },
            { headers: { "Authorization": `Bearer ${token}` } }
        )
            .then((response) => {
                console.log(response, 'getPublish');
                All();
                handleClose();
                setOpen(false);
            })
            .catch((error) => {
                console.error("Error updating publish status:", error);
                setOpen(false);
            });
    };

    const clearlHandle = () => {
        setSortBy(null);
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        console.log("testing....", e, selectedPage, calledAPI);
        setCalledAPI(false);
        setPage(selectedPage);
    };

    const filtersHandle = () => {
        All();
    }

    useEffect(() => {
        if (!calledAPI || searchTerm == '') {
            All();
            setCalledAPI(true);
        }
    }, [searchTerm, page]);

    useEffect(() => {
        if (sortBy) {
            getSortByVideos();
            setCalledAPI(true);
        }
    }, [sortBy, limit])

    const editVideo = async (id, type, bool) => {
        setOpen(true)
        const data = new FormData();
        data.append("id", id)
        data.append(type, bool)
        axios.post(Environment.backendUrl + "/video/edit", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                All()
                setOpen(false)
                setTimeout(() => {
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }, 1000);
                // setOpen(true)
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-right",
                    autoClose: 2000,
                });
            })
    }

    const deleteVideo = () => {
        setOpen(true);
        axios.delete(Environment.backendUrl + `/video/deleteVideo/${delID}`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                console.log("🚀 ~ .then ~ deleteVideo: video okokok", response.data);
                handleClose1();
                if (response) {
                    toast?.success(response?.data?.msg);
                    setOpen(false);
                    setAllVideo(response.data);
                    All();
                    // setAllVideo(response.data?.stakingtier);
                }
                setPageCount(response.data.totalCount / limit);
                setOpen(false);
            }).catch((error) => {
                handleClose1();
                setOpen(false);
                console.log(error, 'error');
            })
    }

    const images = importAll(require.context('assets/img/userflow', false, /\.(png|jpe?g|svg)$/));
    const data = allVideo?.videos?.map((elem, index) => {
        const stakingTiers = ['premium', 'legend', 'diamond', 'platinum', 'gold', 'silver', 'freeUser'];

        const levelRows = stakingTiers.map(tier => {
            const tierData = allVideo?.stakingtier?.filter(item => item?.stakeTier === tier)[0];
            const isChecked = elem?.[tier];
            const count = tierData?.count || 0;

            return (
                <>
                    <Dropdown.Item className="namediv formcheck_newwwwws namediv d-flex justifycontentend" >
                        <label class="checkBox m-0 p-0 warrrrw">
                            {/* <input type="checkbox" id="ch1" className="boxxxxx" /> */}
                            <input
                                disabled={!Acls?.videos?.update}
                                type="checkbox"
                                className="checkbox-button__input"
                                id={`choice-${index}-${tier}`}
                                onChange={() => editVideo(elem?.id, tier, !isChecked)}
                                checked={isChecked}
                                name="choice1"
                            />
                            <p className="inputtextss">{tier.charAt(0).toUpperCase() + tier.slice(1)}</p>
                        </label>
                        <p className="inputtextss p-0 m-0">{count}</p>
                    </Dropdown.Item>
                </>
            );
        });

        return (
            <React.Fragment key={index}>
                <tr className="trSimple">
                    <td className="main-image videotablepadingonly">
                        {/* <VideoPlayer videoUrl={elem.link} snapshotAt={10} poster={elem?.thumbnail} /> */}
                        <div className="video-player">
                            <video
                                className="video-player__video"
                                controls
                                poster={elem?.thumbnail}
                            >
                                <source src={elem?.link} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </td>
                    <td className=" videotablepadingonly ">
                        <p className="forwidthonly">
                            {elem.name}
                        </p></td>
                    <td className="videotablefont videotablepadingonly">
                        <div className="  forwidthonly"> {elem.VideoCategory?.name}</div>
                    </td>
                    <td className="videotablefont  videotablepadingonly">{elem.createdAt.split('T')[0]}</td>
                    <td className="videotablefont  videotablepadingonly">{elem.duration}</td>
                    <td className="videotablefont  videotablepadingonly">{elem.reward} LGX</td>
                    <td className="videotablefont  videotablepadingonly">{elem.views}</td>
                    {
                        elem.published ?
                            <td className="videotablefont  videotablepadingonly">
                                <button className="blue-bag w-auto px-4" onClick={() => handleShow(elem?.id, elem.published)}>Published</button>
                            </td>
                            // <button type="button" class="unpublished-btn">Unpublish</button>
                            :
                            <td className="videotablefont  videotablepadingonly">
                                <button className="btn-red-publish w-auto px-3" onClick={() => handleShow(elem?.id, elem.published)}>
                                    Unpublished
                                </button>
                            </td>
                        // <button type="button" class="published-btn">Publish</button>
                    }
                    <td className="videotablefont videotablepadingonly">
                        <Dropdown className="amer_dropdonfst videodropdown">
                            <Dropdown.Toggle id="dropdown-basic" className=" internalssvvv w-100">
                                {props?.selectedPeriod ? props?.selectedPeriod === "90days" ? "90 days" : capitalize(props?.selectedPeriod) : "Access List"}
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                    <path d="M2.4345 5.10492C2.4055 5.07924 2.2815 4.98226 2.1795 4.89193C1.538 4.36233 0.488 2.98076 0.1675 2.25765C0.116 2.14783 0.007 1.87019 0 1.72185C0 1.57971 0.036 1.44421 0.109 1.31491C0.211 1.15372 0.3715 1.02442 0.561 0.953571C0.6925 0.907962 1.086 0.837112 1.093 0.837112C1.5235 0.766262 2.223 0.727295 2.996 0.727295C3.7325 0.727295 4.4035 0.766262 4.8405 0.824271C4.8475 0.830913 5.3365 0.901762 5.504 0.979254C5.81 1.1214 6 1.39904 6 1.69617V1.72185C5.9925 1.91536 5.8025 2.3223 5.7955 2.3223C5.4745 3.00644 4.476 4.35613 3.8125 4.89857C3.8125 4.89857 3.642 5.05134 3.5355 5.11777C3.3825 5.22138 3.193 5.27275 3.0035 5.27275C2.792 5.27275 2.595 5.21474 2.4345 5.10492Z" fill="#4F4E69" />
                                </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="mydropppp">
                                {levelRows}
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    <td className="button-detailss videotablepadingonly">
                        {/* <Link className="" to={'/admin/videodetail/' + elem.id}>
                            <button className="detailbtn">
                                Details
                            </button>
                        </Link> */}
                        <div class="dropdown droped">
                            <button class="dropbtn " type="button" data-toggle="dropdown" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M10.5 19C10.5 20.1 11.4 21 12.5 21C13.6 21 14.5 20.1 14.5 19C14.5 17.9 13.6 17 12.5 17C11.4 17 10.5 17.9 10.5 19Z" fill="#3553D5" />
                                    <path d="M10.5 5C10.5 6.1 11.4 7 12.5 7C13.6 7 14.5 6.1 14.5 5C14.5 3.9 13.6 3 12.5 3C11.4 3 10.5 3.9 10.5 5Z" fill="#3553D5" />
                                    <path d="M10.5 12C10.5 13.1 11.4 14 12.5 14C13.6 14 14.5 13.1 14.5 12C14.5 10.9 13.6 10 12.5 10C11.4 10 10.5 10.9 10.5 12Z" fill="#3553D5" />
                                </svg>
                            </button>
                            <div class="dropdown-menu dropmain mydropppp">
                                <Dropdown.Item className="namediv" href={`/admin/editvideos/${elem?.id}`}>
                                    <p className="dropdown-item drop " >
                                        <button className="buttons-remove" type="button">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M14 14.668H2C1.72667 14.668 1.5 14.4413 1.5 14.168C1.5 13.8946 1.72667 13.668 2 13.668H14C14.2733 13.668 14.5 13.8946 14.5 14.168C14.5 14.4413 14.2733 14.668 14 14.668Z" fill="#3553D5" />
                                                <path d="M12.6804 2.31848C11.387 1.02514 10.1204 0.991811 8.79371 2.31848L7.98704 3.12514C7.92037 3.19181 7.89371 3.29848 7.92038 3.39181C8.42704 5.15848 9.84037 6.57181 11.607 7.07848C11.6337 7.08514 11.6604 7.09181 11.687 7.09181C11.7604 7.09181 11.827 7.06514 11.8804 7.01181L12.6804 6.20514C13.3404 5.55181 13.6604 4.91848 13.6604 4.27848C13.667 3.61848 13.347 2.97848 12.6804 2.31848Z" fill="#3553D5" />
                                                <path d="M10.4056 7.68458C10.2122 7.59125 10.0256 7.49792 9.84558 7.39125C9.69891 7.30458 9.55891 7.21125 9.41891 7.11125C9.30558 7.03792 9.17224 6.93125 9.04558 6.82458C9.03224 6.81792 8.98558 6.77792 8.93224 6.72458C8.71224 6.53792 8.46558 6.29792 8.24558 6.03125C8.22558 6.01792 8.19224 5.97125 8.14558 5.91125C8.07891 5.83125 7.96558 5.69792 7.86558 5.54458C7.78558 5.44458 7.69224 5.29792 7.60558 5.15125C7.49891 4.97125 7.40558 4.79125 7.31224 4.60458C7.18987 4.34236 6.8457 4.26446 6.64108 4.46908L2.89224 8.21792C2.80558 8.30458 2.72558 8.47125 2.70558 8.58458L2.34558 11.1379C2.27891 11.5912 2.40558 12.0179 2.68558 12.3046C2.92558 12.5379 3.25891 12.6646 3.61891 12.6646C3.69891 12.6646 3.77891 12.6579 3.85891 12.6446L6.41891 12.2846C6.53891 12.2646 6.70558 12.1846 6.78558 12.0979L10.5406 8.34289C10.7411 8.14236 10.6658 7.79734 10.4056 7.68458Z" fill="#3553D5" />
                                            </svg>
                                            Edit
                                            {/* <Link to={`/admin/edit-course${id}`}>Edit</Link>    */}
                                        </button>
                                    </p>
                                </Dropdown.Item>
                                {/* <Dropdown.Item className="namediv">     addcours
                                <p className="dropdown-item drop" > 
                                    <i class="fas fa-edit" onClick={() => getCourse(elem.id)}> Edit </i>
                                    <Link to={`/admin/edit-course/${elem.id}`}>Edit</Link>
                                </p>
                            </Dropdown.Item> */}
                                <Dropdown.Item className="namediv">
                                    <p className="dropdown-item drop" >
                                        <button className="buttons-remove" type="button" onClick={() => handleShow1(elem?.id)} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M14.0491 3.48536C12.9757 3.3787 11.9024 3.2987 10.8224 3.2387V3.23203L10.6757 2.36536C10.5757 1.75203 10.4291 0.832031 8.86905 0.832031H7.12238C5.56905 0.832031 5.42238 1.71203 5.31572 2.3587L5.17572 3.21203C4.55572 3.25203 3.93572 3.29203 3.31572 3.35203L1.95572 3.48536C1.67572 3.51203 1.47572 3.7587 1.50238 4.03203C1.52905 4.30536 1.76905 4.50536 2.04905 4.4787L3.40905 4.34536C6.90238 3.9987 10.4224 4.13203 13.9557 4.48536C13.9757 4.48536 13.9891 4.48536 14.0091 4.48536C14.2624 4.48536 14.4824 4.29203 14.5091 4.03203C14.5291 3.7587 14.3291 3.51203 14.0491 3.48536Z" fill="#F04914" />
                                            <path d="M12.8211 5.42797C12.6611 5.2613 12.4411 5.16797 12.2145 5.16797H3.7878C3.56114 5.16797 3.33447 5.2613 3.18114 5.42797C3.0278 5.59464 2.94114 5.8213 2.95447 6.05464L3.3678 12.8946C3.44114 13.908 3.53447 15.1746 5.86114 15.1746H10.1411C12.4678 15.1746 12.5611 13.9146 12.6345 12.8946L13.0478 6.0613C13.0611 5.8213 12.9745 5.59464 12.8211 5.42797ZM9.1078 11.8346H6.8878C6.61447 11.8346 6.3878 11.608 6.3878 11.3346C6.3878 11.0613 6.61447 10.8346 6.8878 10.8346H9.1078C9.38113 10.8346 9.6078 11.0613 9.6078 11.3346C9.6078 11.608 9.38113 11.8346 9.1078 11.8346ZM9.6678 9.16797H6.33447C6.06114 9.16797 5.83447 8.9413 5.83447 8.66797C5.83447 8.39464 6.06114 8.16797 6.33447 8.16797H9.6678C9.94113 8.16797 10.1678 8.39464 10.1678 8.66797C10.1678 8.9413 9.94113 9.16797 9.6678 9.16797Z" fill="#F04914" />
                                        </svg> Delete </button>
                                    </p>
                                </Dropdown.Item>
                            </div>
                        </div>
                    </td>
                </tr>
                {/* <tr className="trLevel ">
                    <td className="levelTitle">
                        <h6>Available for:</h6>
                    </td>
                    {levelRows}
                </tr> */}
            </React.Fragment>
        );
    });

    // <img src={`${images['arrow-down.png']['default']}`} className="pl-1" alt="" />
    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar
                {...props}
                filtersHandle={filtersHandle}

                calledAPI={calledAPI}
                setCalledAPI={setCalledAPI}

                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchsubmit={searchsubmit}

                filterState={filterState}
                setFilterState={setFilterState}

                filterByDate={filterByDate}
                setFilterByDate={setFilterByDate}

                filerByView={filerByView}
                setFilerByView={setFilerByView}

                filerByAccessList={filerByAccessList}
                setFilerByAccessList={setFilerByAccessList}

                sortBy={sortBy}
                setSortBy={setSortBy}

                clearlHandle={clearlHandle}
            />

            <div className="content pb-0">
                <section className="videos ccard main-tasks user-details">
                    <div className="innertable_user maintablea_task">
                        <div className='table-responsive'>
                            <table class="table mt-0 ">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th > Product Name</th>
                                        <th > Course </th>
                                        <th > Upload Date </th>
                                        <th > Duration </th>
                                        <th > Reward </th>
                                        <th > Views </th>
                                        <th > Status </th>
                                        <th>Avaliable for</th>
                                        <th > Action</th>
                                    </tr>
                                </thead>
                                <tbody className="main-t-body-text" >
                                    {
                                        data?.length > 0 ?
                                            data
                                            :
                                            <tr>
                                                <td colSpan="100%" className="text-center" style={{ cursor: 'auto' }}>
                                                    No data found
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <>
                            <div className="Paginationlattable px-0">
                                <div className="select-item-drop">
                                    <select onChange={handleSelectChange} value={limit}>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={75}>75</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                                {pageCount >= 1 ?
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={page}
                                    ></ReactPaginate>
                                    : ''}
                            </div>
                        </>
                    </div>
                </section>
            </div>

            {/* Publish/Unpublish video Confirmation modal */}
            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                className="generic-modal ambmodalmain"
                centered
            >
                {" "}
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_id?.videoStatus ? "Publish" : "Unpublish"}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M12.0008 13.9008L7.10078 18.8008C6.91745 18.9841 6.68411 19.0758 6.40078 19.0758C6.11745 19.0758 5.88411 18.9841 5.70078 18.8008C5.51745 18.6174 5.42578 18.3841 5.42578 18.1008C5.42578 17.8174 5.51745 17.5841 5.70078 17.4008L10.6008 12.5008L5.70078 7.60078C5.51745 7.41745 5.42578 7.18411 5.42578 6.90078C5.42578 6.61745 5.51745 6.38411 5.70078 6.20078C5.88411 6.01745 6.11745 5.92578 6.40078 5.92578C6.68411 5.92578 6.91745 6.01745 7.10078 6.20078L12.0008 11.1008L16.9008 6.20078C17.0841 6.01745 17.3174 5.92578 17.6008 5.92578C17.8841 5.92578 18.1174 6.01745 18.3008 6.20078C18.4841 6.38411 18.5758 6.61745 18.5758 6.90078C18.5758 7.18411 18.4841 7.41745 18.3008 7.60078L13.4008 12.5008L18.3008 17.4008C18.4841 17.5841 18.5758 17.8174 18.5758 18.1008C18.5758 18.3841 18.4841 18.6174 18.3008 18.8008C18.1174 18.9841 17.8841 19.0758 17.6008 19.0758C17.3174 19.0758 17.0841 18.9841 16.9008 18.8008L12.0008 13.9008Z" fill="white" />
                        </svg>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-outter text-center">
                        <div className="row main-cardssss">
                            <div className="col-md-12 col-12">
                                <div className="awesms">
                                    {
                                        _id?.videoStatus ?
                                            <img src="\unpub.png" className="img-fluid" />
                                            :
                                            <img src="\publish.png" className="img-fluid" />
                                    }
                                </div>
                                <div className="flux-b pt-3">
                                    {
                                        _id?.videoStatus ?
                                            <p className="reddettllss ">
                                                Are you sure you want to publish this video?
                                            </p>
                                            :
                                            <p className="reddettll">
                                                Are you sure want to unpublish this video?
                                            </p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12 col-12 ptb20 m-auto">
                                <div className="button-modal-dailyssss">
                                    <button type="button" className="button-main-dailys w-100" data-dismiss="modal" aria-label="Close" onClick={handleClose} >Cancel</button>
                                    <button type="button" className={_id?.videoStatus ? "button-main-daily_new w-100 radiousssd publish-btn  " : " w-100 radiousssd unpublish-btn blue-add-category 0 button-main-daily"} onClick={() => getPublish()}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            {/* Publish/Unpublish video Confirmation modal */}


            {/* Delete video Confirmation modal */}
            <Modal
                show={show1}
                onHide={handleClose1}
                keyboard={false}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                className="generic-modal ambmodalmain"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Delete video
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M12.0008 13.9008L7.10078 18.8008C6.91745 18.9841 6.68411 19.0758 6.40078 19.0758C6.11745 19.0758 5.88411 18.9841 5.70078 18.8008C5.51745 18.6174 5.42578 18.3841 5.42578 18.1008C5.42578 17.8174 5.51745 17.5841 5.70078 17.4008L10.6008 12.5008L5.70078 7.60078C5.51745 7.41745 5.42578 7.18411 5.42578 6.90078C5.42578 6.61745 5.51745 6.38411 5.70078 6.20078C5.88411 6.01745 6.11745 5.92578 6.40078 5.92578C6.68411 5.92578 6.91745 6.01745 7.10078 6.20078L12.0008 11.1008L16.9008 6.20078C17.0841 6.01745 17.3174 5.92578 17.6008 5.92578C17.8841 5.92578 18.1174 6.01745 18.3008 6.20078C18.4841 6.38411 18.5758 6.61745 18.5758 6.90078C18.5758 7.18411 18.4841 7.41745 18.3008 7.60078L13.4008 12.5008L18.3008 17.4008C18.4841 17.5841 18.5758 17.8174 18.5758 18.1008C18.5758 18.3841 18.4841 18.6174 18.3008 18.8008C18.1174 18.9841 17.8841 19.0758 17.6008 19.0758C17.3174 19.0758 17.0841 18.9841 16.9008 18.8008L12.0008 13.9008Z" fill="white" />
                        </svg>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-outter text-center">
                        <div className="row main-cardssss">
                            <div className="col-md-12 col-12">
                                <div className="awesms">
                                    <img src="\dashboard-assets\deetteet.png" className="" />
                                </div>
                                <div className="flux-b py-3">
                                    <p className="reddettll">
                                        Are you sure you want to permanently delete this video?
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 col-12 ptb20 m-auto">
                                <div className="button-modal-dailyssss">
                                    <button type="button" className="button-main-dailys w-100" data-dismiss="modal" aria-label="Close" onClick={() => handleClose1()} >Cancel</button>
                                    <button type="button" className="blue-add-category w-100 button-main-daily" onClick={() => deleteVideo()}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Delete video Confirmation modal */}
        </>
    );
}

export default Videos;
