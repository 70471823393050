import React, { useEffect, useState } from 'react';
import arrowdown from "assets/img/userflow/arrow-down.png";
import ReactApexChart from "react-apexcharts";
import './dashboard.scss';
import { Link } from "react-router-dom";
import DateRangePicker from 'rsuite/DateRangePicker';
import { Backdrop, CircularProgress } from '@material-ui/core';
// import 'your-date-picker-library/styles.css';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import axios from 'axios';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import MysteryBoxesChart from './MysteryBoxesChart';
import moment from "moment";


function Dashboard(props) {

  const [value, setValue] = useState([]);
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);

  const [totalBoltsUsers, setTotalBoltsUsers] = useState(null);

  const token = localStorage.getItem('mytoken');
  const userRole = localStorage.getItem('myrole');
  const [searchTerm, setSearchTerm] = useState('');
  const [calledAPI, setCalledAPI] = useState(false);
  // console.log("🚀 ~ calledAPI:", calledAPI)

  // Loader states
  const [open, setOpen] = useState(false);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);

  // Loader states

  const [dashboardUserData, setDashboardUserData] = useState(null);

  const [filersCls, setFiltersCls] = useState("all");
  // console.log("🚀 ~ filersCls:", filersCls)
  const [userFilersCls, setUsersFiltersCls] = useState("all");
  const [mysteryFilersCls, setMysteryFiltersCls] = useState("all");
  const [boltsFilersCls, setBoltsFiltersCls] = useState("all");

  const [newUChartDates, setNewUChartDates] = useState(null);
  // console.log("🚀 ~ newUChartDates ok ok ok ok:", newUChartDates)
  const [newUChartcounts, setNewUChartcounts] = useState(null);
  console.log("🚀 ~ newUChartDates:", newUChartDates, newUChartcounts)

  const [newUChartDates1, setNewUChartDates1] = useState(null);
  const [newUChartcounts1, setNewUChartcounts1] = useState(null);

  // Pagination

  const [limit] = useState(10);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  // Pagination

  // search 

  const searchsubmit = (e) => {
    setPage(0)
    setPage(0)
  }

  // search 

  const countNumbersHandle = (number) => {
    if (number >= 1e12) return (number / 1e12).toFixed(2) + 'T';
    if (number >= 1e9) return (number / 1e9).toFixed(2) + 'B';
    if (number >= 1e6) return (number / 1e6).toFixed(2) + 'M';
    if (number >= 1e3) return (number / 1e3).toFixed(2) + 'K';
    return number?.toString();
  };

  const disableFutureDates = (date) => {
    return date.getTime() > new Date().getTime(); // Disable dates after today
  };

  const newUserchartHandle = async (filter) => {
    // setOpen(true); // Show loader
    setLoading1(true);

    let date = ''; // Initialize as empty string
    let date1 = ''; // Initialize as empty string
    let selectedFilter = "year"; // Default filter

    if (value1 && value1.length === 2) { // Check if 'value1' exists and contains both start and end dates
      const a = moment(value1[0]);
      const b = moment(value1[1]);
      date = a.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // Format as ISO 8601 with zero time part
      date1 = b.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // Format as ISO 8601 with zero time part

      // Calculate the difference in days between the selected dates
      const diffDays = b.diff(a, 'days');

      // Determine filter based on the difference in days
      if (diffDays < 7) {
        selectedFilter = "day";
      } else if (diffDays >= 7 && diffDays <= 29) {
        selectedFilter = "week";
      } else if (diffDays > 29 && diffDays <= 365) {
        selectedFilter = "month";
      } else {
        selectedFilter = "year";
      }
    }

    setUsersFiltersCls(filter ? filter : selectedFilter);

    try {
      const response = await axios.get(
        `${Environment.backendUrl}/dashboard/newUsersDashboardData?filter=${filter ? filter : selectedFilter}&startDate=${date}&endDate=${date1}`,
        { headers: { "Authorization": `Bearer ${token}` } }
      );

      // console.log("🚀 ~ response newUserchartHandle okokokkk new", response);
      const dates = [];
      const counts = [];
      response.data.data.newUsers.forEach(user => {
        const formattedDate = moment(user.date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // Convert to ISO 8601 format
        dates.push(formattedDate);
        counts.push(user.count);
      });

      setNewUChartDates(dates);
      setNewUChartcounts(counts);
      if (dates?.length && counts?.length) {
        setLoading1(false);
      }
      // console.log('Dates:', dates);
      // console.log('Counts:', counts);

    } catch (err) {
      setLoading1(false);
      toast.error(err.response?.data.msg, {
        position: "top-center",
        autoClose: 2000,
      });
    }
    // finally {
    //   setOpen(false); // Hide loader
    //   setLoading1(false);
    // }
  };

  const mysteryBoxeschartHandle = async (filter) => {
    // setOpen(true);
    setLoading2(true);

    let date = '';
    let date1 = '';
    let selectedFilter = 'year';

    if (value && value.length === 2) {
      const a = moment(value[0]).toDate();
      const b = moment(value[1]).toDate();
      date = moment(a).utc(true).format('YYYY-MM-DD');
      date1 = moment(b).utc(true).format('YYYY-MM-DD');
      const diffDays = moment(b).diff(moment(a), 'days');

      if (diffDays < 7) {
        selectedFilter = 'day';
      } else if (diffDays >= 7 && diffDays <= 29) {
        selectedFilter = 'week';
      } else if (diffDays > 29 && diffDays <= 365) {
        selectedFilter = 'month';
      } else {
        selectedFilter = 'year';
      }
    }

    setMysteryFiltersCls(filter ? filter : selectedFilter);

    try {
      const response = await axios.get(
        `${Environment.backendUrl}/dashboard/mystoryBoxesDashboardData?filter=${filter || selectedFilter}&startDate=${date}&endDate=${date1}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // console.log('🚀 ~ response mysteryBoxeschartHandle', response);
      const dates = [];
      const counts = [];
      response.data.data.forEach((user) => {
        dates.push(user.date);
        counts.push(user.count);
      });

      setNewUChartDates1(dates);
      setNewUChartcounts1(counts);
      // setOpen(false);
      if (dates?.length && counts?.length) {
        setLoading2(false);
      }
    } catch (err) {
      setOpen(false);
      toast.error(err.response?.data.msg, {
        position: 'top-center',
        autoClose: 2000,
      });
    }
    // finally {
    //   setOpen(false);
    //   setLoading2(false);
    // }
  };

  const totalBoltsHandle = async (filter) => {
    // setOpen(true);
    setLoading3(true);

    let date = '';
    let date1 = '';
    let selectedFilter = 'year';

    if (value2 && value2.length === 2) {
      const a = moment(value2[0]).toDate();
      const b = moment(value2[1]).toDate();
      date = moment(a).utc(true).format('YYYY-MM-DD');
      date1 = moment(b).utc(true).format('YYYY-MM-DD');
      const diffDays = moment(b).diff(moment(a), 'days');

      if (diffDays < 7) {
        selectedFilter = 'day';
      } else if (diffDays >= 7 && diffDays <= 29) {
        selectedFilter = 'week';
      } else if (diffDays > 29 && diffDays <= 365) {
        selectedFilter = 'month';
      } else {
        selectedFilter = 'year';
      }
    }

    setBoltsFiltersCls(filter ? filter : selectedFilter);

    try {
      const response = await axios.get(
        `${Environment.backendUrl}/dashboard/userDistributedBoltsDashboardData?filter=${filter || selectedFilter}&startDate=${date}&endDate=${date1}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('🚀 ~ totalBoltsHandle', response?.data?.data);
      setTotalBoltsUsers(response?.data?.data);
      if (response?.data?.data?.formattedResponse) {
        setLoading3(false);
      }
      // setOpen(false);
    } catch (err) {
      // setOpen(false);
      setLoading3(false);
      toast.error(err.response?.data.msg, {
        position: 'top-center',
        autoClose: 2000,
      });
    }
    // finally {
    //   setOpen(false);
    //   setLoading3(false);
    // }
  };

  const dashboardUserDataHandle = async (filter) => {
    // setOpen(true);
    setLoading4(true);

    let date = '';
    let date1 = '';
    let selectedFilter = 'year';

    if (value3 && value3.length === 2) {
      const a = moment(value3[0]).toDate();
      const b = moment(value3[1]).toDate();
      date = moment(a).utc(true).format('YYYY-MM-DD');
      date1 = moment(b).utc(true).format('YYYY-MM-DD');
      const diffDays = moment(b).diff(moment(a), 'days');

      if (diffDays < 7) {
        selectedFilter = 'day';
      } else if (diffDays >= 7 && diffDays <= 29) {
        selectedFilter = 'week';
      } else if (diffDays > 29 && diffDays <= 365) {
        selectedFilter = 'month';
      } else {
        selectedFilter = 'year';
      }
    }

    setFiltersCls(filter ? filter : selectedFilter);
    // console.log(open, filter, filersCls, '🚀 ~ response dashboardUserData okokok loaderrrrr');
    try {
      const response = await axios.get(
        `${Environment.backendUrl}/dashboard/dashboardData?filter=${filter || selectedFilter}&startDate=${!filter ? date : ''}&endDate=${!filter ? date1 : ''}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // console.log('🚀 ~ response dashboardUserData okokok', response);
      setDashboardUserData(response?.data?.data);
      // setOpen(false);

      if (response?.data?.data) {
        setLoading4(false);
      }
    } catch (err) {
      // setOpen(false);
      setLoading4(false);
      toast.error(err.response?.data.msg, {
        position: 'top-center',
        autoClose: 2000,
      });
    }
    // finally {
    //   setOpen(false);
    //   setLoading4(false);
    // }
  };

  useEffect(() => {
    if (!calledAPI) {
      dashboardUserDataHandle("year");
      newUserchartHandle("year");
      mysteryBoxeschartHandle("year");
      totalBoltsHandle("year");
      setCalledAPI(true);
    } else {
      if (value) {
        mysteryBoxeschartHandle();
        setCalledAPI(true);
      }
      if (value1) {
        newUserchartHandle();
        setCalledAPI(true);
      }
      if (value2) {
        totalBoltsHandle();
        setCalledAPI(true);
      }
      if (value3) {
        dashboardUserDataHandle();
        setCalledAPI(true);
      }
    }
  }, [calledAPI, value, value1, value2, value3]);


  // console.log(open, loading1, loading2, loading3, loading4, "loaders all toppp1");

  useEffect(() => {
    setOverallLoadingState();
  }, [loading1, loading2, loading3, loading4]);


  const setOverallLoadingState = () => {
    // console.log(open, loading1, loading2, loading3, loading4, "loaders all top");
    if (!loading1 && !loading2 && !loading3 && !loading4) {
      setOpen(false);
    } else {
      setOpen(true);
    }
    // console.log(open, loading1, loading2, loading3, loading4, "loaders all");
  };
  // Loader states

  const state = {
    series: [
      {
        name: 'New Users',
        data: newUChartcounts
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: newUChartDates
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy'
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          colors: {
            colors: ['#3654D6']
          }
        }
      }
    }
  }

  return (
    <>
      {
        open &&
        <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
      }

      <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

      <div className="content">
        <section className="New-dashboard">
          <div className="inertoperdiv my-3">

            <div className="dashbodtop">
              <h5 className="statay">
                Global Stats
              </h5>
              <div>
                <div className="custom-tab-bar new-cls">
                  <div onClick={() => dashboardUserDataHandle("day")} className={filersCls == 'day' ? 'active clanderdate' : "clanderdate"}>
                    1D
                  </div>
                  <div onClick={() => dashboardUserDataHandle("week")} className={filersCls == 'week' ? 'active clanderdate' : "clanderdate"}>
                    7D
                  </div>
                  <div onClick={() => dashboardUserDataHandle("month")} className={filersCls == 'month' ? 'active clanderdate' : "clanderdate"}>
                    1M
                  </div>
                  <div onClick={() => dashboardUserDataHandle("year")} className={filersCls == 'year' ? 'active clanderdate' : "clanderdate"}>
                    1Y
                  </div>
                  <div onClick={() => dashboardUserDataHandle("all")} className={filersCls == 'all' ? 'active clanderdate' : "clanderdate"}>
                    All
                  </div>
                  <div className='custom-daterangepicker'>
                    <DateRangePicker placement='bottomEnd' appearance="This week" placeholder="This week" disabledDate={disableFutureDates} onChange={(value3) => setValue3(value3)} />
                  </div>
                </div>
              </div>
            </div>


            <div className="maaasssuuss">
              <div className="innerkjcontent">
                <img src="/dashboard-assets/11.svg" className="img-fluid" />
                <h6 className="greytoral">Total Users</h6>
                <h3 className=" commondigits">{dashboardUserData?.usersCount ? countNumbersHandle(dashboardUserData?.usersCount) : 0}</h3>
              </div>
              <div className="innerkjcontent">
                <img src="/dashboard-assets/22.svg" className="img-fluid" />
                <h6 className="greytoral">New Users</h6>
                <h3 className=" commondigits">{dashboardUserData?.newUsers ? countNumbersHandle(dashboardUserData?.newUsers) : 0}</h3>
              </div>
              <div className="innerkjcontent">
                <img src="/dashboard-assets/33.svg" className="img-fluid" />
                <h6 className="greytoral">Logins</h6>
                <h3 className=" commondigits">{dashboardUserData?.loginUsers ? countNumbersHandle(dashboardUserData?.loginUsers) : 0}</h3>
              </div>
              <div className="innerkjcontent">
                <img src="/dashboard-assets/44.svg" className="img-fluid" />
                <h6 className="greytoral">Mystery Boxes</h6>
                <h3 className=" commondigits">{dashboardUserData?.mysteryBoxes ? countNumbersHandle(dashboardUserData?.mysteryBoxes) : 0}</h3>
              </div>
              <div className="innerkjcontent">
                <img src="/dashboard-assets/55.svg" className="img-fluid" />
                <h6 className="greytoral">Referrals</h6>
                <h3 className=" commondigits">{dashboardUserData?.rafflesTicket ? countNumbersHandle(dashboardUserData?.rafflesTicket) : 0}</h3>
              </div>
              <div className="innerkjcontent">
                <img src="/dashboard-assets/66.svg" className="img-fluid" />
                <h6 className="greytoral">Raffles Users </h6>
                <h3 className=" commondigits">{dashboardUserData?.referralHistory ? countNumbersHandle(dashboardUserData?.referralHistory) : 0}</h3>
              </div>
              <div className="innerkjcontent">
                <img src="/dashboard-assets/77.svg" className="img-fluid" />
                <h6 className="greytoral">Tickets / Used </h6>
                <h3 className=" commondigits">{countNumbersHandle(dashboardUserData?.ticketHistoryAndUsed?.rafflesTicketsUsed)}-{countNumbersHandle(dashboardUserData?.ticketHistoryAndUsed?.ticketHistory)}</h3>
              </div>
              <div className="innerkjcontent">
                <img src="/dashboard-assets/88.svg" className="img-fluid" />
                <h6 className="greytoral">Commission </h6>
                <h3 className=" commondigits">$0</h3>
              </div>

            </div>
          </div>

          <div className="row ">


            <div className="col-xl-6 col-sm-12 ">
              <div className="inertoperdiv">
                <div className="outerdivchart">
                  <div className="dashbodtop mb-3">
                    <h5 className="statay">
                      New Users
                    </h5>
                    <div>
                      {/* <img src="/dashboard-assets/issonns.svg" className="img-fluid custom-img" alt="Your Alt Text" /> */}
                      <div className="custom-tab-bar">
                        <div onClick={() => newUserchartHandle("day")} className={userFilersCls == 'day' ? 'active clanderdate' : "clanderdate"}>
                          1D
                        </div>
                        <div onClick={() => newUserchartHandle("week")} className={userFilersCls == 'week' ? 'active clanderdate' : "clanderdate"}>
                          7D
                        </div>
                        <div onClick={() => newUserchartHandle("month")} className={userFilersCls == 'month' ? 'active clanderdate' : "clanderdate"}>
                          1M
                        </div>
                        <div onClick={() => newUserchartHandle("year")} className={userFilersCls == 'year' ? 'active clanderdate' : "clanderdate"}>
                          1Y
                        </div>
                        <div onClick={() => newUserchartHandle("all")} className={userFilersCls == 'all' ? 'active clanderdate' : "clanderdate"}>
                          All
                        </div>
                        <div className='custom-daterangepicker'>
                          <DateRangePicker placement='bottomEnd' appearance="This week" placeholder="This week" disabledDate={disableFutureDates} onChange={(newValue) => setValue(newValue)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="chart ">
                    <ReactApexChart options={state?.options} series={state?.series} type="bar" height={350} />
                  </div>
                </div>

              </div>
            </div>

            <div className="col-xl-6 col-sm-12">
              <div className="inertoperdiv">
                <div className="outerdivchart">
                  <div className="dashbodtop mb-3">
                    <h5 className="statay">
                      Mystery Boxes
                    </h5>
                    <div className="custom-tab-bar">
                      <div onClick={() => mysteryBoxeschartHandle("day")} className={mysteryFilersCls == 'day' ? 'active clanderdate' : "clanderdate"}>
                        1D
                      </div>
                      <div onClick={() => mysteryBoxeschartHandle("week")} className={mysteryFilersCls == 'week' ? 'active clanderdate' : "clanderdate"}>
                        7D
                      </div>
                      <div onClick={() => mysteryBoxeschartHandle("month")} className={mysteryFilersCls == 'month' ? 'active clanderdate' : "clanderdate"}>
                        1M
                      </div>
                      <div onClick={() => mysteryBoxeschartHandle("year")} className={mysteryFilersCls == 'year' ? 'active clanderdate' : "clanderdate"}>
                        1Y
                      </div>
                      <div onClick={() => mysteryBoxeschartHandle("all")} className={mysteryFilersCls == 'all' ? 'active clanderdate' : "clanderdate"}>
                        All
                      </div>
                      <div className='custom-daterangepicker'>
                        <DateRangePicker placement='bottomEnd' appearance="This week" placeholder="This week" disabledDate={disableFutureDates} onChange={(newValue1) => setValue1(newValue1)} />
                      </div>
                    </div>
                  </div>
                  <div id="chart ">
                    <MysteryBoxesChart newUChartcounts1={newUChartcounts1} newUChartDates1={newUChartDates1} />
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="inertoperdiv my-3">
            <div className="lastpratdiv">
              <div className="dashbodtoplast">
                <div className="inerlast">
                  <h5 className="statayfst">
                    Total Bolts distributed to users
                  </h5>
                  <h2 className="statayfstbig">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
                      <path d="M8.6261 19.3133H13.0036V29.5133C13.0036 31.8933 14.2928 32.3749 15.8653 30.5899L26.5894 18.4066C27.9069 16.9191 27.3544 15.6866 25.3569 15.6866H20.9794V5.4866C20.9794 3.1066 19.6903 2.62494 18.1178 4.40994L7.3936 16.5933C6.09026 18.0949 6.64277 19.3133 8.6261 19.3133Z" stroke="#3553D5" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    {totalBoltsUsers?.totalSum?.toLocaleString(4)}
                  </h2>
                </div>

                <div className="statay">
                  <div className="custom-tab-bar">
                    <a className={boltsFilersCls == 'day' ? 'active clanderdate' : "clanderdate"} onClick={() => totalBoltsHandle("day")}>
                      1D
                    </a>
                    <a className={boltsFilersCls == 'week' ? 'active clanderdate' : "clanderdate"} onClick={() => totalBoltsHandle("week")}>
                      7D
                    </a>
                    <a className={boltsFilersCls == 'month' ? 'active clanderdate' : "clanderdate"} onClick={() => totalBoltsHandle("month")}>
                      1M
                    </a>
                    <a className={boltsFilersCls == 'year' ? 'active clanderdate' : "clanderdate"} onClick={() => totalBoltsHandle("year")}>
                      1Y
                    </a>
                    <a className={boltsFilersCls == 'all' ? 'active clanderdate' : "clanderdate"} onClick={() => totalBoltsHandle("all")}>
                      All
                    </a>
                    <DateRangePicker placement='bottomEnd' appearance="This week" placeholder="This week" disabledDate={disableFutureDates} onChange={(newValue2) => setValue2(newValue2)} />
                  </div>
                </div>
              </div>

              <div className="seclastpartdiv">
                {
                  totalBoltsUsers?.formattedResponse?.map((data, index) => {
                    return (
                      <div key={index} className="inerisstdiv">
                        <p className="inerleft">
                          {data?.description}
                        </p>
                        <p className="inerright">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M4.56929 9.96017H6.88679V15.3602C6.88679 16.6202 7.56929 16.8752 8.40179 15.9302L14.0793 9.48017C14.7768 8.69267 14.4843 8.04017 13.4268 8.04017H11.1093V2.64017C11.1093 1.38017 10.4268 1.12517 9.59429 2.07017L3.91679 8.52017C3.22679 9.31517 3.51929 9.96017 4.56929 9.96017Z" stroke="#161616" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          {data?.amount?.toLocaleString(4)}
                        </p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Dashboard;