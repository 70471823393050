
import React, { useEffect, useState } from "react";
import axios from 'axios';
import './user.scss';
// reactstrap components
import { Link } from "react-router-dom";
import Category from "./ListitemArray";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Modal, Pagination } from "react-bootstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

const Earlyusers = (props) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [user, setUser] = useState([]);
  console.log(user, "asdaasd");
  const [category, setCategory] = useState('Filter')
  const [user1, setUser1] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [min, setMIn] = useState();
  const [max, setMax] = useState();
  const [pageCount, setPageCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState('');
  const [tabStatus, setTabStatus] = useState('Verified')
  const [limit, setLimit] = useState(10);
  const [userDetail, setUserDetail] = useState(null);


  const handleSelectChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

  const token = localStorage.getItem('mytoken')


  const getAllUser = (search, sortOrder, sortBy) => {
    setOpen(true);
    
    // var data
    // if (category === 'Filter') {
    //   data = { pageSize: limit, page: page, search: searchTerm }
    // } else if (category !== 'Filter' && min && max) {
    //   data = { pageSize: limit, page: page, search: searchTerm, filter: category, min: min, max: max }
    // } else {
    //   toast.error('Filter Values are incorrect !')
    // }

    axios.get(`${Environment.backendUrl}/user/getAllEarlyUsers?search=${search ? search : ''}&sortOrder=${sortOrder ? sortOrder : ''}&sortBy=${sortBy ? sortBy : ''}&pageSize=${limit}&page=${page + 1}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        console.log("🚀 ~ .then ~ response: earlyusers", response)
        setUser(response.data.result)
        setPageCount(response.data.count / limit);
        setOpen(false)

      }).catch((err) => {
        setUser([])
        setOpen(false)
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      })
  }


  const changeDateFormate = (createdAt) => {
    const originalDate = new Date(createdAt);

    // Format the date
    const formattedDate = originalDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    // Update the state with the formatted date
    return formattedDate;
  }


  const getUserDetail = (id) => {
    console.log("🚀 ~ getUserDetail ~ id: userDetail okok", id)
    setOpen(true)
    axios.get(Environment.backendUrl + `/user/getDetailSingleEarlyUsers?id=${id}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        console.log("🚀 ~ file: UserDetail.js:79 ~ .then ~ response: userDetail okok", response?.data)
        setUserDetail(response?.data?.result[0])
        handleShow();
        // setTras(response.data.transactions)
        setOpen(false)

      }).catch((err) => {
        setOpen(false)
        handleClose()
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      })
  }

  const searchsubmit = (e) => {
    setPage(0)
    setPage(0)
    if (page == 0) {
      getAllUser()
    }
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage)
  };

  useEffect(() => {
    if (category !== 'Filter' && min && max) {
      getAllUser();
    } else {
      getAllUser();
    }
  }, [page, limit])


  return (
    <>
      <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>

      <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

      <div className="content">
        <div className="container-fluid">
          <div className="maintableauser">
            <div className="innertable_user table-responsive">
              <table>
                <thead>
                  <th>Users Name</th>
                  <th>Joining Date</th>
                  <th>Wallet Address </th>
                  <th>Referrals</th>
                  <th>Email</th>
                  <th>Verification </th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {
                    user?.length >0 ?
                    user?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="mainimgdiv">
                              <div className="inerimgd">
                                <img src={data?.profile_image} className="tableimgginer">
                                </img>
                              </div>
                              <p className="tableimgtext">
                                {data?.full_name}
                              </p>
                            </div>
                          </td>
                          <td>{changeDateFormate(data?.createdAt)}</td>
                          <td>
                            <span className="eleipiess">
                              {data?.address ? `${data?.address?.slice(0, 10)}...${data?.address?.slice(-5)}` : ""}
                            </span>
                          </td>
                          <td>{data?.referrer?.length >= 0 ? data?.referrer?.length : 0}</td>
                          <td>{data?.email}</td>
                          <td>
                            {
                              data?.email_verified ?
                                <span className="greyish">
                                  Complete
                                </span> :
                                <span className="orange">
                                  Pending
                                </span>
                            }
                          </td>
                          <td>
                            <button className="detailbtn " onClick={() => getUserDetail(data?._id)} >Detail</button>
                          </td>
                        </tr>
                      )
                    })
                    : 
                    <tr>No data found</tr>
                  }
                </tbody>
              </table>
            </div>

            {pageCount >= 1 ?
              <div className="Paginationlattable">
                <div className="select-item-drop">
                  <select onChange={handleSelectChange} value={limit}>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                  </select>
                </div>

                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  activeClassName="active"
                  forcePage={page}
                ></ReactPaginate>
              </div>
              : ''}
          </div>
        </div>
      </div>

      <Modal className='ambmodalmain' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            details
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <path d="M12.0008 13.9008L7.10078 18.8008C6.91745 18.9841 6.68411 19.0758 6.40078 19.0758C6.11745 19.0758 5.88411 18.9841 5.70078 18.8008C5.51745 18.6174 5.42578 18.3841 5.42578 18.1008C5.42578 17.8174 5.51745 17.5841 5.70078 17.4008L10.6008 12.5008L5.70078 7.60078C5.51745 7.41745 5.42578 7.18411 5.42578 6.90078C5.42578 6.61745 5.51745 6.38411 5.70078 6.20078C5.88411 6.01745 6.11745 5.92578 6.40078 5.92578C6.68411 5.92578 6.91745 6.01745 7.10078 6.20078L12.0008 11.1008L16.9008 6.20078C17.0841 6.01745 17.3174 5.92578 17.6008 5.92578C17.8841 5.92578 18.1174 6.01745 18.3008 6.20078C18.4841 6.38411 18.5758 6.61745 18.5758 6.90078C18.5758 7.18411 18.4841 7.41745 18.3008 7.60078L13.4008 12.5008L18.3008 17.4008C18.4841 17.5841 18.5758 17.8174 18.5758 18.1008C18.5758 18.3841 18.4841 18.6174 18.3008 18.8008C18.1174 18.9841 17.8841 19.0758 17.6008 19.0758C17.3174 19.0758 17.0841 18.9841 16.9008 18.8008L12.0008 13.9008Z" fill="white" />
            </svg></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mod_bodydivxx '>
            <div className='fsteft w-25'>
              <h6 className='usernnamee'>Image </h6>
              <h6 className='namefullletf '>
                <img src={userDetail?.profile_image} />
              </h6>
            </div>

            <div className='modfsrflex'>

              <div className='fsteft'>
                <h6 className='usernnamee'>User name</h6>
                <h6 className='namefullletf'>{userDetail?.full_name}</h6>
              </div>
              <div className='fsteft'>
                <h6 className='usernnamee'>Email </h6>
                <h6 className='namefullletf'> {userDetail?.email} </h6>
              </div>
            </div>
            <div className='modfsrflex'>
              <div className='fsteft'>
                <h6 className='usernnamee'>Date/Time </h6>
                <h6 className='namefullletf'> {changeDateFormate(userDetail?.createdAt)} </h6>
              </div>
              <div className='fsteft'>
                <h6 className='usernnamee'>Total Followers</h6>
                <h6 className='namefullletf'> {userDetail?.totalFollowers} </h6>
              </div>
            </div>
            <div className='modfsrflex'>
              <div className='fsteft'>
                <h6 className='usernnamee'>Total Following </h6>
                <h6 className='namefullletf'> {userDetail?.totalFollowing} </h6>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >

    </>
  );
}

export default Earlyusers