import { Backdrop, CircularProgress } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Environment from 'utils/Environment'
import './products.scss'
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import ReactPaginate from 'react-paginate'
import { Pagination } from 'react-bootstrap'

const Products = (props) => {

    const [open, setOpen] = useState(false);
    const [productsArr, setProductsArr] = useState([])
    console.log("🚀 ~ Products ~ productsArr:", productsArr)
    const token = localStorage.getItem('mytoken')
    const Acls = JSON.parse(localStorage.getItem('acls'))
    const [calledAPI, setCalledAPI] = useState(false);

    // search 
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 

    const getProducts = async () => {
        setOpen(true)

        axios.get(Environment.backendUrl + `/products/all?limit=${limit}&offset=${page}`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' } })
            .then((response) => {
                setOpen(false)
                console.log('asldfjld', response?.data);
                setProductsArr(response?.data?.products);
                setPageCount(response?.data?.totalCount / limit)
                // window.location.assign('/admin/newsbanner')
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const changeStatus = async (status, id) => {
        if (status && id) {
            try {
                setOpen(true)
                const res = await axios.post(`${Environment.backendUrl}/orderProduct/update/status`, { status, id }, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' } })
                toast.success('Product Category Added', {
                    position: "top-center",
                    autoClose: 2000,
                })
                getProducts()
                setOpen(false)
            } catch (error) {
                setOpen(false)
                toast.error('Category Not added !', {
                    position: "top-center",
                    autoClose: 2000,
                })
            }
        } else {
            toast.error('Fill Fields')
        }
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        console.log("testing....", e, selectedPage, calledAPI);
        setCalledAPI(false);
        setPage(selectedPage);
      };
    
    
      useEffect(() => {
        if (!calledAPI) {
            getProducts();
          setCalledAPI(true);
        }
      }, [page]);

    const [selectedItem, setSelectedItem] = useState("");
    const items = ["Pending", "Inprocess", "Completed"];

    const [selectedItem1, setSelectedItem1] = useState("");
    const items1 = ["Pending", "Processing", "Completed"];

    const [selectedItem2, setSelectedItem2] = useState("");
    const items2 = ["Pending", "Processing", "Completed"];
    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content pb-0">
                <section className="productss">
                    <div className="productstable">
                        <table>
                            <thead>
                                <th>Project Name</th>
                                <th>Price </th>
                                <th>Category</th>
                                <th>Status</th>
                                <th>Actions </th>


                                {/* {Acls?.products?.update ? <th>Actions</th> : ''} */}
                            </thead>
                            <tbody>
                                {productsArr?.map((item) => {
                                    return (
                                        <tr>
                                            <td className='dflexfortableonly'>
                                                <div className="productimg   ">
                                                    <img src={item?.image} alt="innerimg" className="innerimg" />

                                                </div>
                                                <p className=''>
                                                    Rare Box
                                                </p>
                                            </td>
                                            <td>
                                                <p className="darktextdd">{item?.price} Bolts</p>
                                            </td>
                                            <td>
                                                <p className="darktextd">{item?.ProductType?.productName}</p>
                                            </td>
                                            <td>
                                                <p className="darktext">{item?.price} Bolts</p>
                                            </td>
                                            {/* <td>
                                                <div class="dropdown droped">
                                                    <button class="dropbtn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                        {item?.status ? <><h6 className={item?.status == items[0] ? "pendingcolor" : item?.status == items[1] ? "processingcolor" : item?.status == items[2] ? "completecolor" : ""}>{item?.status}</h6></> : <p className='status'>Status</p>}

                                                        <span className=''>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                                                                <path d="M2.9345 5.10419C2.9055 5.07851 2.7815 4.98153 2.6795 4.8912C2.038 4.3616 0.988 2.98003 0.6675 2.25692C0.616 2.1471 0.507 1.86946 0.5 1.72112C0.5 1.57897 0.536 1.44347 0.609 1.31417C0.711 1.15299 0.8715 1.02369 1.061 0.952839C1.1925 0.907229 1.586 0.83638 1.593 0.83638C2.0235 0.76553 2.723 0.726562 3.496 0.726562C4.2325 0.726562 4.9035 0.76553 5.3405 0.823538C5.3475 0.83018 5.8365 0.90103 6.004 0.978522C6.31 1.12066 6.5 1.39831 6.5 1.69543V1.72112C6.4925 1.91462 6.3025 2.32157 6.2955 2.32157C5.9745 3.00571 4.976 4.3554 4.3125 4.89784C4.3125 4.89784 4.142 5.05061 4.0355 5.11703C3.8825 5.22065 3.693 5.27202 3.5035 5.27202C3.292 5.27202 3.095 5.21401 2.9345 5.10419Z" fill="#4F4E69" />
                                                            </svg>
                                                        </span>

                                                    </button>
                                                    <div class="dropdown-menu dropmain mydropppp">
                                                        {items?.map((status) => (
                                                            <p className="dropdown-item drop namediv" onClick={() => changeStatus(status, item?.id)}>

                                                                {status}
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                            </td> */}
                                            {Acls?.products?.update ? <td>
                                                <Link to={`/admin/addproducts/${item?.id}`}>
                                                    <button className="detailbtn">Edit</button>
                                                </Link>
                                            </td>
                                                : ''}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='Paginationlattable'>
  <button className='leftpigbtn' >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
      </svg>
      Prev
  </button>
  <Pagination>
      <Pagination.Item>{1}</Pagination.Item>
      <Pagination.Item>{2}</Pagination.Item>
      <Pagination.Ellipsis />
      <Pagination.Item active>{5}</Pagination.Item>
      <Pagination.Item>{6}</Pagination.Item>
  </Pagination>
  <button className='leftpigbtn' >
      Next
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
      </svg>
  </button>
  {/* {pageCount >= 1 ?
      <div className="Paginationlattable gift-pagination w-100 justify-content-center">
          <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={page}
          ></ReactPaginate>
      </div>
      : ''} */}
</div>
                </section>
            </div>
        </>
    )
}

export default Products
