import React from 'react'
import "./subscription.scss"
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DemoNavbar from "components/Navbars/DemoNavbar.js";

const Subscription = (props) => {

    const [open, setOpen] = useState(false);
    const [subs, setSubs] = useState([])
    const token = localStorage.getItem('mytoken')
    const Acls = JSON.parse(localStorage.getItem('acls'))

    // search 
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 

    const getSubscriptions = async () => {
        setOpen(true)

        axios.get(Environment.backendUrl + "/subscription/all", { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                setOpen(false)
                console.log('asldfjld', response?.data?.allSubscription)
                setSubs(response?.data?.allSubscription)
                // window.location.assign('/admin/newsbanner')
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }
    useEffect(() => {
        getSubscriptions()
    }, [])

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <div className="subscription maintableauser pl-3 ">
                    <div class="innertable_user table-responsive ">
                        <table>
                            <thead>
                                <tr className=''>
                                    <th className='tableboldhead'>
                                        
                                        Banner Image</th>
                                    <th className='tableboldhead'>Name</th>
                                    <th className='tableboldhead'>Price</th>
                                    {Acls?.subscription?.update ?
                                     <th className='tableboldhead'>Action</th> : ''}
                                </tr>
                            </thead>
                            <tbody className="main-t-body-text" >
                                {subs?.map((item) => {
                                    return (
                                        <tr>
                                            <td >
                                                <div className="mainimage">
                                                    <img src={item?.bannerImage} alt="img" className=' imgages55no' />
                                                </div>
                                            </td>
                                            <td>
                                            <p class="tablerowtextbold ">  {item?.name}</p>
                                          </td>
                                            <td>
                                            <p class="tablerowtextbold ">     ${item?.price}</p>
                                                
                                            </td>
                                            {Acls?.subscription?.update ? <td>
                                                <Link to={`/admin/addsubscription/${item?.id}`}> <button className='blue-btn'>Edit</button></Link>
                                            </td>
                                                : ''}
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Subscription
