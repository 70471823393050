
import React, { useEffect, useState } from "react";
import './badges.scss';
import Environment from "utils/Environment";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Link } from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import ReactPaginate from "react-paginate";

// reactstrap components
function Badges(props) {

    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('mytoken')
    const [myFiles, setMyFiles] = useState([]);
    const Acls = JSON.parse(localStorage.getItem('acls'))

    // search 
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const getAllBadges = () => {
        setOpen(true)
        axios.get(Environment.backendUrl + "/badges/all", { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setMyFiles(response.data.badges)
                setOpen(false)
                setPageCount(response)
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const noti = myFiles.map((elem) => {
        return (
            <tr className="">
                <td className=''>
                    <div className="inerimgddss">
                        <img src={elem?.image} className="tableimgginerxxx" alt="" />
                    </div>

                </td>
                <td className=''>

                    <p className="bagestablerow eleipiess">

                        {elem?.name}</p></td>
                <td className='bagestablerow'>{elem?.type}</td>
                <td className=''>

                    <p className="bagestablerow eleipiess">
                        {elem?.description}
                    </p></td>

                {Acls?.badges?.update ? <td> <Link to={'/admin/sendbadgesss/' + elem?.id}>

                    <button className="blue-bag">Edit</button>
                </Link></td> : ''}
                <td>
                    <button className="btn-red-publish" type="button" onClick={() => badgeDelete(elem.id)}>
                        Unpublish

                    </button>


                </td>
            </tr>
        )
    })

    const badgeDelete = (de) => {
        setOpen(true)
        const id = de
        axios.get(Environment.backendUrl + "/badges/delete/" + id, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setOpen(false)
                toast.success('Notification Deleted ', {
                    position: "top-center",
                    autoClose: 2000,
                });
                getAllBadges()

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }

    useEffect(() => {
        getAllBadges()
    }, [token])

    const images = importAll(require.context('assets/img/userflow', false, /\.(png|jpe?g|svg)$/));
    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />


            <div className="content">
                <section className="main-tasksss">
                    <div className='maintablea_task main-tasks user-details'>
                        <div className="maintablea_task table-responsive">
                            <table class="table mt-0 ">
                                <thead>
                                    <tr className="onlytablehadng">
                                        <th>Badge Image</th>
                                        <th>Name <span className="pl-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                                <path d="M2.4345 5.31539C2.4055 5.28714 2.2815 5.18047 2.1795 5.0811C1.538 4.49854 0.488 2.97881 0.1675 2.18339C0.116 2.06259 0.007 1.75718 0 1.59401C0 1.43765 0.036 1.2886 0.109 1.14637C0.211 0.96907 0.3715 0.826839 0.561 0.748904C0.6925 0.698734 1.086 0.620799 1.093 0.620799C1.5235 0.542864 2.223 0.5 2.996 0.5C3.7325 0.5 4.4035 0.542864 4.8405 0.606673C4.8475 0.61398 5.3365 0.691914 5.504 0.777155C5.81 0.933512 6 1.23892 6 1.56576V1.59401C5.9925 1.80687 5.8025 2.25451 5.7955 2.25451C5.4745 3.00706 4.476 4.49172 3.8125 5.08841C3.8125 5.08841 3.642 5.25645 3.5355 5.32952C3.3825 5.4435 3.193 5.5 3.0035 5.5C2.792 5.5 2.595 5.43619 2.4345 5.31539Z" fill="#030229" />
                                            </svg>

                                        </span> </th>
                                        <th>Type  <span className="pl-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                                <path d="M2.4345 5.31539C2.4055 5.28714 2.2815 5.18047 2.1795 5.0811C1.538 4.49854 0.488 2.97881 0.1675 2.18339C0.116 2.06259 0.007 1.75718 0 1.59401C0 1.43765 0.036 1.2886 0.109 1.14637C0.211 0.96907 0.3715 0.826839 0.561 0.748904C0.6925 0.698734 1.086 0.620799 1.093 0.620799C1.5235 0.542864 2.223 0.5 2.996 0.5C3.7325 0.5 4.4035 0.542864 4.8405 0.606673C4.8475 0.61398 5.3365 0.691914 5.504 0.777155C5.81 0.933512 6 1.23892 6 1.56576V1.59401C5.9925 1.80687 5.8025 2.25451 5.7955 2.25451C5.4745 3.00706 4.476 4.49172 3.8125 5.08841C3.8125 5.08841 3.642 5.25645 3.5355 5.32952C3.3825 5.4435 3.193 5.5 3.0035 5.5C2.792 5.5 2.595 5.43619 2.4345 5.31539Z" fill="#030229" />
                                            </svg>

                                        </span> </th>
                                        <th >Description </th>

                                        {Acls?.badges?.update ? <th>Edit </th> : ''}
                                        <th>Action </th>
                                    </tr>
                                </thead>
                                <tbody className="main-t-body-text" >
                                    {noti}
                                </tbody>
                            </table>
                        </div>
                        {
                            pageCount >= 1 ?
                                <div className="Paginationlattable">
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={page}
                                    ></ReactPaginate>
                                </div>
                                : ''
                        }
                    </div>
                </section>
            </div>
        </>
    );
}

export default Badges;
