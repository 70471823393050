
import React, { useEffect, useState } from "react";
// import './banner.scss';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Web3 from "web3";

function AddCoin(props) {

    const history = useHistory();
    const token = localStorage.getItem('mytoken')
    const id = props.match.params.id;

    const [selectedImg, setSelectedImg] = useState(null);
    const [myFiles, setMyFiles] = useState(null);
    console.log(selectedImg, myFiles, "assd");

    const [inputs, setInputs] = useState({
        name: '',
        symbol: '',
        decimals: '',
        contractAddress: '',
        type: '',
        image: '',
        chainUrl: '',
        tokenType: ''
    })
    console.log("sdfasdfsadfsdaf", inputs)


    const chainSupport = {
        BSC: {
            name: "Binance Smart Chain",
            fungible: "BEP-20",
            nft: ["BEP-721", "BEP-1155"]
        },
        ETH: {
            name: "Ethereum",
            fungible: "ERC-20",
            nft: ["ERC-721", "ERC-1155"]
        },
        MATIC: {
            name: "Polygon (Matic)",
            fungible: "ERC-20",
            nft: ["ERC-721", "ERC-1155"]
        },
        GATE: {
            name: "GateChain",
            fungible: "GRC-20",  // If GateChain uses a custom standard, adjust accordingly
            nft: ["GRC-721", "GRC-1155"]
        }
    };

    // Get the supported token types based on the selected chain
    const getSupportedTokenTypes = (chain) => {
        const chainInfo = chainSupport[chain];
        if (chainInfo) {
            return [chainInfo.fungible, ...chainInfo.nft];
        }
        return [];
    };

    const handleFileSelect = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));
            setSelectedImg(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles(file)
        console.log(file, 'asd');

    }

    console.log("coinsssss", inputs)
    const handleChange1 = (e) => {

        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const renderPhotos = (source) => {
        return <img src={source} alt="" width="200" height="200" />
    }

    const [open, setOpen] = useState(false);
    const [stakingLBoardListing, setStakingLBoardListing] = useState(null);

    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    // pagenition============================

    // Search =============
    const [searchTerm, setSearchTerm] = useState('');
    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
    }
    // Search =============

    // verify valid token address web3 =============================

    // const [address, setAddress] = useState('');
    const [isValid, setIsValid] = useState(null);
    console.log(inputs.contractAddress, isValid, "Address");

    // const web3 = new Web3(Web3.givenProvider || 'https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID');

    const web3 = new Web3(Web3.givenProvider || 'https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID');

    const isValidAddress = (contractAddress) => {
        return web3.utils.isAddress(contractAddress);
    };

    const handleValidation = (contractAddress) => {
        if (contractAddress.trim() === '') {
            setIsValid(null); // Reset validation state if input is empty
            return;
        }

        try {
            if (isValidAddress(contractAddress)) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } catch (error) {
            setIsValid(false);
        }
    };

    // verify valid token address web3 =============================

    const FIndcoin = () => {
        setOpen(true)
        axios.get(Environment.backendUrl + "/coin/find/" + id, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setInputs(response.data.coin);
                setSelectedImg(response.data.coin.image)
                setOpen(false)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    console.log(inputs.tokenType, inputs?.type, inputs.chainUrl, "asdfssdfsdfdsfsdasda");

    const Addcoin = async (event) => {
        setOpen(true)
        event.preventDefault()
        const data = new FormData();
        data.append("image", myFiles)
        data.append("name", inputs.name)
        data.append("symbol", inputs.symbol)
        data.append("decimals", inputs.decimals)
        data.append("contractAddress", inputs.contractAddress)
        data.append("type", inputs.type)
        data.append("chainUrl", inputs.chainUrl)
        data.append("chainName", inputs.chainUrl);

        if (myFiles && inputs.name && inputs.symbol && inputs.decimals && inputs.contractAddress && inputs.type && inputs.chainUrl) {
            axios.post(Environment.backendUrl + "/coin/create", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    setOpen(false)
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                    setInputs({
                        name: '',
                        symbol: '',
                        decimals: '',
                        contractAddress: '',
                        type: '',
                        image: ''
                    })
                    setSelectedImg('')
                    history.push('/admin/Coins')
                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            setOpen(false)
            toast.error("Fields cannot be empty", {
                position: "top-center",
                autoClose: 2000,
            });
        }
    }

    const editCoin = async (event) => {
        setOpen(true)
        event.preventDefault()
        const data = new FormData();
        if (myFiles) {
            data.append("image", myFiles)
        }
        data.append("name", inputs.name)
        data.append("symbol", inputs.symbol)
        data.append("decimals", inputs.decimals)
        data.append("contractAddress", inputs.contractAddress)
        data.append("type", inputs.type)
        data.append("chainUrl", inputs.chainUrl);
        data.append("chainName", inputs.chainUrl);
        data.append("id", id);
        axios.post(Environment.backendUrl + "/coin/edit", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                setOpen(false)
                setTimeout(() => {
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }, 3000);

                setInputs({
                    name: '',
                    symbol: '',
                    decimals: '',
                    contractAddress: '',
                    type: '',
                    image: ''
                })
                setSelectedImg('')
                history.push('/admin/Coins')
                // console.log(response)
                // setOpen(true)
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const cancel = () => {
        history.push('/admin/Coins')
    }

    useEffect(() => {
        handleValidation(inputs.contractAddress);
    }, [inputs.contractAddress])

    useEffect(() => {
        if (id) {
            FIndcoin();
        }
    }, [id])

    // const images = importAll(require.context('assets/img/userflow', false, /\.(png|jpe?g|svg)$/));z
    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <section className="addbanner12345 card">
                    <form>
                        <div className="inner-submit-lower-div">
                            <div class="row">
                                <div class="col-xl-7 col-md-12  order-md-0 order-1 ">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div class="form-group">
                                                <label for="example">Coin Name</label>
                                                <input type="text" name="name" value={inputs.name} onChange={handleChange1} className={'form-control'} placeholder="Coin Name" />
                                                {/* {Object.keys(TitleError).map((key) => {
                                                        return <p className="inputErrors">{TitleError[key]}</p>
                                                    })} */}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-6">
                                            <div class="form-group">
                                                <label className="padd-top" for="example">Symbol</label>
                                                <input type="text" name="symbol" value={inputs.symbol} onChange={handleChange1} className={'form-control'} placeholder="Symbol" />
                                                {/* {Object.keys(linkError).map((key) => {
                                                        console.log("key", key);
                                                        return <p className="inputErrors">{linkError[key]}</p>
                                                    })} */}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-6">
                                            <div class="form-group">
                                                <label className="padd-top" for="example">Decimal</label>
                                                <input type="number" name="decimals" value={inputs.decimals} onChange={handleChange1} className={'form-control'} placeholder="Decimal" />
                                                {/* {Object.keys(linkError).map((key) => {
                                                        console.log("key", key);
                                                        return <p className="inputErrors">{linkError[key]}</p>
                                                    })} */}
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div class="form-group">
                                                <label for="example">Address</label>
                                                <input type="text" name="contractAddress" value={inputs.contractAddress} onChange={handleChange1} className={'form-control'} placeholder="Address" />
                                                {/* {Object.keys(TitleError).map((key) => {
                                                    return <p className="inputErrors">{TitleError[key]}</p>
                                                })} */}
                                                {isValid !== null && (
                                                    <p>{isValid ? "" : <p className="text-danger">Invalid address</p>}</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            {/* <div class="form-group">
                                                <label for="example">External Link</label>
                                                <input type="text" name="chainUrl" value={inputs.chainUrl} onChange={handleChange1}  className={'form-control'} placeholder="Enter External Link" />
                                          
                                            </div> */}
                                            <div class="form-group">
                                                <label className="padd-top" for="example">Chain</label>
                                                <div class="dropdown">
                                                    <FormControl variant="outlined" className="styleeee">
                                                        <Select
                                                            native
                                                            name="chainUrl"
                                                            onChange={handleChange1}
                                                            value={inputs.chainUrl}
                                                        // inputProps={{
                                                        //     state
                                                        // }}
                                                        >
                                                            <option className="main-boot" >Select Chain</option>,
                                                            <option className="main-boot" name="chainUrl" key="option1" value='BSC'>BSC</option>;
                                                            <option className="main-boot" name="chainUrl" key="option2" value='MATIC'>Matic</option>;
                                                            <option className="main-boot" name="chainUrl" key="option3" value='ETH'>ETH</option>;
                                                            <option className="main-boot" name="chainUrl" key="option4" value='GATE'>Gate</option>;
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>

                                            <div className="col-12 p-0">
                                                <div class="form-group">
                                                    <label for="example" className="padd-top" >Token Type</label>
                                                    {/* <input type="text" name="type" onChange={handleChange1} value={inputs.type} className={'form-control myinputnew'} placeholder="Enter title of the game" /> */}
                                                    {/* {Object.keys(TitleError).map((key) => {
                                                    return <p className="inputErrors">{TitleError[key]}</p>
                                                })} */}
                                                    <div class="dropdown">
                                                        <FormControl variant="outlined" className="styleeee">
                                                            <Select
                                                                native
                                                                name="type"
                                                                onChange={handleChange1}
                                                                value={inputs.type}
                                                            >
                                                                <option className="main-boot">Select Token Type</option>
                                                                {getSupportedTokenTypes(inputs.chainUrl).map((type, index) => (
                                                                    <option className="main-boot" key={index} value={type}>{type}</option>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div class="form-group">
                                                        <div className="videopage">
                                                            {id ? <button className="red-b" onClick={editCoin}>Save</button> : <button className="red-b" onClick={Addcoin}>Publish</button>}
                                                            {/* // <button className="red-b" onClick={sendVideo}>Publish</button> */}
                                                            <button className="red-w" onClick={cancel}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-5 col-md-12 order-md-1 order-0 ">
                                    <div className="upload-img  mypointttt">
                                        <label for="exampleInputsymbol " className="manydivbart">
                                            {" "}
                                            Upload Coin Logo
                                        </label>
                                        <div className="upload sidedotsaa">
                                            {
                                                selectedImg ?
                                                    <label htmlFor="imgFile1" className="selectedIm-wrapper">
                                                        <img src={selectedImg} alt="selectedImg" />
                                                        <input type="file" name="image" id="imgFile1" accept="image/*" className={'form-control d-none'} onChange={handleFileSelect} />
                                                    </label>
                                                    :
                                                    <label htmlFor="imgFile2" style={{ cursor: "pointer" }}>
                                                        <>
                                                            <img
                                                                src="\dashboard-assets\upload.svg"
                                                                alt="img"
                                                                className="img-fluid dragimg"
                                                            />
                                                            <p className="paraa1 mypointttt">
                                                                Drag & drop or{" "}
                                                                <span className="paraa2"> Browse</span>
                                                            </p>
                                                        </>
                                                        <input type="file" name="image" id="imgFile2" accept="image/*" className={'form-control d-none'} onChange={handleFileSelect} />
                                                    </label>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </>
    );
}

export default AddCoin;
