
import React, { useEffect, useState } from "react";
import './coin.scss';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// reactstrap components
import { Link } from "react-router-dom";
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import swal from 'sweetalert';
function Coin() {
    const [myFiles, setMyFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState();
    const token = localStorage.getItem('mytoken')
    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { return images[item.replace('./', '')] = r(item) });
        return images;
    }

    const getNewsBanner = () => {
        setOpen(true)
        axios.get(Environment.backendUrl + "/MemeTokens/all", { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setMyFiles(response.data.coins)
                setOpen(false)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const close = () => {
        window.$('#exampleModal345').modal('hide')
    }
    const opeeennn = () => {
        window.$('#exampleModal345').modal('show')
    }


    const News = myFiles.map(elem => {
        return (
            <tr>
                <td className='main-image'>
                    <ul className="list-inline">
                        <li className="list-inline-item"><Link className=''> <img src={elem?.image} className="pr-2 imgagesnos" alt="" /></Link></li>
                        <li className="list-inline-item">{elem.name}</li>
                    </ul>
                </td>
                <td className=''>{elem.symbol}</td>
                <td>{elem.contractAddress}</td>
                <td> <button className="buttons-remove" type="button" onClick={() => collection(elem.id)}><i className="far fa-trash-alt"></i></button></td>
                <td> <Link to={'/admin/EditMemeCoins/' + elem?.id}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M14 14.668H2C1.72667 14.668 1.5 14.4413 1.5 14.168C1.5 13.8946 1.72667 13.668 2 13.668H14C14.2733 13.668 14.5 13.8946 14.5 14.168C14.5 14.4413 14.2733 14.668 14 14.668Z" fill="#3553D5"/>
  <path d="M12.6804 2.31848C11.387 1.02514 10.1204 0.991811 8.79371 2.31848L7.98704 3.12514C7.92037 3.19181 7.89371 3.29848 7.92038 3.39181C8.42704 5.15848 9.84037 6.57181 11.607 7.07848C11.6337 7.08514 11.6604 7.09181 11.687 7.09181C11.7604 7.09181 11.827 7.06514 11.8804 7.01181L12.6804 6.20514C13.3404 5.55181 13.6604 4.91848 13.6604 4.27848C13.667 3.61848 13.347 2.97848 12.6804 2.31848Z" fill="#3553D5"/>
  <path d="M10.4056 7.68458C10.2122 7.59125 10.0256 7.49792 9.84558 7.39125C9.69891 7.30458 9.55891 7.21125 9.41891 7.11125C9.30558 7.03792 9.17224 6.93125 9.04558 6.82458C9.03224 6.81792 8.98558 6.77792 8.93224 6.72458C8.71224 6.53792 8.46558 6.29792 8.24558 6.03125C8.22558 6.01792 8.19224 5.97125 8.14558 5.91125C8.07891 5.83125 7.96558 5.69792 7.86558 5.54458C7.78558 5.44458 7.69224 5.29792 7.60558 5.15125C7.49891 4.97125 7.40558 4.79125 7.31224 4.60458C7.18987 4.34236 6.8457 4.26446 6.64108 4.46908L2.89224 8.21792C2.80558 8.30458 2.72558 8.47125 2.70558 8.58458L2.34558 11.1379C2.27891 11.5912 2.40558 12.0179 2.68558 12.3046C2.92558 12.5379 3.25891 12.6646 3.61891 12.6646C3.69891 12.6646 3.77891 12.6579 3.85891 12.6446L6.41891 12.2846C6.53891 12.2646 6.70558 12.1846 6.78558 12.0979L10.5406 8.34289C10.7411 8.14236 10.6658 7.79734 10.4056 7.68458Z" fill="#3553D5"/>
</svg></Link></td>

            </tr>
        )
    })

    // const collection11=() =>{
    //     swal("Thanks for your rating!", `You rated us 3/3`, "success")
    // onClick={() => collection(elem.id)}
    // }

    const collection = (de) => {
        const ids = de
        setId(ids)
        opeeennn()
    }

    const deleteCoin = () => {
        close()
        setOpen(true)
        axios.get(Environment.backendUrl + "/MemeTokens/delete/" + id, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setOpen(false)
                toast.success(response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
                getNewsBanner()
                // setOpen(true)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    useEffect(() => {
        getNewsBanner()
    }, [])

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <div className="content">
                <section className="banner card">
                    <div className="container-fluid">
                        <div class="table-responsive">
                            <table class="table ">
                                <thead>
                                    <tr>
                                        <th>Token</th>
                                        <th>Symbol</th>
                                        <th>Address</th>
                                        <th>Remove</th>
                                        <th>Edit</th>

                                    </tr>
                                </thead>
                                <tbody className="main-t-body-text" >
                                    {News}
                                    {/* <tr>
                                        <td className='main-image'>
                                            <Link className=''> <img src={`${images['viedo-1.png']['default']}`} className="pr-2 imgages-no" alt="" /></Link>
                                        </td>
                                        <td className=''>New App Release</td>
                                        <td className=''>Legion Flair is now live on the Apple App Store!</td>
                                        <td><Link className="common" target="_blank">https://balance.ex...</Link></td>
                                        <td> <img src={`${images['remove1.png']['default']}`} alt="" /></td>
                                    </tr>
                                    <tr>
                                        <td className='main-image'>
                                            <Link className=''> <img src={`${images['viedo-1.png']['default']}`} className="pr-2 imgages-no" alt="" /></Link>
                                        </td>
                                        <td className=''>New App Release</td>
                                        <td className=''>Legion Flair is now live on the Apple App Store!</td>
                                        <td><Link className="common" target="_blank">https://balance.ex...</Link></td>
                                        <td> <img src={`${images['remove1.png']['default']}`} alt="" /></td>
                                    </tr> */}

                                </tbody>
                            </table>

                        </div>
                        <div class="modal fade modal-zzz" id="exampleModal345" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog daily-profile-modal">
                                <div class="modal-content daily-profile-modal-inner">
                                    <div class="modal-body modal-body-main">
                                        <div className="main-outter text-center">
                                            <div className="row main-cardssss">
                                                <div className="col-md-12 col-12">
                                                    <div className="awesm">
                                                        <i class="fas fa-exclamation-triangle"></i>
                                                    </div>
                                                    <div className="flux-b pt-3">
                                                        <h3>Are You Sure You Want to Delete This COIN</h3>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-12 ptb20">
                                                    <div className="button-modal-daily">
                                                        <button type="button" className="button-main-daily " onClick={deleteCoin} >Yes</button>
                                                        <button type="button" className="button-main-dailys" data-dismiss="modal" aria-label="Close" onChange={close} >Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Coin;
