
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import './faq.scss';
import DemoNavbar from "components/Navbars/DemoNavbar.js";

// reactstrap components
function Faq(props) {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([])
    const token = localStorage.getItem('mytoken')
    const Acls = JSON.parse(localStorage.getItem('acls'))

    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 

    const GetFAQ = () => {
        setOpen(true)
        axios.get(Environment.backendUrl + "/faq/all", { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setOpen(false)
                setData(response.data.Faqs)
                // setOpen(true)
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }


    const collection = (de) => {
        const id = de
        setOpen(true)
        axios.get(Environment.backendUrl + "/faq/delete/" + id, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setOpen(false)
                toast.success(response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
                GetFAQ()

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }
    console.log("data:::::", data)


    const Faq = data.map(elem => {
        return (
            <tr>
                <td >
                    <div className='eleipiess'>  {elem?.question}</div>
                    {/* {elem?.question} */}
                </td>
                <td className="">
                    <p className="notboldd eleipiess"> {elem.answer}</p>
                </td>
                <td className=''>


                    <p className="notboldd">

                        {elem.createdAt.split('T')[0]}
                    </p></td>
                {Acls?.faq?.delete ? <td> <button className="buttons-remove" type="button" onClick={() => collection(elem.id)}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <g clip-path="url(#clip0_277_139950)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                            <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                        </g>
                        <defs>
                            <clipPath id="clip0_277_139950">
                                <rect width="22" height="22" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>




                </button></td> : ''}
                {Acls?.faq?.update ? <td> <Link to={'/admin/EditFaq/' + elem?.id}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M17.7506 6.14062H4.24769C3.83907 6.14062 3.50781 6.47185 3.50781 6.88043V18.4379C3.50781 20.4024 5.10608 22.0006 7.07041 22.0006H14.9276C16.892 22.0006 18.4903 20.4024 18.4903 18.4379V6.88043C18.4903 6.47188 18.1592 6.14062 17.7506 6.14062ZM9.22058 17.5446C9.22058 18.0466 8.81365 18.4535 8.31161 18.4535C7.80974 18.4535 7.40263 18.0466 7.40263 17.5446V10.5966C7.40263 10.0946 7.80967 9.68766 8.31161 9.68766C8.81362 9.68766 9.22058 10.0946 9.22058 10.5966V17.5446ZM14.5955 17.5446C14.5955 18.0466 14.1886 18.4535 13.6865 18.4535C13.1847 18.4535 12.7776 18.0466 12.7776 17.5446V10.5966C12.7776 10.0946 13.1846 9.68766 13.6865 9.68766C14.1885 9.68766 14.5955 10.0946 14.5955 10.5966V17.5446Z" fill="#D6D6D6" />
                        <path d="M17.0087 1.15112H13.7858V0.871624C13.7858 0.391003 13.3949 0 12.9143 0H9.08262C8.602 0 8.211 0.391003 8.211 0.871624V1.15112H4.98814C3.75406 1.15112 2.75 2.1551 2.75 3.38922V4.8244C2.75 5.08107 2.95807 5.28917 3.21484 5.28917H18.7821C19.0387 5.28917 19.2468 5.08107 19.2468 4.8244V3.38922C19.2468 2.15514 18.2429 1.15112 17.0087 1.15112Z" fill="#D6D6D6" />
                    </svg>


                </Link></td> : ''}
            </tr>
        )
    })

    useEffect(() => {
        GetFAQ()
    }, [])

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <section className="videos faq notifications card">
                    <div className="container-fluid">
                        <div class="table-responsive">
                            <table class="table mt-0 ">
                                <thead>
                                    <tr>
                                        <th>Questions</th>
                                        <th>Answers</th>
                                        <th >Date</th>
                                        {Acls?.faq?.delete ? <th>Remove</th> : ''}
                                        {Acls?.faq?.update ? <th>Edit</th> : ''}
                                    </tr>
                                </thead>
                                <tbody className="main-t-body-text" >
                                    {Faq ? Faq : ''}
                                </tbody>
                            </table>
                            {/* <ToastContainer style={{ fontSize: 20 }} /> */}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Faq;
