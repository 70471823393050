
import React, { useState, useEffect } from "react";
import './rewardlea.scss';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from 'utils/Environment';
import ReactPaginate from 'react-paginate';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
// reactstrap components
import { Link } from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import moment from "moment";
import { Pagination } from "react-bootstrap";

function LeaderBoard(props) {

    const [value, setValue] = useState([]);

    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [leader, setLeader] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [limit, setLimit] = useState(10);
    console.log(limit, "limit");
    const token = localStorage.getItem('mytoken')

    const handleSelectChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
    };

    // search ==========================
    const [searchTerm, setSearchTerm] = useState('');

    const searchsubmit = (e) => {
        setPage(0);
        getLeaderboard();
    }
    // search ==========================

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };


    const getLeaderboard = () => {
        setOpen(true);

        let date = '';
        let date1 = '';

        if (value && value.length === 2) {
            const a = moment(value[0]).toDate();
            const b = moment(value[1]).toDate();
            date = moment(a).utc(true).format('YYYY-MM-DD');
            date1 = moment(b).utc(true).format('YYYY-MM-DD');
        }

        axios.post(Environment.backendUrl + "/task/leaderboard", { limit: limit, page: page, startDate: date, endDate: date1, searchQuery: searchTerm }, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setLeader(response.data.board)
                //  varc= response.data.total[0]
                setPageCount(response.data.total[0]?.totalCount / limit);
                setPage(page)
                setOpen(false)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data?.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    console.log("pageee", pageCount)

    const mydata = leader.map(elem => {
        return (
            <>
                <tr>
                    <td>
                        <div className="mainimgdiv">
                            <div className="inerimgd">
                                <img src={elem.profile_image} className="tableimgginer">
                                </img>
                            </div>
                            <p className="darktextneww">
                                {elem.full_name}
                            </p>
                        </div>
                    </td>

                    <td className=''>
                        <p className="eleipiess darktextneww">
                            {elem?.address ? `${elem?.address?.substring(0, 6)} ... ${elem?.address?.substring(elem?.address.length - 6)}` : ""}
                        </p>
                    </td>

                    <td className=''>
                        <p className="eleipiess completed darktextneww">
                            {elem.completed == null ? 0 : elem.completed}
                        </p>
                    </td>

                    <td className=''>
                        <p className="eleipiess uncomplete darktextneww">
                            {elem.pending ? elem.pending : '0'}
                        </p>
                    </td>
                    <td className="button-details">
                        <Link className='btn-common padds' to={'/admin/leaderboardDetails/' + elem.id}>Details</Link>
                    </td>
                </tr>
            </>
        )
    })


    useEffect(() => {
        getLeaderboard();
    }, [page, value, limit]);

    const images = importAll(require.context('assets/img/userflow', false, /\.(png|jpe?g|svg)$/));

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} value={value} setValue={setValue} />

            {/* NEW Rewards / Leaderboard design */}

            <div className="content">
                <section className="daily leaderboard ptb20 card">
                    <div className="container-fluid">
                        <div className="innertable_leaderBoard">
                            <table>
                                <thead>
                                    <tr className="onlytablehadng">
                                        <th>Users Name
                                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                <path d="M2.4345 4.81539C2.4055 4.78714 2.2815 4.68047 2.1795 4.5811C1.538 3.99854 0.488 2.47881 0.1675 1.68339C0.116 1.56259 0.007 1.25718 0 1.09401C0 0.937652 0.036 0.788602 0.109 0.646371C0.211 0.46907 0.3715 0.326839 0.561 0.248904C0.6925 0.198734 1.086 0.120799 1.093 0.120799C1.5235 0.0428641 2.223 0 2.996 0C3.7325 0 4.4035 0.0428641 4.8405 0.106673C4.8475 0.11398 5.3365 0.191914 5.504 0.277155C5.81 0.433512 6 0.738919 6 1.06576V1.09401C5.9925 1.30687 5.8025 1.75451 5.7955 1.75451C5.4745 2.50706 4.476 3.99172 3.8125 4.58841C3.8125 4.58841 3.642 4.75645 3.5355 4.82952C3.3825 4.9435 3.193 5 3.0035 5C2.792 5 2.595 4.93619 2.4345 4.81539Z" fill="#030229" />
                                            </svg>
                                        </th>
                                        <th>Wallet Address
                                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                <path d="M2.4345 4.81539C2.4055 4.78714 2.2815 4.68047 2.1795 4.5811C1.538 3.99854 0.488 2.47881 0.1675 1.68339C0.116 1.56259 0.007 1.25718 0 1.09401C0 0.937652 0.036 0.788602 0.109 0.646371C0.211 0.46907 0.3715 0.326839 0.561 0.248904C0.6925 0.198734 1.086 0.120799 1.093 0.120799C1.5235 0.0428641 2.223 0 2.996 0C3.7325 0 4.4035 0.0428641 4.8405 0.106673C4.8475 0.11398 5.3365 0.191914 5.504 0.277155C5.81 0.433512 6 0.738919 6 1.06576V1.09401C5.9925 1.30687 5.8025 1.75451 5.7955 1.75451C5.4745 2.50706 4.476 3.99172 3.8125 4.58841C3.8125 4.58841 3.642 4.75645 3.5355 4.82952C3.3825 4.9435 3.193 5 3.0035 5C2.792 5 2.595 4.93619 2.4345 4.81539Z" fill="#030229" />
                                            </svg>
                                        </th>
                                        <th>Task Completed
                                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                <path d="M2.4345 4.81539C2.4055 4.78714 2.2815 4.68047 2.1795 4.5811C1.538 3.99854 0.488 2.47881 0.1675 1.68339C0.116 1.56259 0.007 1.25718 0 1.09401C0 0.937652 0.036 0.788602 0.109 0.646371C0.211 0.46907 0.3715 0.326839 0.561 0.248904C0.6925 0.198734 1.086 0.120799 1.093 0.120799C1.5235 0.0428641 2.223 0 2.996 0C3.7325 0 4.4035 0.0428641 4.8405 0.106673C4.8475 0.11398 5.3365 0.191914 5.504 0.277155C5.81 0.433512 6 0.738919 6 1.06576V1.09401C5.9925 1.30687 5.8025 1.75451 5.7955 1.75451C5.4745 2.50706 4.476 3.99172 3.8125 4.58841C3.8125 4.58841 3.642 4.75645 3.5355 4.82952C3.3825 4.9435 3.193 5 3.0035 5C2.792 5 2.595 4.93619 2.4345 4.81539Z" fill="#030229" />
                                            </svg>
                                        </th>
                                        <th>Tasks Pending
                                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                <path d="M2.4345 4.81539C2.4055 4.78714 2.2815 4.68047 2.1795 4.5811C1.538 3.99854 0.488 2.47881 0.1675 1.68339C0.116 1.56259 0.007 1.25718 0 1.09401C0 0.937652 0.036 0.788602 0.109 0.646371C0.211 0.46907 0.3715 0.326839 0.561 0.248904C0.6925 0.198734 1.086 0.120799 1.093 0.120799C1.5235 0.0428641 2.223 0 2.996 0C3.7325 0 4.4035 0.0428641 4.8405 0.106673C4.8475 0.11398 5.3365 0.191914 5.504 0.277155C5.81 0.433512 6 0.738919 6 1.06576V1.09401C5.9925 1.30687 5.8025 1.75451 5.7955 1.75451C5.4745 2.50706 4.476 3.99172 3.8125 4.58841C3.8125 4.58841 3.642 4.75645 3.5355 4.82952C3.3825 4.9435 3.193 5 3.0035 5C2.792 5 2.595 4.93619 2.4345 4.81539Z" fill="#030229" />
                                            </svg>
                                        </th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mydata ? mydata : ''}
                                </tbody>
                            </table>
                        </div>
                        {/* {pageCount >= 1 ?
                            <div className="w-100 d-flex justify-content-between">
                                <div className="select-item-drop">
                                    <select onChange={handleSelectChange} value={limit}>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={75}>75</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                                <div className="Paginationlattable gift-pagination position-relative">
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={5}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={page}
                                    ></ReactPaginate>
                                </div>
                            </div>
                            : ''} */}


{pageCount >= 1 ?
                            <>
                                <div className="Paginationlattable">
                                    <div className="select-item-drop">
                                        <select onChange={handleSelectChange} value={limit}>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={75}>75</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={page}
                                    ></ReactPaginate>
                                </div>
                            </>
                            : ''}
                    </div>
                </section>
            </div>
        </>
    );
}

export default LeaderBoard;
