import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';


const MysteryBoxesChart = ({ newUChartDates1, newUChartcounts1 }) => {
// console.log("rfrfr", newUChartDates1)

    const state = {
        series: [
            {
                name: 'Mystery Box',
                // data: [31, 40, 28, 51, 42, 109, 100]
                data: newUChartcounts1
            }
            // {
            //   name: 'series2',
            //   data: [11, 32, 45, 32, 34, 52, 41]
            // }
        ],
        options: {
            chart: {
                height: 350,
                type: 'bar'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                categories: newUChartDates1
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },

            plotOptions: {
                bar: {
                    columnWidth: '50%', // Adjust column width if needed
                    colors: {
                        Colors: ['#3654D6',]
                    }
                }
            }
        }
    }

    return (
        <>
            <ReactApexChart options={state?.options} series={state?.series} type="bar" height={350} />
        </>
    )
}

export default MysteryBoxesChart