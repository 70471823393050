import React, { useEffect, useState } from 'react'
import { Nav, Pagination } from 'react-bootstrap'
import './ambassadors.scss'
import { useWeb3React } from '@web3-react/core';
import useAuth from 'hooks/useAuth';
import { Backdrop, CircularProgress } from '@material-ui/core';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import axios from 'axios';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import ClaimAb from 'hooks/dataSenders/claim';
import { AmbassadorsClaimHelper } from 'utils/contractHelpers';
import useWeb3 from 'hooks/useWeb3';
import ReactPaginate from 'react-paginate';


const Claimambassadors = (props) => {
    console.log("🚀 ~ Claimambassadors ~ props: brandName", props?.className)

    const [activeTab, setActiveTab] = useState('link-1');


    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    // pagenition============================

    // Search =============
    const [searchTerm, setSearchTerm] = useState('');
    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
    }
    // Search =============



    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };

    const [activeTab1, setActiveTab1] = useState('link-1');

    const handleSelect1 = (eventKey) => {
        setActiveTab1(eventKey);
    };

    // start web3 ================================================================

    let { account } = useWeb3React();

    const { login, logout } = useAuth();
    const [ambassadorDetail, setAmbassadorDetail] = useState(false);


    const logoutHandle = async () => {
        const connectorId = window.localStorage.getItem("connectorId")
        logout(connectorId)
        localStorage.removeItem('connectorId')
        localStorage.removeItem('flag')
        localStorage.removeItem('chain')
    }

    const connectMetamask = async (e) => {
        // setLoader(true);
        console.log(e, 'eeeeeeeee');
        // handleClose();
        if (account) {
            const connectorId = window.localStorage.getItem("connectorId")
            await logout(connectorId);
            localStorage.removeItem("connectorId");
            localStorage.removeItem("flag");
        } else {
            await login("injected", e);
            localStorage.setItem("connectorId", "injected");
            localStorage.setItem("flag", "true");
            localStorage.setItem("chain", e);
            // setLoader(false);
        }
        // setLoader(false);
    };

    const trustWallet = async () => {
        // setLoader(true)
        console.log('startt trust.....');
        // handleClose();
        if (account) {
            await logout("walletconnect");
        } else {
            let a = await login("walletconnect");
            console.log(a, 'aaaaa');
            localStorage.setItem('connectorId', 'walletconnect');
            localStorage.setItem("flag", "true");
        }
        // setLoader(false);
    };


    const web3 = useWeb3();
    const [blance, setblance] = useState();

    const blFun = async () => {
        try {
            const blance = await web3.eth.getBalance(account);
            // const blance = await web3.eth.getBalance("0x6F3fDdF3B497caB73C10A314f63a72d8D9F89C1a");
            console.log("🚀 ~ blFun ~ blance:", blance / 10 ** 18)
            setblance(blance / 10 ** 18)
        } catch (error) {
            console.log("🚀 ~ blFun ~ error:", error)
        }
    }

    const contractAddress = Environment?.AmbassadorsClaim;
    const contract = AmbassadorsClaimHelper(contractAddress, web3);

    const claimApproveHook = async (address, type, amount) => {
        // console.log("🚀 ~ claim req in hook asd asd", address, type, amount)

        console.log('contract payload in hook', [address?.toString()], type == 'LGX' ? [0] : [amount], type == 'USDT' ? [0] : [amount]);

        let towayValue = web3.utils.toWei(amount.toString(), "ether");
        let gasPrice = await web3.eth.getGasPrice();
        gasPrice = parseInt(gasPrice) + 1000000000;

        try {
            // const response = await contract?.methods?.setClaimAddresses([address?.toString()], type == 'LGX' ? [0] : [amount], type == 'USDT' ? [0] : [amount])?.send({
            //     from: account
            // })
            const gas = await contract.methods.setClaimAddresses([address?.toString()], type == 'LGX' ? [0] : [towayValue], type == 'USDT' ? [0] : [towayValue]).estimateGas({ from: account });
            var response = await contract.methods.setClaimAddresses([address?.toString()], type == 'LGX' ? [0] : [towayValue], type == 'USDT' ? [0] : [towayValue])?.send({ gas: gas, gasPrice: gasPrice, from: account })

            return response
        } catch (error) {
            console.log("🚀 ~ file: placeABid.js:30 ~ error: accept a bid", error)
            throw error
        }
    }

    // claim ================================================================

    // const { claimApproveHook } = ClaimAb();
    const approveClaimAmount = async (ItemiD, address, type, amount) => {

        console.log(ItemiD, address, type, amount, 'asdasd');
        setOpen(true);

        try {
            const res = await claimApproveHook(address, type, amount);
            console.log("🚀 ~ approveClaimAmount ~ res:", res);
            if (res) {
                axios.post(Environment.backendUrl + `/ambassador/approveAmbassadorWithdrawal`, { id: ItemiD, trxHash: res?.transactionHash }, { headers: { "Authorization": `Bearer ${token}` } })
                    .then((response) => {
                        console.log("🚀 ~ .then ~ response: in contract res api", response)
                        // setAmbassadorsClaimListing(response?.data);
                        setOpen(false);
                        ambassadorsClaimListingHandle();
                        toast.success(response.data.msg, {
                            position: "top-center",
                            autoClose: 2000,
                        });
                    })
                    .catch((err) => {
                        setOpen(false);
                        toast.error(err.response?.data.msg, {
                            position: "top-center",
                            autoClose: 2000,
                        });
                    });
            }
            setOpen(false);
        } catch (error) {
            console.log("🚀 ~ approveClaimAmount ~ error:", error)
            setOpen(false);
        }
    }

    // claim ================================================================
    // start web3 ================================================================


    // web 2 =====================================================================

    let { id } = useParams();
    const token = localStorage.getItem('mytoken');

    const [open, setOpen] = useState(false);
    // Search =============

    // web 2 =====================================================================

    const changeDateFormate = (createdAt) => {
        console.log("🚀 ~ changeDateFormate ~ createdAt:", createdAt);
        const originalDate = new Date(createdAt);

        // Format the date and time
        const formattedDate = originalDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true, // Use 12-hour clock, you can set it to false for 24-hour clock
        });

        // Return the formatted date and time
        return formattedDate;
    }

    const ambassadorDetailHandle = () => {
        console.log('start ambassadorsClaimRewardsListing');
        setOpen(true);
        axios.get(Environment.backendUrl + `/ambassador/singleAmbassadorClaimDetails/${id}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                console.log("🚀 ~ .then ~ response: setAmbassadorDetail", response)
                setAmbassadorDetail(response?.data);
                setOpen(false);
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    }


    const [ambassadorsClaimListing, setAmbassadorsClaimListing] = useState(null);
    console.log("🚀 ~ Ambassadorsclaimrewards ~ ambassadorsClaimListing:", ambassadorsClaimListing)

    const ambassadorsClaimListingHandle = (status) => {
        console.log('start ambassadorsClaimRewardsListing');
        setOpen(true);
        axios.get(Environment.backendUrl + `/ambassador/ambassadorClaimListing?status=${status ? status : "pending"}&offset=0&limit=10&id=${id}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                console.log("🚀 ~ .then ~ response:", response)
                setAmbassadorsClaimListing(response?.data);
                setOpen(false);
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    }

    const claimDeniedHandle = (itemId) => {
        console.log('start ambassadorsClaimRewardsListing');
        setOpen(true);
        axios.patch(Environment.backendUrl + `/ambassador/deniedAmbassadorWithdrawal/${itemId}`, null, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                console.log("🚀 ~ .then ~ response: claimDeniedHandle", response)
                // setAmbassadorsClaimListing(response?.data);
                ambassadorsClaimListingHandle();
                setOpen(false);
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    }

    // amboassadors API ====================================================

    useEffect(() => {
        ambassadorsClaimListingHandle();
    }, [])

    useEffect(() => {
        blFun()
    }, [account])

    useEffect(() => {
        ambassadorDetailHandle();
    }, [])


    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className='content pb-0'>

                <div className="maintopdiv">
                    <div className="inerimgdss">
                        <img src={ambassadorDetail?.User?.profile_image} className="tableimgginerss">
                        </img>
                    </div>
                    <div className="inertopcolmm">
                        <p className="Usernnvame ">
                            User Name
                        </p>
                        <h5 className="ericccrown">
                            {ambassadorDetail?.User?.full_name}
                        </h5>
                    </div>
                    <div className="inertopcolmm">
                        <p className="Usernnvame">
                            Total Commission
                        </p>
                        <h5 className="ericccrown">
                            ${ambassadorDetail?.totalUsdEarned} + {ambassadorDetail?.totalLgxEarned} LGX
                        </h5>
                    </div>
                    <div className="inertopcolmm">
                        <p className="Usernnvame">
                            Approved withdrawal
                        </p>
                        <h5 className="ericccrown">
                            {/* $1,000 + 40,000 LGX */}
                            ${ambassadorDetail?.totalUsdApprove} + {ambassadorDetail?.totalLgxApprove} LGX
                        </h5>
                    </div>
                    <div className="inertopcolmm">
                        <p className="Usernnvame">
                            Pending amount
                        </p>
                        <h5 className="ericccrown">
                            {/* $500 + 10,000 LGX */}
                            ${ambassadorDetail?.totalUsdRemaining} + {ambassadorDetail?.totalLgxRemaining} LGX
                        </h5>
                    </div>
                    <div className="inertopcolmm">
                        <button type="button" className="buttonddred">Block User</button>
                    </div>
                </div>
                <div className='maintablea'>
                    <>
                        <div className="lowertabsss claimpillss">
                            <Nav variant="pills" activeKey={activeTab1} onSelect={handleSelect1} className='amberpillsoutersss'>
                                <Nav.Item className='amberitempilsss' onClick={() => ambassadorsClaimListingHandle("pending")}>
                                    <Nav.Link className='inerambss' eventKey="link-1">Pending</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='amberitempilsss' onClick={() => ambassadorsClaimListingHandle("approve")}>
                                    <Nav.Link className='inerambss' eventKey="link-2">Allowed</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='amberitempilsss' onClick={() => ambassadorsClaimListingHandle("denied")}>
                                    <Nav.Link className='inerambss' eventKey="link-3">Denied</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div className='ambnavlastbtn'>
                                {
                                    account ?
                                        <>
                                            <button className='auction-btn reject-btn' onClick={logoutHandle}>Disconnect</button>
                                            <p>{blance?.toLocaleString(4)}</p>
                                        </>
                                        :
                                        <>
                                            <button className='blueaddcategory auction-btn approve-btn' onClick={() => connectMetamask(97)}>Connect Wallet</button>
                                            {/* <button onClick={trustWallet}>Trust Wallet</button> */}
                                        </>
                                }
                                {/* <button className='alowgreen'>Allow Selected Claims</button>
                                <button className='rejetred'>Revoke Selected Claims</button> */}
                            </div>
                        </div>
                        {activeTab1 === 'link-1' && (
                            <>
                                <div className="innertable">
                                    <table>
                                        <thead>
                                            <th>
                                                <div className="namemain">
                                                    <label class="checkbox">
                                                        <input type="checkbox" />
                                                        <div class="checkbox-circle">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className='d-none innerbox'>
                                                                <rect x="0.910156" y="0.910156" width="10.1818" height="10.1818" rx="1" fill="#3654D6" />
                                                            </svg>
                                                        </div>
                                                    </label>
                                                    <p className="namepara">Wallet Address</p>
                                                </div>
                                            </th>

                                            <th>Type </th>
                                            <th>Status </th>
                                            <th>Claim Amount </th>
                                            <th>Date/Time  </th>
                                            <th>Action</th>

                                        </thead>
                                        <tbody>
                                            {
                                                ambassadorsClaimListing?.ambassadorClaimStatus?.claim?.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="namemain">
                                                                    {/* <label class="checkbox">
                                                                        <input type="checkbox" />
                                                                        <div class="checkbox-circle">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className='d-none innerbox'>
                                                                                <rect x="0.910156" y="0.910156" width="10.1818" height="10.1818" rx="1" fill="#3654D6" />
                                                                            </svg>
                                                                        </div>
                                                                    </label> */}
                                                                    <p className="namepara">{data?.walletAddress}</p>
                                                                </div>
                                                            </td>
                                                            <td>{data?.type} </td>
                                                            <td>{data?.status} </td>
                                                            <td>{data?.amount} {data?.type}</td>
                                                            <td>{data?.createdAt ? changeDateFormate(data?.createdAt) : ""}</td>
                                                            <td>
                                                                <button className="auction-btn reject-btn" onClick={() => claimDeniedHandle(data?.id)}>Reject</button>
                                                                <button className='auction-btn approve-btn' onClick={() => approveClaimAmount(data?.id, data?.walletAddress, data?.type, data?.amount)}>Approve</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {pageCount >= 1 ?
                                    <div className="Paginationlattable">
                                        <ReactPaginate
                                            previousLabel="Previous"
                                            nextLabel="Next"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            forcePage={page}
                                        ></ReactPaginate>
                                    </div>
                                    : ''}
                            </>
                        )}
                        {activeTab1 === 'link-2' && (
                            <>
                                <div className="innertable">
                                    <table>
                                        <thead>
                                            <th>
                                                <div className="namemain">
                                                    <label class="checkbox">
                                                        <input type="checkbox" />
                                                        <div class="checkbox-circle">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className='d-none innerbox'>
                                                                <rect x="0.910156" y="0.910156" width="10.1818" height="10.1818" rx="1" fill="#3654D6" />
                                                            </svg>
                                                        </div>
                                                    </label>
                                                    <p className="namepara">Wallet Address</p>
                                                </div>
                                            </th>

                                            <th>Type </th>
                                            <th>Status </th>
                                            <th>Claim Amount </th>
                                            <th>Date/Time  </th>
                                            {/* <th>Action</th> */}
                                        </thead>
                                        <tbody>
                                            {
                                                ambassadorsClaimListing?.ambassadorClaimStatus?.claim?.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="namemain">
                                                                    <label class="checkbox">
                                                                        <input type="checkbox" />
                                                                        <div class="checkbox-circle">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className='d-none innerbox'>
                                                                                <rect x="0.910156" y="0.910156" width="10.1818" height="10.1818" rx="1" fill="#3654D6" />
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                    <p className="namepara">{data?.walletAddress}</p>
                                                                </div>
                                                            </td>
                                                            <td>{data?.type} </td>
                                                            <td>{data?.status} </td>
                                                            <td>{data?.amount} {data?.type}</td>
                                                            <td>{data?.claimDate ? changeDateFormate(data?.claimDate) : ""}</td>
                                                            {/* <td>
                                                                <button>A Claim</button>
                                                            </td> */}
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {pageCount >= 1 ?
                                    <div className="Paginationlattable">
                                        <ReactPaginate
                                            previousLabel="Previous"
                                            nextLabel="Next"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            forcePage={page}
                                        ></ReactPaginate>
                                    </div>
                                    : ''}
                            </>
                        )}

                        {activeTab1 === 'link-3' && (
                            <>
                                <div className="innertable">
                                    <table>
                                        <thead>
                                            <th>
                                                <div className="namemain">
                                                    <label class="checkbox">
                                                        <input type="checkbox" />
                                                        <div class="checkbox-circle">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className='d-none innerbox'>
                                                                <rect x="0.910156" y="0.910156" width="10.1818" height="10.1818" rx="1" fill="#3654D6" />
                                                            </svg>
                                                        </div>
                                                    </label>
                                                    <p className="namepara">Wallet Address</p>
                                                </div>
                                            </th>

                                            <th>Type </th>
                                            <th>Status </th>
                                            <th>Claim Amount </th>
                                            <th>Date/Time  </th>
                                            <th>Action</th>
                                        </thead>
                                        <tbody>
                                            {
                                                ambassadorsClaimListing?.ambassadorClaimStatus?.claim?.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="namemain">
                                                                    {/* <label class="checkbox">
                                                                        <input type="checkbox" />
                                                                        <div class="checkbox-circle">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className='d-none innerbox'>
                                                                                <rect x="0.910156" y="0.910156" width="10.1818" height="10.1818" rx="1" fill="#3654D6" />
                                                                            </svg>
                                                                        </div>
                                                                    </label> */}
                                                                    <p className="namepara">{data?.walletAddress}</p>
                                                                </div>
                                                            </td>
                                                            <td>{data?.type} </td>
                                                            <td>{data?.status} </td>
                                                            <td>{data?.amount} {data?.type}</td>
                                                            <td>{data?.claimDate ? changeDateFormate(data?.claimDate) : ""}</td>
                                                            {/* <td>
                                                                <button>Claim</button>
                                                            </td> */}
                                                            <td>
                                                                <button onClick={() => claimDeniedHandle(data?.id)}>Reject</button>
                                                                <button className='success' onClick={() => approveClaimAmount(data?.id, data?.walletAddress, data?.type, data?.amount)}>Approve</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                {pageCount >= 1 ?
                                    <div className="Paginationlattable">
                                        <ReactPaginate
                                            previousLabel="Previous"
                                            nextLabel="Next"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            forcePage={page}
                                        ></ReactPaginate>
                                    </div>
                                    : ''}
                            </>
                        )}
                    </>


                </div>
            </div>
        </>
    )
}

export default Claimambassadors
