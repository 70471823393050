
import React, { useEffect, useState } from "react";
import './coin1.scss';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// reactstrap components
import { Link } from "react-router-dom";
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import ReactPaginate from "react-paginate";
import { Pagination } from "react-bootstrap";

function Rolemanagement(props) {

  const [calledAPI, setCalledAPI] = useState(false);

  const [myFiles, setMyFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const token = localStorage.getItem('mytoken')

  // search ==========================
  const [searchTerm, setSearchTerm] = useState('');
  const [limit] = useState(10);
  const [page, setPage] = useState(0);
  console.log("🚀 ~ Rolemanagement ~ page:", page, !calledAPI)
  const [pageCount, setPageCount] = useState(0);

  const searchsubmit = (e) => {
    setPage(0)
    setPage(0)
    // if (page == 0) {
    //   getAllUser()
    // }
  }
  // search ==========================

  const changeDateFormate = (createdAt) => {
    const originalDate = new Date(createdAt);

    // Format the date
    const formattedDate = originalDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    // Update the state with the formatted date
    return formattedDate;
  }


  const getAllRole = () => {
    setOpen(true)
    axios.get(Environment.backendUrl + `/rolesManagement/getRoles?limit=${limit}&offset=${page}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setMyFiles(response.data.roles);
        setPageCount(response.data.totalCount / limit);
        setOpen(false);

      }).catch((err) => {
        setOpen(false)
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      })
  }

  const close = () => {
    window.$('#exampleModal345').modal('hide')
  }
  const opeeennn = () => {
    window.$('#exampleModal345').modal('show')
  }


  // const News = myFiles?.map(elem => {
  //     return (
  //         <tr>
  //             <td >
  //                 {/* <ul className="list-inline"> */}
  //                 <li className="list-inline-item">{elem?.role_name}</li>
  //                 {/* </ul> */}
  //             </td>
  //             <td >
  //                 <li className="list-inline-item">{elem?.id}</li>
  //             </td>
  //             <td> <button className="buttons-remove" type="button" onClick={() => collection(elem.id)}><i className="far fa-trash-alt"></i></button></td>
  //             <td> <Link to={'/admin/editrolemanagement/' + elem?.id}><i class="fas fa-edit"></i></Link></td>

  //         </tr>
  //     )
  // })

  // const collection11=() =>{
  //     swal("Thanks for your rating!", `You rated us 3/3`, "success")
  // onClick={() => collection(elem.id)}
  // }

  const collection = (de) => {
    const ids = de
    setId(ids)
    opeeennn()
  }

  const deleteCoin = () => {
    close()
    setOpen(true)
    axios.get(Environment.backendUrl + "/rolesManagement/delete/" + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setOpen(false)
        toast.success(response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
        getAllRole()
        // setOpen(true)

      }).catch((err) => {
        setOpen(false)
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      })
  }


  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log("testing....", e, selectedPage, calledAPI);
    setCalledAPI(false);
    setPage(selectedPage);
  };


  useEffect(() => {
    if (!calledAPI) {
      getAllRole();
      setCalledAPI(true);
    }
  }, [page]);

  return (
    <>
      <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
      <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

      <div className="content">
        <section className="banner card">
          <div className="container-fluid">
            <div class="table-responsive">
              <table class="table mt-0">
                <thead>
                  <tr>
                    <th>Role Name</th>
                    {/* <th>Discription</th>8 */}
                    <th>Add Date</th>
                    {/* <th>PUBLISH</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="main-t-body-text">
                  {
                    myFiles?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td className="tdclasss">{data?.role_name}</td>
                          {/* <td className="tdclasss">Sed ut perspiciatis unde omnis iste natus error sit</td> */}
                          <td className="tdclasss">{changeDateFormate(data?.createdAt)}</td>
                          {/* <td className="tdclasss">
                            <div className="main-switch-nn ">
                              <div class="custom-control custom-switch ">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customSwitches"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customSwitches"
                                ></label>
                              </div>
                            </div>
                            <div className="main-switch-nn toglewithonly">
                              <div class="toggle">
                                <input type="checkbox" />
                                <label></label>
                              </div>
                            </div>
                          </td> */}
                          <td className="tdclasss">
                            {" "}
                            <Link to={`/admin/editrolemanagement/${data?.id}`}>
                              <button className="edittttbut">Edit</button>
                            </Link>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>



            
            <div className='Paginationlattable'>
              <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button>
              <Pagination>
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item active>{5}</Pagination.Item>
                <Pagination.Item>{6}</Pagination.Item>
              </Pagination>
              <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </button>



  
            </div>

              
            <div
              class="modal fade modal-zzz"
              id="exampleModal345"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog daily-profile-modal">
                <div class="modal-content daily-profile-modal-inner">
                  <div class="modal-body modal-body-main">
                    <div className="main-outter text-center">
                      <div className="row main-cardssss">
                        <div className="col-md-12 col-12">
                          <div className="awesm">
                            <i class="fas fa-exclamation-triangle"></i>
                          </div>
                          <div className="flux-b pt-3">
                            <h3>Are You Sure You Want to Delete This Role</h3>
                          </div>
                        </div>
                        <div className="col-md-12 col-12 ptb20">
                          <div className="button-modal-daily">
                            <button
                              type="button"
                              className="button-main-daily "
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className="button-main-dailys"
                              data-dismiss="modal"
                              aria-label="Close"
                              onChange={close}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Rolemanagement;
