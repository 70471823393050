import React, { useEffect, useState } from 'react'
import { Modal, Nav, Pagination } from 'react-bootstrap'
import './leaderboards.scss'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import { Backdrop, CircularProgress } from '@material-ui/core';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import ReactPaginate from 'react-paginate';

const Leaderboards = (props) => {

    const [activeTab, setActiveTab] = useState('link-1');

    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    const [activeTab1, setActiveTab1] = useState('link-1');

    const handleSelect1 = (eventKey) => {
        setActiveTab1(eventKey);
    }
    const token = localStorage.getItem('mytoken');
    const userRole = localStorage.getItem('myrole');
    const [open, setOpen] = useState(false);
    const [leaderBoardData, setLeaderBoardData] = useState([]);
    console.log("🚀 ~ Leaderboards ~ leaderBoardData:", leaderBoardData)
    const [allGamesData, setAllGamesData] = useState([]);
    const [calledAPI, setCalledAPI] = useState(false);
    const [tabsIndex, setTabsIndex] = useState(1);

    console.log(tabsIndex, 'check indexxx getSubGamesHandle ~ tabsInd')

    // search
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const changeDateFormate = (createdAt) => {
        const originalDate = new Date(createdAt);

        // Format the date
        const formattedDate = originalDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });

        // Update the state with the formatted date
        return formattedDate;
    }

    // Pagination

    // search 

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 

    const getLeaderBoardHandle = (setType) => {
        setOpen(true);
        axios.post(
            `${Environment.backendUrl}/reward/leaderBoardApis`,
            {
                filter: "Day",
                type: setType ? setType : "Bolts",
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            }
        )
            .then((response) => {
                console.log("🚀 ~ file: UserDetail.js:158 ~ .then ~ response:", response);
                setLeaderBoardData(response.data);
                setPageCount(response.data.total / limit);
                setOpen(false);
            })
            .catch((err) => {
                setLeaderBoardData([]);
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    };

    const getSubGamesHandle = (id, tabsInd) => {
        console.log("🚀 ~ getSubGamesHandle ~ tabsInd:", tabsInd, tabsIndex)
        setOpen(true);
        setTabsIndex(tabsInd);
        axios.post(`${Environment.backendUrl}/reward/leaderBoardApis`, { type: "Arcadia", gameId: id }, { headers: { "Authorization": `Bearer ${token}` }, })
            .then((response) => {
                console.log("🚀 ~ file: UserDetail.js:158 ~ .then ~ response: get All Games Handle", response);
                setLeaderBoardData(response?.data);
                setOpen(false);
            })
            .catch((err) => {
                // setLeaderBoardData([]);
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    };

    const getAllGamesHandle = () => {
        setOpen(true);
        axios.get(`${Environment.backendUrl}/reward/allGames`, { headers: { "Authorization": `Bearer ${token}` }, })
            .then((response) => {
                console.log("🚀 ~ file: UserDetail.js:158 ~ .then ~ response: get All Games Handle", response);
                setAllGamesData(response?.data)
                setOpen(false);
            })
            .catch((err) => {
                // setLeaderBoardData([]);
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    };

    useEffect(() => {
        if (!calledAPI) {
            getLeaderBoardHandle();
            setCalledAPI(true);
        }
    }, [calledAPI]);

    return (
        <>

            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>

            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />


            <div className='content pb-0'>
                <div className='maintablea_leader'>
                    <div className='maintablepills'>
                        <Nav variant="pills" activeKey={activeTab} onSelect={handleSelect} className='amberpillsouter_leader'>
                            <Nav.Item className='amberitempils' onClick={() => getLeaderBoardHandle("Bolts")}>
                                <Nav.Link className='ineramb' eventKey="link-1">
                                    Bolts
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='amberitempils' onClick={() => getLeaderBoardHandle("Invites")}>
                                <Nav.Link className='ineramb' eventKey="link-2">
                                    Invites
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='amberitempils' onClick={() => getLeaderBoardHandle("Followers")}>
                                <Nav.Link className='ineramb' eventKey="link-3">
                                    Followers
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='amberitempils' onClick={() => getAllGamesHandle()}>
                                <Nav.Link className='ineramb' eventKey="link-4">
                                    Arcadia
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='amberitempils' onClick={() => getLeaderBoardHandle("Staking")}>
                                <Nav.Link className='ineramb' eventKey="link-5">
                                    Staking
                                </Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </div>



                    {activeTab === 'link-1' && (
                        <>
                            <div className="innertable  table-responsive">
                                <table>
                                    <thead>
                                        <th>Rank</th>
                                        <th>Users Name</th>
                                        <th> Date</th>
                                        <th>Bolts </th>

                                    </thead>
                                    <tbody>
                                        {
                                            leaderBoardData?.boltsLeaderBoard?.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <div className="mainimgdivvid ">
                                                                <div className="inerimgdvide">
                                                                    <img src={data?.User?.profile_image} className="tableimgginerddvidd">
                                                                    </img>
                                                                </div>
                                                                <p className="tableimgtext">
                                                                    {data?.User?.full_name}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>02-Nov-2023</td>
                                                        <td>
                                                            <span className="eleipiess">
                                                                {data?.totalBolts}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {pageCount >= 1 ?
                                <div className="text-center">
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={page}
                                    ></ReactPaginate>
                                </div>
                                : ''}
                        </>
                    )}
                    {activeTab === 'link-2' && (
                        <>
                            <div className="innertable  table-responsive">
                                <table>
                                    <thead>
                                        <th>Rank</th>
                                        <th>Users Name</th>
                                        <th> Date</th>
                                        <th>Bolts </th>

                                    </thead>
                                    <tbody>
                                        {
                                            leaderBoardData?.invites?.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <div className="mainimgdivvid ">
                                                                <div className="inerimgdvide">
                                                                    <img src={data?.referrer?.profile_image} className="tableimgginerddvidd">
                                                                    </img>
                                                                </div>
                                                                <p className="tableimgtext">
                                                                    {data?.referrer?.full_name}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>02-Nov-2023</td>
                                                        <td>
                                                            <span className="eleipiess">
                                                                {data?.numberOfInvites}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>
                            {pageCount >= 1 ?
                                <div className="text-center">
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={page}
                                    ></ReactPaginate>
                                </div>
                                : ''}
                        </>
                    )}
                    {activeTab === 'link-3' && (
                        <>
                            <div className="innertable  table-responsive">
                                <table>
                                    <thead>
                                        <th>Rank</th>
                                        <th>Users Name</th>
                                        <th> Date</th>
                                        <th>Bolts </th>

                                    </thead>
                                    <tbody>
                                        {
                                            leaderBoardData?.Follow?.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <div className="mainimgdivvid ">
                                                                <div className="inerimgdvide">
                                                                    <img src={data?.follow?.profile_image} className="tableimgginerddvidd">
                                                                    </img>
                                                                </div>
                                                                <p className="tableimgtext">
                                                                    {data?.follow?.full_name}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>02-Nov-2023</td>
                                                        <td>
                                                            <span className="eleipiess">
                                                                {data?.numberOfInvites}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {pageCount >= 1 ?
                                <div className="text-center">
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={page}
                                    ></ReactPaginate>
                                </div>
                                : ''}
                        </>
                    )}
                    {activeTab === 'link-4' && (
                        <>
                            <div className="lowertabsss claimpillss">
                                {/* <Nav variant="pills" activeKey={activeTab1} onSelect={handleSelect1} className='amberpillsoutersss'>
                                    {
                                        allGamesData?.games?.map((data, index) => {
                                            return (
                                                <Nav.Item className='amberitempilsss' key={index} onClick={() => getSubGamesHandle(data?.id, index)}>
                                                    <Nav.Link className='inerambss' eventKey={tabsIndex}>{data?.name}</Nav.Link>
                                                </Nav.Item>
                                            )
                                        })
                                    }
                                   
                                </Nav> */}
                                <div className='amberpillsoutersss my-new-tabss'>
                                    {
                                        allGamesData?.games?.map((data, index) => {
                                            return (
                                                <a className={tabsIndex == index + 1 ? `${tabsIndex} amberitempilsss active` : 'amberitempilsss'} key={index} onClick={() => getSubGamesHandle(data?.id, index + 1)}>
                                                    <a className='inerambss'>{data?.name}</a>
                                                </a>
                                            )
                                        })
                                    }
                                    {/* <Nav.Item className='amberitempilsss' onClick={() => getSubGamesHandle("Knife Strike")}>
                                        <Nav.Link className='inerambss' eventKey="link-55">Knife Strike</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='amberitempilsss' onClick={() => getSubGamesHandle("Block Shooter")}>
                                        <Nav.Link className='inerambss' eventKey="link-66">Block Shooter</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='amberitempilsss' onClick={() => getSubGamesHandle("Fruit Warrior")}>
                                        <Nav.Link className='inerambss' eventKey="link-77">Fruit Warrior</Nav.Link>
                                    </Nav.Item> */}
                                </div>

                            </div>
                            {/* {tabsIndex} 11111111111111111111111 */}
                            {tabsIndex ? (
                                <>
                                    <div className="innertable  table-responsive">
                                        <table>
                                            <thead>
                                                <th>Rank</th>
                                                <th>Users Name</th>
                                                <th> Date</th>
                                                <th>Score </th>

                                            </thead>
                                            <tbody>

                                                {
                                                    leaderBoardData?.leaderboard?.map((data, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <div className="mainimgdivvid ">
                                                                        <div className="inerimgdvide">
                                                                            <img src={data?.profile_image} className="tableimgginerddvidd">
                                                                            </img>
                                                                        </div>
                                                                        <p className="tableimgtext">
                                                                            {data?.full_name}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>02-Nov-2023</td>
                                                                <td>
                                                                    <span className="eleipiess">
                                                                        {data?.score?.toLocaleString()}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>

                                    </div>
                                    {pageCount >= 1 ?
                                        <div className="text-center">
                                            <ReactPaginate
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={page}
                                            ></ReactPaginate>
                                        </div>
                                        : ''}
                                </>
                            )
                                : ""}
                            {activeTab1 === 'link-021451545' && (
                                <>
                                    <div className="innertable  table-responsive">
                                        <table>
                                            <thead>
                                                <th>Rank</th>
                                                <th>Users Name adsfsaf</th>
                                                <th> Date</th>
                                                <th>Score </th>

                                            </thead>
                                            <tbody>

                                                {
                                                    leaderBoardData?.leaderboard?.map((data, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <div className="mainimgdivvid ">
                                                                        <div className="inerimgdvide">
                                                                            <img src={data?.profile_image} className="tableimgginerddvidd">
                                                                            </img>
                                                                        </div>
                                                                        <p className="tableimgtext">
                                                                            {data?.full_name}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>02-Nov-2023</td>
                                                                <td>
                                                                    <span className="eleipiess">
                                                                        {data?.score?.toLocaleString()}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>

                                    </div>
                                    {pageCount >= 1 ?
                                        <div className="text-center">
                                            <ReactPaginate
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={page}
                                            ></ReactPaginate>
                                        </div>
                                        : ''}
                                </>
                            )}
                            {activeTab1 === 'link-66' && (
                                <>
                                    <div className="innertable  table-responsive">
                                        <table>
                                            <thead>
                                                <th>Rank</th>
                                                <th>Users Name</th>
                                                <th> Date</th>
                                                <th>Bolts </th>

                                            </thead>
                                            <tbody>

                                                {
                                                    leaderBoardData?.map((data, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <div className="mainimgdivvid ">
                                                                        <div className="inerimgdvide">
                                                                            <img src={data?.User?.profile_image} className="tableimgginerddvidd">
                                                                            </img>
                                                                        </div>
                                                                        <p className="tableimgtext">
                                                                            {data?.User?.full_name}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>02-Nov-2023</td>
                                                                <td>
                                                                    <span className="eleipiess">
                                                                        {data?.totalBolts}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>

                                    </div>
                                    {pageCount >= 1 ?
                                        <div className="text-center">
                                            <ReactPaginate
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={page}
                                            ></ReactPaginate>
                                        </div>
                                        : ''}
                                </>
                            )}
                            {activeTab1 === 'link-77' && (
                                <>
                                    <div className="innertable  table-responsive">
                                        <table>
                                            <thead>
                                                <th>Rank</th>
                                                <th>Users Name</th>
                                                <th> Date</th>
                                                <th>Bolts </th>

                                            </thead>
                                            <tbody>

                                                {
                                                    leaderBoardData?.map((data, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <div className="mainimgdivvid ">
                                                                        <div className="inerimgdvide">
                                                                            <img src={data?.User?.profile_image} className="tableimgginerddvidd">
                                                                            </img>
                                                                        </div>
                                                                        <p className="tableimgtext">
                                                                            {data?.User?.full_name}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>02-Nov-2023</td>
                                                                <td>
                                                                    <span className="eleipiess">
                                                                        {data?.totalBolts}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>

                                    </div>
                                    {pageCount >= 1 ?
                                        <div className="text-center">
                                            <ReactPaginate
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={page}
                                            ></ReactPaginate>
                                        </div>
                                        : ''}
                                </>
                            )}


                        </>
                    )}
                    {activeTab === 'link-5' && (
                        <>
                            {/* <div className="lowertabsss claimpillss">
                                <Nav variant="pills" activeKey={activeTab1} onSelect={handleSelect1} className='amberpillsoutersss'>
                                    <Nav.Item className='amberitempilsss'>
                                        <Nav.Link className='inerambss' eventKey="link-444">Hoop Shot</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='amberitempilsss'>
                                        <Nav.Link className='inerambss' eventKey="link-555">Knife Strike</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='amberitempilsss'>
                                        <Nav.Link className='inerambss' eventKey="link-666">Block Shooter</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='amberitempilsss'>
                                        <Nav.Link className='inerambss' eventKey="link-777">Fruit Warrior</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div> */}
                            <div className="innertable  table-responsive">
                                <table>
                                    <thead>
                                        <th>Rank</th>
                                        <th>Users Name</th>
                                        {/* <th>Date</th> */}
                                        <th>Staked LGX</th>
                                    </thead>
                                    <tbody>

                                        {
                                            leaderBoardData?.stakingData?.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <div className="mainimgdivvid ">
                                                                <div className="inerimgdvide">
                                                                    <img src={data?.User?.profile_image} className="tableimgginerddvidd">
                                                                    </img>
                                                                </div>
                                                                <p className="tableimgtext">
                                                                    {data?.User?.full_name}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>02-Nov-2023</td>
                                                        <td>
                                                            <span className="eleipiess">
                                                                {/* {data?.totalLgxStaked?.toLocaleString()} */}
                                                                {parseInt(data?.totalLgxStaked).toLocaleString()}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            {pageCount >= 1 ?
                                <div className="text-center">
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={page}
                                    ></ReactPaginate>
                                </div>
                                : ''}
                            {/* {activeTab1 === 'link-444' && (
                                <>
                                    
                                </>
                            )} */}
                            {activeTab1 === 'link-555' && (
                                <>
                                    <div className="innertable  table-responsive">
                                        <table>
                                            <thead>
                                                <th>Rank</th>
                                                <th>Users Name</th>
                                                <th> Date</th>
                                                <th>Score </th>

                                            </thead>
                                            <tbody>

                                                {
                                                    leaderBoardData?.map((data, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <div className="mainimgdivvid ">
                                                                        <div className="inerimgdvide">
                                                                            <img src={data?.User?.profile_image} className="tableimgginerddvidd">
                                                                            </img>
                                                                        </div>
                                                                        <p className="tableimgtext">
                                                                            {data?.User?.full_name}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>02-Nov-2023</td>
                                                                <td>
                                                                    <span className="eleipiess">
                                                                        {data?.totalBolts}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>

                                    </div>
                                    {pageCount >= 1 ?
                                        <div className="text-center">
                                            <ReactPaginate
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={page}
                                            ></ReactPaginate>
                                        </div>
                                        : ''}
                                </>
                            )}
                            {activeTab1 === 'link-666' && (
                                <>
                                    <div className="innertable  table-responsive">
                                        <table>
                                            <thead>
                                                <th>Rank</th>
                                                <th>Users Name</th>
                                                <th> Date</th>
                                                <th>Bolts </th>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>


                                            </tbody>

                                        </table>

                                    </div>
                                    {pageCount >= 1 ?
                                        <div className="text-center">
                                            <ReactPaginate
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={page}
                                            ></ReactPaginate>
                                        </div>
                                        : ''}
                                </>
                            )}
                            {activeTab1 === 'link-777' && (
                                <>
                                    <div className="innertable  table-responsive">
                                        <table>
                                            <thead>
                                                <th>Rank</th>
                                                <th>Users Name</th>
                                                <th> Date</th>
                                                <th>Bolts </th>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1</td>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src="\users-assets\admin-img.png" className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                Carolyn Wilson
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>02-Nov-2023</td>
                                                    <td>
                                                        <span className="eleipiess">
                                                            187,254
                                                        </span>
                                                    </td>
                                                </tr>


                                            </tbody>

                                        </table>

                                    </div>
                                    <div className='Paginationlattable'>
                                        <button className='leftpigbtn' >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            Prev
                                        </button>
                                        <Pagination>
                                            <Pagination.Item>{1}</Pagination.Item>
                                            <Pagination.Item>{2}</Pagination.Item>
                                            <Pagination.Ellipsis />
                                            <Pagination.Item active>{5}</Pagination.Item>
                                            <Pagination.Item>{6}</Pagination.Item>
                                        </Pagination>
                                        <button className='leftpigbtn' >
                                            Next
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>

                                        </button>
                                    </div>
                                </>
                            )}

                        </>
                    )}
                </div>
            </div>


        </>
    )
}

export default Leaderboards
