
export const Chain = [
    {
        item: 'Rewards'
    },
    {
        item: 'Play To Earn',
    },
    {
        item: 'Empower',
    },
    {
        item: 'Referral',
    },
    {
        item: 'Buy LGX',
    },
] 

export default Chain;