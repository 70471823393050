import React, { useEffect, useState } from 'react'
import { Nav, Pagination } from 'react-bootstrap'
import './ambassadors.scss'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Backdrop, CircularProgress } from '@material-ui/core';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import useAuth from 'hooks/useAuth';
import { useWeb3React } from '@web3-react/core/dist';
import { toast } from 'react-toastify';
import Environment from 'utils/Environment';
import axios from 'axios';
import ReactPaginate from 'react-paginate';

const Ambassadorsclaimrewards = (props) => {

    const token = localStorage.getItem('mytoken');

    const [activeTab, setActiveTab] = useState('link-1');

    const [open, setOpen] = useState(false);

    // const [limit] = useState(10);
    // const [page, setPage] = useState(0);
    // pagenition============================

    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    // pagenition============================

    // Search =============
    const [searchTerm, setSearchTerm] = useState('');
    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
    }
    // Search =============



    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    const [activeTab1, setActiveTab1] = useState('link-1');

    const handleSelect1 = (eventKey) => {
        setActiveTab1(eventKey);
    };

    // amboassadors API ====================================================

    // http://localhost:3000/admin/ambassador/ambassadorsClaimRewardsListing?offset=0&limit=10
    const [ambassadorsClaimListing, setAmbassadorsClaimListing] = useState(null);
    console.log("🚀 ~ Ambassadorsclaimrewards ~ ambassadorsClaimListing:", ambassadorsClaimListing)

    const ambassadorsClaimListingHandle = () => {
        console.log('start ambassadorsClaimRewardsListing');
        setOpen(true);
        axios.get(Environment.backendUrl + `/ambassador/ambassadorsClaimRewardsListing`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setAmbassadorsClaimListing(response?.data?.ambassadors);
                setOpen(false);
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    }

    // amboassadors API ====================================================
    useEffect(() => {
        ambassadorsClaimListingHandle();
    }, [])

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className='content'>
                <div className='maintablea'>
                    <div className='maintablepills claimpillss onlyforrewards'>

                        <div className='ambnavlastbtn'>
                            {/* {
                                account ?
                                    <>
                                        <button onClick={logoutHandle}>Disconnect</button>
                                    </>
                                    :
                                    <>
                                        <button onClick={connectMetamask}>Connect Wallet</button>
                                        <button onClick={trustWallet}>Trust Wallet</button>
                                    </>
                            } */}
                            {/* <button className='alowgreen disabled'>Allow Selected Claims</button>
                            <button disabled className='rejetred'>Revoke Selected Claims</button> */}
                        </div>
                    </div>
                    <>
                        <div className="innertable">
                            <table>
                                <thead>
                                    <th>
                                        <div className="namemain">
                                            {/* <label class="checkbox">
                                                <input type="checkbox" />
                                                <div class="checkbox-circle">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className='d-none innerbox'>
                                                        <rect x="0.910156" y="0.910156" width="10.1818" height="10.1818" rx="1" fill="#3654D6" />
                                                    </svg>
                                                </div>
                                            </label> */}
                                            <p className="namepara">Ambassadors</p>
                                        </div>
                                    </th>
                                    <th>Total Commission </th>
                                    <th>Approved withdrawal</th>
                                    <th>Pending amount </th>
                                    <th>Action </th>
                                </thead>
                                <tbody>
                                    {
                                        ambassadorsClaimListing?.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="namemain">
                                                            {/* <label class="checkbox">
                                                                <input type="checkbox" />
                                                                <div class="checkbox-circle">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className='d-none innerbox'>
                                                                        <rect x="0.910156" y="0.910156" width="10.1818" height="10.1818" rx="1" fill="#3654D6" />
                                                                    </svg>
                                                                </div>
                                                            </label> */}
                                                            <p className="namepara">{data?.User?.full_name}</p>
                                                        </div>
                                                    </td>
                                                    <td>$ {data?.totalUsdEarned ? data?.totalUsdEarned : "0"} + {data?.totalLgxEarned ? data?.totalLgxEarned : "0"} LGX  </td>
                                                    <td>$ {data?.totalUsdApprove ? data?.totalUsdApprove : "0"} + {data?.totalLgxApprove ? data?.totalLgxApprove : "0"} LGX   </td>
                                                    <td>$ {data?.totalUsdRemaining ? data?.totalUsdRemaining : "0"} + {data?.totalLgxRemaining ? data?.totalLgxRemaining : "0"} LGX  </td>

                                                    <td>
                                                        <Link to={`/admin/claimambassadors/${data?.user_id}`}>
                                                            <button className="detailbtn" >View Detail</button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                        </div>
                        <div className='Paginationlattable'>
                            {pageCount >= 1 ?
                                <div className="Paginationlattable">
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={page}
                                    ></ReactPaginate>
                                </div>
                                : ''}
                            {/* <button className='leftpigbtn' >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                Prev
                            </button>
                            <Pagination>
                                <Pagination.Item>{1}</Pagination.Item>
                                <Pagination.Item>{2}</Pagination.Item>
                                <Pagination.Ellipsis />
                                <Pagination.Item active>{5}</Pagination.Item>
                                <Pagination.Item>{6}</Pagination.Item>
                            </Pagination>
                            <button className='leftpigbtn' >
                                Next
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            </button> */}
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}

export default Ambassadorsclaimrewards
