
import React from "react";
import './games.scss';
// reactstrap components
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
function DailyLeaderBoard() {

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const images = importAll(require.context('assets/img/userflow', false, /\.(png|jpe?g|svg)$/));
    return (
        <>
            <div className="content">
                <section className="daily daily-leader-board ptb20 card">
                    <div className="container-fluid">
                        <div className="leaderporttop">

                            <h3>Daily Leaderboard</h3>
                            <Dropdown className="amer_dropdonfst">
                                <Dropdown.Toggle id="dropdown-basic amer_dropdonfstdd">
                                    Sort by
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="mydropppp">
                                    <Dropdown.Item href="#/action-1" className="namediv">Name</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2" className="namediv">Join Date</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3" className="namediv">Verification Status</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3" className="namediv">Referrals</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>


                        </div>


                    </div>
                    <div class="table-responsive">
                        <table class="table mt-0 ">
                            <thead>
                                <tr>
                                    <th>Users Name
                                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-1" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                            <g opacity="0.7">
                                                <path d="M2.4345 4.81539C2.4055 4.78714 2.2815 4.68047 2.1795 4.5811C1.538 3.99854 0.488 2.47881 0.1675 1.68339C0.116 1.56259 0.007 1.25718 0 1.09401C0 0.937652 0.036 0.788602 0.109 0.646371C0.211 0.46907 0.3715 0.326839 0.561 0.248904C0.6925 0.198734 1.086 0.120799 1.093 0.120799C1.5235 0.0428641 2.223 0 2.996 0C3.7325 0 4.4035 0.0428641 4.8405 0.106673C4.8475 0.11398 5.3365 0.191914 5.504 0.277155C5.81 0.433512 6 0.738919 6 1.06576V1.09401C5.9925 1.30687 5.8025 1.75451 5.7955 1.75451C5.4745 2.50706 4.476 3.99172 3.8125 4.58841C3.8125 4.58841 3.642 4.75645 3.5355 4.82952C3.3825 4.9435 3.193 5 3.0035 5C2.792 5 2.595 4.93619 2.4345 4.81539Z" fill="#030229" />
                                            </g>
                                        </svg>

                                    </th>
                                    <th>Country</th>
                                    <th>Wallet Address    <svg xmlns="http://www.w3.org/2000/svg" className="ml-1" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                        <g opacity="0.7">
                                            <path d="M2.4345 4.81539C2.4055 4.78714 2.2815 4.68047 2.1795 4.5811C1.538 3.99854 0.488 2.47881 0.1675 1.68339C0.116 1.56259 0.007 1.25718 0 1.09401C0 0.937652 0.036 0.788602 0.109 0.646371C0.211 0.46907 0.3715 0.326839 0.561 0.248904C0.6925 0.198734 1.086 0.120799 1.093 0.120799C1.5235 0.0428641 2.223 0 2.996 0C3.7325 0 4.4035 0.0428641 4.8405 0.106673C4.8475 0.11398 5.3365 0.191914 5.504 0.277155C5.81 0.433512 6 0.738919 6 1.06576V1.09401C5.9925 1.30687 5.8025 1.75451 5.7955 1.75451C5.4745 2.50706 4.476 3.99172 3.8125 4.58841C3.8125 4.58841 3.642 4.75645 3.5355 4.82952C3.3825 4.9435 3.193 5 3.0035 5C2.792 5 2.595 4.93619 2.4345 4.81539Z" fill="#030229" />
                                        </g>
                                    </svg></th>
                                    <th>Points</th>
                                    <th>Position</th>
                                    <th>Reward Sent</th>
                                    <th>Reminder</th>
                                </tr>
                            </thead>
                            <tbody className="main-t-body-text" >
                                <tr>
                                    <td className='main-image'>
                                        <Link className=''> <img src={`${images['gamess1.png']['default']}`} className="pr-2 imgages-nonew" alt="" /></Link>
                                        <span>Carolyn Wilson</span>
                                    </td>
                                    <td className='main-image'>
                                        <Link className=''> <img src={`${images['iran.png']['default']}`} className="pr-2 imgages-nonew" alt="" /></Link>
                                    </td>
                                    <td className=''>
                                        
                                    <p className="eleipiess">
                                        0x0712770x0712775C435C43...</p></td>
                                        <td className=''>
                                        
                                        <p className="eleipiess">
                                        49602</p></td>
                                    <td className='main-image'>
                                        <Link className=''> <img src={`${images['batch1.png']['default']}`} className="pr-2 imgages-nonew" alt="" /></Link>
                                    </td>
                                    <td className="">
                                        <Link className=''> <img src={`${images['greentick.png']['default']}`} className="pr-2 imgages-nonew" alt="" /></Link>
                                    </td>
                                    <td className="">
                                        <Link className=''>Send Reminder</Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </>
    );
}

export default DailyLeaderBoard;
