
import React, { useEffect, useState } from "react";
import './games.scss';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from "react-router-dom";
// reactstrap components
import { Link } from "react-router-dom";
import Onlydailychalgametable from "./Onlydailychalgametable";
import DemoNavbar from "components/Navbars/DemoNavbar.js";


const AddDaily = (props) => {

    const [open, setOpen] = useState(false);
    const [selectedImg1, setSelectedImg1] = useState();
    const history = useHistory();
    const [selectedImg2, setSelectedImg2] = useState();
    const [selectedImg3, setSelectedImg3] = useState();
    const id = props.match.params.id;
    const token = localStorage.getItem('mytoken')
    const [myFiles, setMyFiles] = useState();
    const [myFiles1, setMyFiles1] = useState();
    const [myFiles2, setMyfiles2] = useState();
    const [selectedImg_new, setSelectedImg_new] = useState();
    console.log("🚀 ~ AddDaily ~ selectedImg_new:", myFiles, selectedImg_new)
    const [inputs, setInputs] = useState([
        {
            name: '',
            description: '',
            first: '',
            second: '',
            third: '',
            fourth: ''
        }
    ]);
    const { name, description, first, second, third, fourth } = inputs;

    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const getdata = () => {
        setOpen(true)
        axios.get(Environment.backendUrl + "/game/find/" + id, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setOpen(false)
                setInputs(response.data.game)
                setSelectedImg1(response.data.game.horizontalImage)
                setSelectedImg2(response.data.game.verticalImage)
                setSelectedImg3(response.data.game.roundImage)
                setSelectedImg_new(response.data.game.horizontalImage)
                // setOpen(true)

            }).catch((err) => {
                setOpen(false)
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const handleFileSelect = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg1(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles(file)
    }
    const handleFileSelect1 = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg2(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles1(file)
    }
    const handleFileSelect2 = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg3(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyfiles2(file)
    }
    const handleFileSelect3 = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));

            setSelectedImg_new(filesarray[0]);
            // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles(file)
    }


    const editDaily = async (event) => {
        setOpen(true)
        event.preventDefault()
        const data = new FormData();
        if (myFiles) {
            data.append("horizontalImage", myFiles)
        }
        if (myFiles1) {
            data.append("verticalImage", myFiles1)
        }
        if (myFiles2) {
            data.append("roundImage", myFiles2)
        }

        data.append("name", inputs.name)
        data.append("description", inputs.description)
        data.append("first", inputs.first)
        data.append("second", inputs.second)
        data.append("third", inputs.third)
        data.append("fourth", inputs.fourth)
        data.append("id", id)
        axios.post(Environment.backendUrl + "/game/edit/dailyChallenge", data, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                setOpen(false)
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
                setInputs({
                    name: '',
                    description: '',
                    first: '',
                    second: '',
                    third: '',
                    fourth: ''
                })
                setSelectedImg1('');
                setSelectedImg2('');
                setSelectedImg3('');
                history.push('/admin/dailychallegne')
                // console.log(response)
                // setOpen(true)
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }


    useEffect(() => {
        if (id) {
            getdata()
        }

    }, [token])

    const renderPhotos = (source) => {
        return <img src={source} alt="" width="200" height="200" />
    }
    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <section className="daily card">
                    <div className="inner-submit-lower-div">
                        <div class="row">
                            <div class="col-md-8 order-md-0 order-1">
                                <div className="row">
                                    <div className="row">
                                        <div className="col-12">
                                            <div class="form-group">
                                                <label for="example">Game Title</label>
                                                <input type="text" name="name" value={id ? inputs.name : name} className='form-control' onChange={handleChange1} placeholder="Enter title of the game" />
                                            </div>
                                        </div>
                                        <div className="col-12 ">
                                            <div class="form-group">
                                                <label className="padd-top" for="example">Game Description</label>
                                                <textarea name="description" value={id ? inputs.description : description} onChange={handleChange1} className='form-control' placeholder="Enter description of the game" rows="5"></textarea>

                                            </div>
                                        </div>
                                        {/* <div className="col-12 ">
                                                <div class="form-group">
                                                    <label className="padd-top ptb20" for="example">Game Thumbnail</label>
                                                    <div className="main-image-div">
                                                        <label for="example">Horizontal Image</label>
                                                        <div className="choose-filessss yoyoyo">
                                                            <small> {selectedImg1 ? selectedImg1 : null}</small>
                                                            <form> <input type="file" name="horizontalImage" onChange={handleFileSelect} accept="image/*" className='form-control' />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 ">
                                                <div class="form-group">
                                                    <div className="main-image-div">
                                                        <label for="example">Vertical Image</label>
                                                        <div className="choose-filessss yoyoyo">
                                                            <small> {selectedImg2 ? selectedImg2 : null}</small>
                                                            <form>
                                                                <input type="file" name="verticalImage" onChange={handleFileSelect1} accept="image/*" className='form-control' />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 ">
                                                <div class="form-group">
                                                    <div className="main-image-div">
                                                        <label for="example">Round Image</label>
                                                        <div className="choose-filessss yoyoyo">
                                                            <small> {selectedImg3 ? selectedImg3 : null}</small>
                                                            <form>  <input type="file" name="roundImage" onChange={handleFileSelect2} accept="image/*" className='form-control' />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group choose-category">
                                            <label class="form-check-label" for="exampleRadios1">
                                                Daily Challenge Reward
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-sm-3">
                                        <div class="form-group rewardsss">
                                            <label for="example">1st Position</label>
                                            <input type="text" name="first" value={id ? inputs.first : first} onChange={handleChange1} className='form-control' placeholder="Reward" />

                                        </div>
                                    </div>

                                    <div className="col-sm-3">
                                        <div class="form-group rewardsss">
                                            <label for="example">2nd Position</label>
                                            <input type="text" name="second" value={id ? inputs.second : second} onChange={handleChange1} className='form-control' placeholder="Reward" />

                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div class="form-group rewardsss">
                                            <label for="example">3rd Position</label>
                                            <input type="text" name="third" value={id ? inputs.third : third} onChange={handleChange1} className='form-control' placeholder="Reward" />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div class="form-group rewardsss">
                                            <label for="example">4th Position</label>
                                            <input type="text" name="fourth" value={id ? inputs.fourth : fourth} onChange={handleChange1} className='form-control' placeholder="Reward" />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group">
                                            <div className="videopage">
                                                {id ? <button className="red-b" onClick={editDaily}>Save</button> : <button className="red-b"  >Publish</button>}
                                                <Link to="/admin/dailychallegne">
                                                    <button className="red-w">Cancel</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 order-md-1 order-0">
                                <div class="form-group rightside-for-group">
                                    <label for="exampleInputsymbol"> Upload Game</label>
                                    <div className="dashed-border-new">

                                        {selectedImg_new ?
                                            <label htmlFor="myFile00" className="main-image-div">
                                                {renderPhotos(selectedImg_new)}
                                                <input type="file" className="custom-file-inputt d-none" id="myFile00" name="filename" onChange={(e) => handleFileSelect3(e)} />
                                            </label>
                                            :
                                            <div className="main-image-div">
                                                <svg xmlns="http://www.w3.org/2000/svg" onChange={(e) => handleFileSelect3(e)} width="61" height="45" viewBox="0 0 61 45" fill="none">
                                                    <path d="M47.6633 44.6918H37.804H35.1492H34.5758V31.4672H38.9009C39.9978 31.4672 40.6459 30.2208 39.9978 29.3234L31.5844 17.6818C31.0484 16.9339 29.9391 16.9339 29.4031 17.6818L20.9898 29.3234C20.3416 30.2208 20.9773 31.4672 22.0866 31.4672H26.4117V44.6918H25.8384H23.1835H11.7538C5.21005 44.3303 0 38.2104 0 31.5794C0 27.005 2.48038 23.0165 6.15734 20.8602C5.8208 19.9503 5.6463 18.9781 5.6463 17.956C5.6463 13.2819 9.42297 9.50525 14.0971 9.50525C15.1067 9.50525 16.0789 9.67975 16.9888 10.0163C19.6935 4.28273 25.5268 0.306641 32.3073 0.306641C41.0821 0.319105 48.3114 7.03733 49.134 15.6003C55.8772 16.7594 61 23.004 61 30.0713C61 37.6246 55.1169 44.1683 47.6633 44.6918Z" fill="#EDEDEE" />
                                                </svg>
                                                <div className="choose-filessss">
                                                    <p className='draganddrop'>Drag & drop or <span className='common' >Browse</span></p>
                                                    <input type="file" className="custom-file-inputt" id="myFile" name="filename" onChange={(e) => handleFileSelect3(e)} />
                                                    {/* <form action="" className="style-actionn">
                                                    <input type="file" className="custom-file-inputt" accept="video/*" id="myFile" name="filename" />
                                                </form> */}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div class="form-group rightside-for-group">
                                    <label for="exampleInputsymbol"> Upload Game</label>
                                    <div className="dashed-border-new">
                                        {selectedImg3 ?
                                            <label htmlFor="myFile22" className="main-image-div">
                                                {renderPhotos(selectedImg3)}
                                                <input type="file" className="custom-file-inputt d-none" id="myFile22" name="filename" onChange={(e) => handleFileSelect2(e)} />
                                            </label>
                                            :
                                            <div className="main-image-div">
                                                <svg xmlns="http://www.w3.org/2000/svg" onChange={(e) => handleFileSelect2(e)} width="61" height="45" viewBox="0 0 61 45" fill="none">
                                                    <path d="M47.6633 44.6918H37.804H35.1492H34.5758V31.4672H38.9009C39.9978 31.4672 40.6459 30.2208 39.9978 29.3234L31.5844 17.6818C31.0484 16.9339 29.9391 16.9339 29.4031 17.6818L20.9898 29.3234C20.3416 30.2208 20.9773 31.4672 22.0866 31.4672H26.4117V44.6918H25.8384H23.1835H11.7538C5.21005 44.3303 0 38.2104 0 31.5794C0 27.005 2.48038 23.0165 6.15734 20.8602C5.8208 19.9503 5.6463 18.9781 5.6463 17.956C5.6463 13.2819 9.42297 9.50525 14.0971 9.50525C15.1067 9.50525 16.0789 9.67975 16.9888 10.0163C19.6935 4.28273 25.5268 0.306641 32.3073 0.306641C41.0821 0.319105 48.3114 7.03733 49.134 15.6003C55.8772 16.7594 61 23.004 61 30.0713C61 37.6246 55.1169 44.1683 47.6633 44.6918Z" fill="#EDEDEE" />
                                                </svg>
                                                <div className="choose-filessss">
                                                    <p className='draganddrop'>Drag & drop or <span className='common' >Browse</span></p>
                                                    <input type="file" className="custom-file-inputt" id="myFile" name="filename" onChange={(e) => handleFileSelect2(e)} />
                                                    {/* <form action="" className="style-actionn">
                                                    <input type="file" className="custom-file-inputt" accept="video/*" id="myFile" name="filename" />
                                                </form> */}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
            {/* <Onlydailychalgametable /> */}
        </>
    );
}

export default AddDaily;
