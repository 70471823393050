import React, { useState } from 'react'
import "./boltmanagement.scss"
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import axios from 'axios';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { Link, useHistory } from 'react-router-dom';

const Addboltcategory = (props) => {

    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [task, setTask] = useState('')
    const [bolt, setBolt] = useState('')
    const token = localStorage.getItem('mytoken')
    // const id = props.match.params.id;
    // console.log("iddd", id)

    // search 
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 


    const addBolt = async () => {
        if (!task) {
            toast.error('Task is missing!', {
                position: "top-center",
                autoClose: 2000,
            });
        } else if (!bolt) {
            toast.error('Bolt is missing!', {
                position: "top-center",
                autoClose: 2000,
            });
        } else if (task && bolt) {
            setOpen(true);
            axios.post(Environment.backendUrl + "/reward/addBoltsReward", { description: task, boltReward: bolt }, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    setOpen(false);
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                    console.log(response, "errrrrr in tryyy");
                    history?.push('/admin/boltmanagement');
                }).catch((error) => {
                    setOpen(false);
                    // console.log(error.response.data, 'errrrrr', error.data, error);
                    toast.error(error.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            setOpen(false);
            toast.error("Field cannot be empty", {
                position: "top-center",
                autoClose: 2000,
            });
        }

    }
    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <section className="boltcategories">
                    <div className="container-fluid p-0">
                        <div className='col-sm-12 col-lg-8 p-0'>
                            <p>Task</p>
                            <input type="text" value={task} onChange={(e) => setTask(e.target.value)} placeholder='Verified Referral' />
                            <p>Bolt</p>
                            <input type="number" value={bolt} onChange={(e) => parseFloat(e.target.value) > 0 ? setBolt(e.target.value) : setBolt('')} placeholder='10' />
                        </div>
                    </div>
                    <div className='last'>
                        <button className='button-main-daily' onClick={addBolt} >Publish</button>
                        <Link to="/admin/boltmanagement">
                            <button className='red-w'> Cancel</button>
                        </Link>
                    </div>
                </section>
            </div>


        </>
    )
}

export default Addboltcategory