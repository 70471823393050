import { Backdrop, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Environment from 'utils/Environment';
import './newcategory.scss';
import DemoNavbar from "components/Navbars/DemoNavbar.js";

const Newcategory = (props) => {

    // search 
    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Pagination
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // search 

    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('mytoken')
    const Acls = JSON.parse(localStorage.getItem('acls'))
    const [categories, setCategories] = useState([])
    const getCategories = async () => {
        setOpen(true)

        axios.get(Environment.backendUrl + "/productsCategory/all", { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' } })
            .then((response) => {
                setOpen(false)
                setCategories(response?.data?.productCategory)
                // window.location.assign('/admin/newsbanner')
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }
    useEffect(() => {
        getCategories()
    }, [])
    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <section className="productss">
                    <div className="productstable">
                        <table>
                            <thead>
                                <th>Category</th>
                                <th>Creation Date</th>
                                <th>Total Products</th>
                                {Acls?.productCategories?.update ? <th>Actions</th> : ''}
                            </thead>
                            <tbody>
                                {categories?.map((item) => {
                                    return (
                                        <tr>
                                            <td>
                                                <p className="forellipise">{item?.categoryName}</p>
                                            </td>
                                            <td>
                                                <p className="">{item?.createdAt?.slice(0, 10)}</p>
                                            </td>
                                            <td>
                                                <p className="">{item?.totalProducts} Products</p>
                                            </td>
                                            {Acls?.productCategories?.update ? <td>
                                                <Link to={`/admin/editcategory/${item?.id}`}>
                                                    <button className="detailbtn">Edit</button>
                                                </Link>
                                            </td>
                                                : ''}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Newcategory
