import React, { useEffect, useState } from "react";
import './video.scss';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Environment from "utils/Environment";
import ReactPlayer from 'react-player';
import { Backdrop, CircularProgress } from '@material-ui/core';
import DemoNavbar from "components/Navbars/DemoNavbar.js";

function VideoDetails(props) {
    console.log(props, "videodetail okokokokok.");
    const token = localStorage.getItem('mytoken');
    const [open, setOpen] = useState(false);
    const Acls = JSON.parse(localStorage.getItem('acls'));

    const [allVideo, setAllVideo] = useState({
        createdAt: '',
        VideoQuestions: []
    });

    console.log(allVideo, "elem allVideo");
    const { id } = useParams();

    const Video = async () => {
        setOpen(true);
        await axios.get(`${Environment.backendUrl}/video/find/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response) {
                    setAllVideo(response.data.video);
                    setOpen(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching video details:", error);
                setOpen(false);
            });
    };

    const getPublish = async () => {
        const access = allVideo.published;
        console.log("status", access);
        setOpen(true);
        await axios.post(`${Environment.backendUrl}/video/publish/unpublish`,
            { id, access: !access },
            { headers: { "Authorization": `Bearer ${token}` } }
        )
            .then((response) => {
                setAllVideo(allVideo => ({ ...allVideo, published: !allVideo.published }));
                Video();
                setOpen(false);
            })
            .catch((error) => {
                console.error("Error updating publish status:", error);
                setOpen(false);
            });
    };

    useEffect(() => {
        Video();
    }, []);

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DemoNavbar {...props} />

            <div className="content">
                <section className="videos-detail card">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="video-title">
                                    <h4>Video Title</h4>
                                    <div className="title">
                                        <h6>{allVideo?.name}</h6>
                                    </div>
                                </div>
                                <div className="description">
                                    <h4>Video Description</h4>
                                    <div className="inner-dec">
                                        <p>{allVideo?.description}</p>
                                    </div>
                                </div>
                                <div className="RUC">
                                    <div className="RUC-inner">
                                        <p>Video Reward</p>
                                        <h6>{allVideo?.reward} LGX</h6>
                                    </div>
                                    <div className="RUC-inner">
                                        <p>Category</p>
                                        <h6>{allVideo?.VideoCategory?.name}</h6>
                                    </div>
                                    <div className="RUC-inner">
                                        <p>Video Duration</p>
                                        <h6>{allVideo?.duration}</h6>
                                    </div>
                                    <div className="RUC-inner">
                                        <p>Upload Date </p>
                                        <h6>{(allVideo.createdAt).split('T')[0]}</h6>
                                    </div>
                                </div>

                                <div className="row ptb20">
                                    <div className="col-sm-12 p-0">
                                        <div className="questions">
                                            <h2 className="quectionhnd">Questionnaire</h2>
                                            {allVideo?.VideoQuestions?.length > 0 ?
                                                allVideo?.VideoQuestions?.map((elem, index) => {
                                                    console.log(elem, "elemelem");
                                                    return (
                                                        <div key={index}>
                                                            <h5>{elem?.question}</h5>
                                                            <ul className="ptb20 qustintopdiv">
                                                                <li>
                                                                    <p className="quectinpara">
                                                                        <span className="onlyspanbold">01-</span>
                                                                        {elem?.a}
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="quectinpara">
                                                                        <span className="onlyspanbold">02-</span>
                                                                        {elem?.b}
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="quectinpara">
                                                                        <span className="onlyspanbold">03-</span>
                                                                        {elem?.c}
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="quectinpara">
                                                                        <span className="onlyspanbold">04-</span>
                                                                        {elem?.d}
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                            <p className="answercrtt">
                                                                Correct Answer: <span className="green">{elem?.answer}</span>
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                                : "Data not found"
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="buttons ptb20">
                                    {Acls?.videos?.update && (
                                        <Link to={`/admin/editvideos/${id}`}>
                                            <button type="button" className="red-w">Cancel</button>
                                        </Link>
                                    )}
                                    {Acls?.videos?.delete && (
                                        <button
                                            type="button"
                                            className={allVideo.published ? 'red-g' : 'red-b'}
                                            onClick={getPublish}
                                        >
                                            {allVideo.published ? 'Unpublish' : 'Publish'}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <h4 className="videolable">Video</h4>
                                <div className="image">
                                    {
                                        allVideo?.link && allVideo?.thumbnail &&
                                        <ReactPlayer
                                            className="videoFrame"
                                            url={allVideo?.link}
                                            width='100%'
                                            light={allVideo?.thumbnail}
                                            height='100%'
                                            controls
                                        />
                                    }
                                </div>
                                <h4 className="videolable">Thumbnail</h4>
                                <div className="imagesec">
                                    {
                                        allVideo?.link && allVideo?.thumbnail &&
                                        <ReactPlayer
                                            className="videoFrame"
                                            url={allVideo?.link}
                                            width='100%'
                                            light={allVideo?.thumbnail}
                                            height='100%'
                                            controls
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default VideoDetails;
