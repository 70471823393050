import React, { useEffect, useState } from 'react'
import { Modal, Nav, Pagination } from 'react-bootstrap'
import './stakingleaderboards.scss'
import { Link } from 'react-router-dom';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { Backdrop, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';

const Stakingleaderboards = (props) => {

    const [activeTab, setActiveTab] = useState('link-1');

    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    const [activeTab1, setActiveTab1] = useState('link-1');

    const handleSelect1 = (eventKey) => {
        setActiveTab1(eventKey);
    }


    const token = localStorage.getItem('mytoken');

    const [open, setOpen] = useState(false);
    const [stakingLBoardListing, setStakingLBoardListing] = useState(null);

    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage)
    };

    // pagenition============================

    // Search =============
    const [searchTerm, setSearchTerm] = useState('');
    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
    }
    // Search =============

    const stakingLeaderBoardHandle = () => {
        console.log('start ambassadorsClaimRewardsListing');
        setOpen(true);
        axios.post(Environment.backendUrl + `/reward/leaderBoardApis`, 
            {
                filter: "All",
                type: "Staking",
            },
            { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                console.log("🚀 ~ .then ~ response: stakingLeaderBoardHandle", response?.data?.stakingData)
                setStakingLBoardListing(response?.data?.stakingData);
                setOpen(false);
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    }

    useEffect(() => {
        stakingLeaderBoardHandle();
    }, [])

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className='content'>
                <div className='maintablea_leader'>
                    <>
                        <div className="innertable  table-responsive">
                            <table>
                                <thead>

                                    <th>Users </th>
                                    {/* <th> No of Referres</th> */}
                                    <th>Referrer Staking  </th>

                                </thead>
                                <tbody>
                                    {
                                        stakingLBoardListing?.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="mainimgdivvid ">
                                                            <div className="inerimgdvide">
                                                                <img src={data?.User?.profile_image} className="tableimgginerddvidd">
                                                                </img>
                                                            </div>
                                                            <p className="tableimgtext">
                                                                {data?.User?.full_name}
                                                            </p>
                                                        </div>
                                                    </td>
                                                    {/* <td>121</td> */}
                                                    <td>
                                                        <span className="eleipiess">
                                                            {parseInt(data?.totalLgxStaked)?.toLocaleString(4)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        {pageCount >= 1 ?
                            <div className="Paginationlattable">
                                <ReactPaginate
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    forcePage={page}
                                ></ReactPaginate>
                            </div>
                            : ''}
                        {/* <div className='Paginationlattable'>
                            <button className='leftpigbtn' >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                Prev
                            </button>
                            <Pagination>
                                <Pagination.Item>{1}</Pagination.Item>
                                <Pagination.Item>{2}</Pagination.Item>
                                <Pagination.Ellipsis />
                                <Pagination.Item active>{5}</Pagination.Item>
                                <Pagination.Item>{6}</Pagination.Item>
                            </Pagination>
                            <button className='leftpigbtn' >
                                Next
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            </button>
                        </div> */}
                    </>


                </div>
            </div>


        </>
    )
}

export default Stakingleaderboards
