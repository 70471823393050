import React, { useEffect, useState } from 'react'
import './coin1.scss'
import { toast } from 'react-toastify';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import Environment from "utils/Environment";
import { useHistory } from 'react-router';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { Link } from 'react-router-dom';

const Addrolemanagement = (props) => {

    const [rend, setRend] = useState(false);
    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('mytoken')
    const id = props.match.params.id;
    const history = useHistory()


    const [roleName, setRoleName] = useState(null);
    const [roledescription, setroledescription] = useState(null);

    const [userAccess, setUserAccess] = useState(false);
    const [boltsAccess, setBoltsAccess] = useState(false);
    const [videosAccess, setVideosAccess] = useState(false);

    // user access states ====================
    const [userViewA, setUserViewA] = useState(false);
    const [userCreateA, setUserCreateA] = useState(false);
    const [userEditA, setUserEditA] = useState(false);
    const [userDeleteA, setUserDeleteA] = useState(false);

    // bolts access states ====================
    const [boltsViewA, setBoltsViewA] = useState(false);
    const [boltsCreateA, setBoltsCreateA] = useState(false);
    const [boltsEditA, setBoltsEditA] = useState(false);
    const [boltsDeleteA, setBoltsDeleteA] = useState(false);

    // course access states ====================
    const [videosViewA, setVideosViewA] = useState(false);
    const [videosCreateA, setVideosCreateA] = useState(false);
    const [videosEditA, setVideosEditA] = useState(false);
    const [videosDeleteA, setVideosDeleteA] = useState(false);



    const [searchTerm, setSearchTerm] = useState('');
    const [limit] = useState(10);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);


    const searchsubmit = (e) => {
        setPage(0)
        setPage(0)
        // if (page == 0) {
        //   getAllUser()
        // }
    }

    // console.log("ddddddddddd", id)
    // const handleChangeCHeckbox = (e, key, b) => {
    //     let dumObj = myState;
    //     if (b === "create") {
    //         dumObj[key].create = e.target.checked;
    //     } else if (b === "update") {
    //         dumObj[key].update = e.target.checked;
    //     } else if (b === "get") {
    //         dumObj[key].get = e.target.checked;
    //     } else if (b === "delete") {
    //         dumObj[key].delete = e.target.checked;
    //     }
    //     setMyState(dumObj);
    //     setRend(!rend);
    // }

    // const [inputs, setInputs] = useState({
    //     role: ''
    // })
    // const [myState, setMyState] = useState({});
    // console.log("sdfasdfsadfsdaf", myState)
    // console.log("sdfasdfsadfsdaf:::::::::", inputs)
    // // const { name, symbol, decimals, contractAddress, type, page, image, link } = inputs;

    // const handleChange1 = (e) => {

    //     const { name, value } = e.target;
    //     setInputs(inputs => ({ ...inputs, [name]: value }));
    // }
    // const [isSubscribed, setIsSubscribed] = useState(false);
    // const handleChange = event => {
    //     if (event.target.checked) {
    //         console.log('checked');
    //     } else {
    //         console.log(' Checkbox is NOT checked');
    //     }
    //     setIsSubscribed(current => !current);
    // };
    // const moreToggle = (e, a, index) => {
    //     // console.log("e.", e.target.checked)
    //     if (e.target.checked) {
    //         // const name = e.currentTarget.name;
    //         let dumArr = myState;
    //         let dumObj = {
    //             selection: a,
    //             get: false,
    //             delete: false,
    //             create: false,
    //             update: false
    //         }
    //         dumArr[a] = dumObj;
    //         // console.log("ddddddd", dumArr)
    //         setMyState(dumArr);
    //         setRend(!rend);
    //     } else {
    //         // let dumArr = myState;
    //         // dumArr = {}
    //         // setMyState(dumArr);
    //         setRend(!rend);

    //         let dumArr = myState;
    //         // console.log("before delete",dumArr,a)
    //         // let dumObj = {
    //         //     selection: '',
    //         //     get: false,
    //         //     delete: false,
    //         //     create: false,
    //         //     update: false
    //         // }
    //         // dumArr = delete dumArr[a]
    //         let name = a
    //         const { [name]: removedProperty, ...restObject } = dumArr
    //         // console.log("after delete",restObject)
    //         // dumArr[{}]=dumObj
    //         setMyState(restObject);
    //     }
    // };


    const roles_permissions = [
        {
            "selection": "users",
            "update": userEditA,
            "delete": userDeleteA,
            "get": userViewA,
            "create": userCreateA
        },
        {
            "selection": "bolts",
            "update": boltsEditA,
            "delete": userDeleteA,
            "get": userEditA,
            "create": userCreateA
        },
        {
            "selection": "videos",
            "update": userEditA,
            "delete": userDeleteA,
            "get": userViewA,
            "create": userCreateA
        }
    ]


    const addRole = async (event) => {
        setOpen(true)
        event.preventDefault()

        if (roleName !== '') {
            axios.post(Environment.backendUrl + "/rolesManagement/add", { role_name: roleName, roles_permissions: roles_permissions }, { headers: { "Authorization": `BBearer ${token}` } })
                .then((response) => {
                    setOpen(false)
                    toast.success(response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                    history.push('/admin/allroles');
                    // setSelectedImg('');
                }).catch((err) => {
                    setOpen(false)
                    toast.error(err.response.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            setOpen(false)
            toast.error("Field cannot be empty", {
                position: "top-center",
                autoClose: 2000,
            });
        }

    }

    const editRole = async (event) => {
        setOpen(true)
        // event.preventDefault()

        // if (inputs.role != '') {
        axios.post(Environment.backendUrl + "/rolesManagement/edit", { role_name: roleName, roles_permissions: roles_permissions, role_id: id, }, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setOpen(false)
                toast.success(response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
                history.push('/admin/allroles');
                // setSelectedImg('');
            }).catch((err) => {
                setOpen(false)
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })

    }

    const FIndRole = () => {
        setOpen(true)
        axios.get(Environment.backendUrl + "/rolesManagement/find/" + id, { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                console.log("🚀 ~ .then ~ response: FIndRole", response)
                setRoleName(response?.data?.role?.role_name);
                setOpen(false)

            }).catch((err) => {
                setOpen(false)
                // console.log("err",err)
                toast.error(err.response?.data?.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    useEffect(() => {
        if (id) {
            FIndRole()
        }
    }, [id])

    // console.log("myState", myState,inputs)

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <DemoNavbar {...props} searchTerm={searchTerm} setSearchTerm={setSearchTerm} searchsubmit={searchsubmit} />

            <div className="content">
                <section className="addbanner12345 card njdhbcdhbc">
                    <div className="inner-submit-lower-div">
                        <div class="row">
                            <div class="col-md-8 order-md-0 order-1">
                                <div className="row">
                                    <div className="row">
                                        <div className="col-xl-8 col-sm-12">
                                            <div class="form-group">
                                                <label for="example">Role Name</label>
                                                <input
                                                    type="text"
                                                    name="full_name"
                                                    value={roleName}
                                                    onChange={(e) => setRoleName(e?.target?.value)}
                                                    className={"form-control"}
                                                    placeholder="Name"
                                                />
                                                {/* {Object.keys(TitleError).map((key) => {
                                                        return <p className="inputErrors">{TitleError[key]}</p>
                                                    })} */}
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-sm-12">
                                            {/* <div class="form-group">
                                                    <label for="example">Admin Role</label>
                                                    <input type="text" name="role" value={inputs.name} onChange={handleChange1} className={'form-control'} placeholder="Enter Role of Admin Eg reward-Admin" />
                                                    {Object.keys(TitleError).map((key) => {
                                                        return <p className="inputErrors">{TitleError[key]}</p>
                                                    })}
                                                </div> */}
                                        </div>
                                        <div className="col-xl-8 col-sm-12">
                                                <div class="form-group">
                                                    <label for="example">Description</label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        value={roledescription}
                                                        onChange={(e) => setroledescription(e?.target?.value)}
                                                        readOnly={id}
                                                        className={"form-control"}
                                                        placeholder="Enter of Admin"
                                                    />
                                                </div>
                                            </div>
                                        <div className="col-xl-8 col-sm-12">
                                        {/* <label for="example">Permissions</label> */}
                                            <h1 className="perdiv">Permissions</h1>
                                            <div class="dropdownnn">
                                                <a
                                                    class="btn warrrr"
                                                    href="#"
                                                    role="button"
                                                    data-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    {userAccess && "Users"} &nbsp;
                                                    {boltsAccess && "Bolt Store"} &nbsp;
                                                    {videosAccess && "Courses"} &nbsp;
                                                    {!userAccess && !boltsAccess && !videosAccess && "Permissions"}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="9"
                                                        height="6"
                                                        viewBox="0 0 9 6"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M8.33877 0.427734H4.52466H1.09013C0.502401 0.427734 0.208538 1.20833 0.624845 1.66592L3.79613 5.15169C4.30427 5.71022 5.13076 5.71022 5.6389 5.15169L6.84496 3.82602L8.81018 1.66592C9.22036 1.20833 8.9265 0.427734 8.33877 0.427734Z"
                                                            fill="#3553D5"
                                                        />
                                                    </svg>
                                                </a>
                                                <div class="dropdown-menu mydropppp">
                                                    <a class="dropdown-itemw  " href="#">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M9.58464 18.1263C4.8763 18.1263 1.04297 14.293 1.04297 9.58464C1.04297 4.8763 4.8763 1.04297 9.58464 1.04297C14.293 1.04297 18.1263 4.8763 18.1263 9.58464C18.1263 14.293 14.293 18.1263 9.58464 18.1263ZM9.58464 2.29297C5.55964 2.29297 2.29297 5.56797 2.29297 9.58464C2.29297 13.6013 5.55964 16.8763 9.58464 16.8763C13.6096 16.8763 16.8763 13.6013 16.8763 9.58464C16.8763 5.56797 13.6096 2.29297 9.58464 2.29297Z"
                                                                fill="#3553D5"
                                                            />
                                                            <path
                                                                d="M18.3365 18.9595C18.1781 18.9595 18.0198 18.9012 17.8948 18.7762L16.2281 17.1095C15.9865 16.8678 15.9865 16.4678 16.2281 16.2262C16.4698 15.9845 16.8698 15.9845 17.1115 16.2262L18.7781 17.8928C19.0198 18.1345 19.0198 18.5345 18.7781 18.7762C18.6531 18.9012 18.4948 18.9595 18.3365 18.9595Z"
                                                                fill="#3553D5"
                                                            />
                                                        </svg>
                                                        Search
                                                    </a>
                                                    {/* <a class="dropdown-itemw" href="#"> */}
                                                    <div class="dropdown-itemw formcheck_newwwwws namediv" onClick={() => setUserAccess(!userAccess)}>
                                                        <label class="checkBox m-0 warrrrw">
                                                            <input type="checkbox" id="ch1" className="boxxxxx" />
                                                            <p className="inputtext">Users</p>
                                                        </label>
                                                    </div>
                                                    {/* </a> */}
                                                    {/* <a class="dropdown-itemw" href="#"> */}
                                                    <div class="dropdown-itemw formcheck_newwwwws" onClick={() => setBoltsAccess(!boltsAccess)}>
                                                        <label class="checkBox m-0 warrrrw">
                                                            <input type="checkbox" id="ch1" className="boxxxxx" />
                                                            <p className="inputtext">Bolt Store</p>
                                                        </label>
                                                    </div>
                                                    {/* </a> */}
                                                    {/* <a class="dropdown-itemw" href="#"> */}
                                                    <div class="dropdown-itemw formcheck_newwwwws" onClick={() => setVideosAccess(!videosAccess)}>
                                                        <label class="checkBox m-0 warrrrw">
                                                            <input type="checkbox" id="ch1" className="boxxxxx" />
                                                            <p className="inputtext">Videos</p>
                                                        </label>
                                                    </div>
                                                    {/* </a> */}
                                                    <a class="dropdown-itemw" href="#">
                                                        <button className="butttttt">Add Selected</button>
                                                    </a>
                                                </div>
                                            </div>
                                            {
                                                userAccess &&
                                                <div className="userdivv">
                                                    <h6 className="userfloooow">Users</h6>
                                                    <div className="mainshead">
                                                        <div class="formcheck_newwwwws">
                                                            <label className="checkBox m-0 warrrrw" onClick={() => setUserViewA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={userViewA} readOnly />
                                                                <p className="inputtextt">View</p>
                                                            </label>
                                                        </div>
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setUserCreateA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={userCreateA} />
                                                                <p className="inputtextt">Create</p>
                                                            </label>
                                                        </div>
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setUserEditA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={userEditA} />
                                                                <p className="inputtextt">Edit</p>
                                                            </label>
                                                        </div>
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setUserDeleteA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={userDeleteA} />
                                                                <p className="inputtextt">Delete</p>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                boltsAccess &&
                                                <div className="userdivv">
                                                    <h6 className="userfloooow">Bolt Store</h6>
                                                    <div className="mainshead">
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setBoltsViewA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={boltsViewA} />
                                                                <p className="inputtextt">View</p>
                                                            </label>
                                                        </div>
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setBoltsCreateA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={boltsCreateA} />
                                                                <p className="inputtextt">Create</p>
                                                            </label>
                                                        </div>
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setBoltsEditA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={boltsEditA} />
                                                                <p className="inputtextt">Edit</p>
                                                            </label>
                                                        </div>
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setBoltsDeleteA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={boltsDeleteA} />
                                                                <p className="inputtextt">Delete</p>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                videosAccess &&
                                                <div className="userdivv">
                                                    <h6 className="userfloooow">Courses</h6>
                                                    <div className="mainshead">
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setVideosViewA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={videosViewA} />
                                                                <p className="inputtextt">View</p>
                                                            </label>
                                                        </div>
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setVideosCreateA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={videosCreateA} />
                                                                <p className="inputtextt">Create</p>
                                                            </label>
                                                        </div>
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setVideosEditA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={videosEditA} />
                                                                <p className="inputtextt">Edit</p>
                                                            </label>
                                                        </div>
                                                        <div class="formcheck_newwwwws">
                                                            <label class="checkBox m-0 warrrrw" onClick={() => setVideosDeleteA()}>
                                                                <input type="checkbox" id="ch1" className="boxxxxx" checked={videosDeleteA} />
                                                                <p className="inputtextt">Delete</p>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>



                                    <div className="col-xl-8 col-sm-12">
                                        <div class="form-group">
                                            <div className="videopage">
                                                {id ?
                                                    <button className="red-b" onClick={() => editRole()}>Save</button>
                                                    :
                                                    <button className="red-b hhhhhdkhlkhlkh" onClick={addRole}>Publish</button>
                                                }
                                                {/* // <button className="red-b" onClick={sendVideo}>Publish</button> */}
                                                <Link to="/admin/allroles">
                                                    <button className="red-w">Cancel</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Addrolemanagement